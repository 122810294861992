import {
  IntercomTracker,
  MixpanelTracker,
  DataLayerTracker
} from '@/lib/tracking/trackers';

export class Tracker {
  #intercomTracker;

  #mixpanelTracker;

  #dataLayerTracker;

  constructor({ intercom, mixpanel, dataLayer }) {
    this.#intercomTracker = new IntercomTracker(intercom);
    this.#mixpanelTracker = new MixpanelTracker(mixpanel);
    this.#dataLayerTracker = new DataLayerTracker(dataLayer);
  }

  trackFunnelViewed(data = {}) {
    this.#mixpanelTracker.trackFunnelViewed(data);
  }

  trackFunnelCreated(data = {}) {
    this.#mixpanelTracker.trackFunnelCreated(data);
  }

  trackFunnelStageCreated(data = {}) {
    this.#mixpanelTracker.trackFunnelStageCreated(data);
  }

  trackOrganizationCreated(data = {}) {
    this.#intercomTracker.trackOrganizationCreated(data);
    this.#mixpanelTracker.trackOrganizationCreated(data);
  }

  trackOrganizationUpdated(data = {}) {
    this.#mixpanelTracker.trackOrganizationUpdated(data);
  }

  trackPersonCreated(data = {}) {
    this.#intercomTracker.trackPersonCreated(data);
    this.#mixpanelTracker.trackPersonCreated(data);
  }

  trackDealCreated(data = {}) {
    this.#intercomTracker.trackDealCreated(data);
    this.#mixpanelTracker.trackDealCreated(data);
    this.#dataLayerTracker.trackDealCreated(data);
  }

  trackProductCreated(data = {}) {
    this.#mixpanelTracker.trackProductCreated(data);
  }

  trackProductEdited(data = {}) {
    this.#mixpanelTracker.trackProductEdited(data);
  }

  trackProductDeleted(data = {}) {
    this.#mixpanelTracker.trackProductDeleted(data);
  }

  trackNewCreateOrganizationFormViewed(data = {}) {
    this.#mixpanelTracker.trackNewCreateOrganizationFormViewed(data);
  }

  trackNewCreatePersonFormViewed(data = {}) {
    this.#mixpanelTracker.trackNewCreatePersonFormViewed(data);
  }

  trackNewCreateDealFormViewed(data = {}) {
    this.#mixpanelTracker.trackNewCreateDealFormViewed(data);
  }

  trackNewEditOrganizationFormViewed(data = {}) {
    this.#mixpanelTracker.trackNewEditOrganizationFormViewed(data);
  }

  trackNewEditPersonFormViewed(data = {}) {
    this.#mixpanelTracker.trackNewEditPersonFormViewed(data);
  }

  trackNewEditDealFormViewed(data = {}) {
    this.#mixpanelTracker.trackNewEditDealFormViewed(data);
  }

  trackNewOrganizationListViewed(data = {}) {
    this.#mixpanelTracker.trackNewOrganizationListViewed(data);
  }

  trackNewPersonListViewed(data = {}) {
    this.#mixpanelTracker.trackNewPersonListViewed(data);
  }

  trackNewDealListViewed(data = {}) {
    this.#mixpanelTracker.trackNewDealListViewed(data);
  }

  trackUserLoaded(data = {}) {
    this.#dataLayerTracker.trackUserLoaded(data);
  }

  trackProfileUpdated(data = {}) {
    this.#mixpanelTracker.trackProfileUpdated(data);
    this.#dataLayerTracker.trackProfileUpdated(data);
  }

  trackProfileStepViewed(data = {}) {
    this.#mixpanelTracker.trackProfileStepViewed(data);
  }

  trackProfileStepCompleted(data = {}) {
    this.#mixpanelTracker.trackProfileStepCompleted(data);
  }

  trackCustomFieldCreated(data = {}) {
    this.#mixpanelTracker.trackCustomFieldCreated(data);
    this.#dataLayerTracker.trackCustomFieldCreated(data);
  }

  trackCustomFieldEdited(data = {}) {
    this.#mixpanelTracker.trackCustomFieldEdited(data);
  }

  trackCustomFieldDeleted(data = {}) {
    this.#mixpanelTracker.trackCustomFieldDeleted(data);
  }

  trackBacklogFormToggle(data = {}) {
    this.#mixpanelTracker.trackBacklogFormToggle(data);
  }

  trackCardDraggedFromBacklog(data = {}) {
    this.#mixpanelTracker.trackCardDraggedFromBacklog(data);
  }

  trackBacklogFilterUsed(data = {}) {
    this.#mixpanelTracker.trackBacklogFilterUsed(data);
  }

  trackBacklogSearchUsed(data = {}) {
    this.#mixpanelTracker.trackBacklogSearchUsed(data);
  }

  trackFunnelFilterUsed(data = {}) {
    this.#mixpanelTracker.trackFunnelFilterUsed(data);
  }

  trackListHorizontalScrollViewed(data = {}) {
    this.#dataLayerTracker.trackListHorizontalScrollViewed(data);
  }

  trackNewImportViewed(data = {}) {
    this.#mixpanelTracker.trackNewImportViewed(data);
  }

  trackNewImportStarted(data = {}) {
    this.#mixpanelTracker.trackNewImportStarted(data);
  }

  trackNewImportMapping(data = {}) {
    this.#mixpanelTracker.trackNewImportMapping(data);
  }

  trackNewImportVisualization(data = {}) {
    this.#mixpanelTracker.trackNewImportVisualization(data);
  }

  trackCommentAdded(data = {}) {
    this.#mixpanelTracker.trackCommentAdded(data);
    this.#dataLayerTracker.trackCommentAdded(data);
  }

  trackDealStageChanged(data = {}) {
    this.#mixpanelTracker.trackDealStageChanged(data);
  }

  trackCommentChanged(data = {}) {
    this.#mixpanelTracker.trackCommentChanged(data);
  }

  trackDealStatusChanged(data = {}) {
    this.#mixpanelTracker.trackDealStatusChanged(data);
    this.#dataLayerTracker.trackDealStatusChanged(data);
  }

  trackTaskFinished(data = {}) {
    this.#mixpanelTracker.trackTaskFinished(data);
  }

  trackModalViewed(data = {}) {
    this.#mixpanelTracker.trackModalViewed(data);
    this.#intercomTracker.trackDealModalViewed(data);
  }

  trackDealFunnelChanged(data = {}) {
    this.#mixpanelTracker.trackDealFunnelChanged(data);
  }

  trackDealEmailCopied(data = {}) {
    this.#mixpanelTracker.trackDealEmailCopied(data);
  }

  trackBackToLegacy(data = {}) {
    this.#mixpanelTracker.trackBackToLegacy(data);
  }

  trackRequiredFieldCreated(data = {}) {
    this.#mixpanelTracker.trackRequiredFieldCreated(data);
  }

  trackRequiredFieldUpdated(data = {}) {
    this.#mixpanelTracker.trackRequiredFieldUpdated(data);
  }

  trackRequiredFieldDeleted(data = {}) {
    this.#mixpanelTracker.trackRequiredFieldDeleted(data);
  }

  trackRequiredFieldFilled(data = {}) {
    this.#mixpanelTracker.trackRequiredFieldFilled(data);
  }

  trackVoipActivated(data = {}) {
    this.#mixpanelTracker.trackVoipActivated(data);
  }

  trackVoipCallStarted(data = {}) {
    this.#mixpanelTracker.trackVoipCallStarted(data);
  }

  trackVoipGptSummaryViewed(data = {}) {
    this.#mixpanelTracker.trackVoipGptSummaryViewed(data);
  }

  trackVoipGptTranscriptionViewed(data = {}) {
    this.#mixpanelTracker.trackVoipGptTranscriptionViewed(data);
  }

  trackActivityTemplateButtonClicked(data = {}) {
    this.#mixpanelTracker.trackActivityTemplateButtonClicked(data);
  }

  trackActivityTemplateSelected(data = {}) {
    this.#mixpanelTracker.trackActivityTemplateSelected(data);
  }

  trackActivityTemplateCreated(data = {}) {
    this.#mixpanelTracker.trackActivityTemplateCreated(data);
  }

  trackActivityTemplateDeleted(data = {}) {
    this.#mixpanelTracker.trackActivityTemplateDeleted(data);
  }

  trackAutomationCreated(data = {}) {
    this.#mixpanelTracker.trackAutomationCreated(data);
    this.#intercomTracker.trackAutomationCreated(data);
  }

  trackAutomationCardSpoilerClicked(data = {}) {
    this.#mixpanelTracker.trackAutomationCardSpoilerClicked(data);
  }

  trackAutomationCardClicked(data = {}) {
    this.#mixpanelTracker.trackAutomationCardClicked(data);
  }

  trackLeadsGenerationPresented(data = {}) {
    this.#intercomTracker.trackLeadsGenerationPresented(data);
    this.#mixpanelTracker.trackLeadsGenerationPresented(data);
  }

  trackLeadsGenerationStarted(data = {}) {
    this.#intercomTracker.trackLeadsGenerationStarted(data);
    this.#mixpanelTracker.trackLeadsGenerationStarted(data);
  }

  trackSessionModalInterruption(data = {}) {
    this.#mixpanelTracker.trackSessionModalInterruption(data);
  }

  trackLeadsGenerationSegmented(data = {}) {
    this.#mixpanelTracker.trackLeadsGenerationSegmented(data);
  }

  trackLeadsGenerationDiscarded(data = {}) {
    this.#mixpanelTracker.trackLeadsGenerationDiscarded(data);
  }

  trackLeadsGenerationListPresented(data = {}) {
    this.#mixpanelTracker.trackLeadsGenerationListPresented(data);
  }

  trackLeadsGenerationChooseFunnel(data = {}) {
    this.#mixpanelTracker.trackLeadsGenerationChooseFunnel(data);
  }

  trackLeadsGenerationPaymentStarted(data = {}) {
    this.#intercomTracker.trackLeadsGenerationPaymentStarted(data);
    this.#mixpanelTracker.trackLeadsGenerationPaymentStarted(data);
  }

  trackEmailSent(data = {}) {
    this.#mixpanelTracker.trackEmailSent(data);
  }

  trackProposalCreated(data = {}) {
    this.#mixpanelTracker.trackProposalCreated(data);
    this.#intercomTracker.trackProposalCreated(data);
  }

  trackProposalModelCreated(data = {}) {
    this.#mixpanelTracker.trackProposalModelCreated(data);
  }

  trackProposalModelUpdated(data = {}) {
    this.#mixpanelTracker.trackProposalModelUpdated(data);
  }

  trackProposalModelDeleted(data = {}) {
    this.#mixpanelTracker.trackProposalModelDeleted(data);
  }

  trackProposalNotCreated(data = {}) {
    this.#intercomTracker.trackProposalNotCreated(data);
  }

  trackABTest(data = {}) {
    this.#mixpanelTracker.trackABTest(data);
  }

  trackActionButtonClicked(data = {}) {
    this.#mixpanelTracker.trackActionButtonClicked(data);
  }

  trackPaymentStarted(data = {}) {
    this.#mixpanelTracker.trackPaymentStarted(data);
  }

  trackFunnelGiftClicked(data = {}) {
    this.#mixpanelTracker.trackFunnelGiftClicked(data);
  }

  trackFunnelGiftPlaylistClicked(data = {}) {
    this.#mixpanelTracker.trackFunnelGiftPlaylistClicked(data);
  }

  trackFunnelGiftSettingsClicked(data = {}) {
    this.#mixpanelTracker.trackFunnelGiftSettingsClicked(data);
  }

  trackImportGuideSampleDownloadClicked(data = {}) {
    this.#mixpanelTracker.trackImportGuideSampleDownloadClicked(data);
  }

  trackImportGuideHelpCenterClicked(data = {}) {
    this.#mixpanelTracker.trackImportGuideHelpCenterClicked(data);
  }

  trackImportGuideVideoPlayed(data = {}) {
    this.#mixpanelTracker.trackImportGuideVideoPlayed(data);
  }

  trackWhatsappModalViewed(data = {}) {
    this.#mixpanelTracker.trackWhatsappModalViewed(data);
  }

  trackWhatsappModalClicked(data = {}) {
    this.#mixpanelTracker.trackWhatsappModalClicked(data);
  }

  trackWhatsappUsed(data = {}) {
    this.#mixpanelTracker.trackWhatsappUsed(data);
  }

  trackSmartSuggestionPresented(data = {}) {
    this.#mixpanelTracker.trackSmartSuggestionPresented(data);
  }

  trackSmartSuggestionAccepted(data = {}) {
    this.#mixpanelTracker.trackSmartSuggestionAccepted(data);
  }

  trackSmartSuggestionConfirmed(data = {}) {
    this.#mixpanelTracker.trackSmartSuggestionConfirmed(data);
  }

  trackSmartSuggestionClosed(data = {}) {
    this.#mixpanelTracker.trackSmartSuggestionClosed(data);
  }

  trackSmartSuggestionCancelled(data = {}) {
    this.#mixpanelTracker.trackSmartSuggestionCancelled(data);
  }

  trackBackToLegacyConfirmed(data = {}) {
    this.#mixpanelTracker.trackBackToLegacyConfirmed(data);
  }

  trackOngoingChecklistOpened(data = {}) {
    this.#mixpanelTracker.trackOngoingChecklistOpened(data);
  }

  trackVoipInfoModalOpened(data = {}) {
    this.#mixpanelTracker.trackVoipInfoModalOpened(data);
  }

  trackVoipInfoShowMoreClicked(data = {}) {
    this.#mixpanelTracker.trackVoipInfoShowMoreClicked(data);
  }

  trackVoipInfoModalButtonClicked(data = {}) {
    this.#mixpanelTracker.trackVoipInfoModalButtonClicked(data);
  }

  trackIntegrationCardClicked(data = {}) {
    this.#mixpanelTracker.trackIntegrationCardClicked(data);
  }

  trackIntegrationModalOpened(data = {}) {
    this.#mixpanelTracker.trackIntegrationModalOpened(data);
  }

  trackIntegrationModalCTAClicked(data = {}) {
    this.#mixpanelTracker.trackIntegrationModalCTAClicked(data);
  }
}

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import LoadingButton from '@/components/LoadingButton';
import Popover from '@/components/Popover';
import Form from '@/components/Form';
import { AddressFields } from '@/components/Inputs';
import EntityAddressContent from '@/components/EntityModal/EntityAddressContent';

const propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func
};

const defaultProps = {
  initialValues: {},
  onSubmit: () => {}
};

function EntityAddressPopover({ initialValues, onSubmit }) {
  const [showModal, setShowModal] = useState(false);

  const initialData = useMemo(() => ({
    address: {
      postal_code: initialValues?.postalCode,
      country: initialValues?.country,
      district: initialValues?.district,
      street_name: initialValues?.streetName,
      street_number: initialValues?.streetNumber,
      additional_info: initialValues?.additionalInfo,
      state: initialValues?.state,
      city_id: initialValues?.cityId
    }
  }), [initialValues]);

  const handleSubmitAddress = (values) => {
    onSubmit(values);
    setShowModal(false);
  };

  return (
    <Popover
      flip
      hide={!showModal}
      className='max-width-6'
      placement='left-start'
      showArrow
      rootCloseEvent='mousedown'
      content={({ onHide }) => (
        <div className='mt-2'>
          <h3>Editar o Endereço</h3>

          <Form
            name='edit-address-form'
            onSubmit={handleSubmitAddress}
            initialValues={initialData}
          >
            {
              ({ handleSubmit, isSubmitting }) => (
                <>
                  <AddressFields/>
                  <Row className='mt-8'>
                    <Col sm={6}>
                      <Button
                        className='w-100'
                        variant='outline-dark-gray'
                        onClick={onHide}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col sm={6}>
                      <LoadingButton
                        className='w-100'
                        variant='primary'
                        isLoading={isSubmitting}
                        onClick={handleSubmit}
                      >
                        Salvar
                      </LoadingButton>
                    </Col>
                  </Row>
                </>
              )
            }
          </Form>

        </div>
      )}
    >
      <div
        className='d-inline-flex hover-parent'
      >
        <EntityAddressContent initialValues={initialValues} />
        <div
          onClick={() => setShowModal(true)}
          id='edit-address'
          className='opacity-0 hover-child-opacity-100'
        >
          <Icon
            className='text-primary cursor-pointer mx-1'
            name='edit-field'
          />
        </div>
      </div>
    </Popover>
  );
}

EntityAddressPopover.propTypes = propTypes;
EntityAddressPopover.defaultProps = defaultProps;

export default EntityAddressPopover;

import React, { useState } from 'react';
import ConfirmationModal from '@/components/ConfirmationModal';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import { Checkbox } from '@/components/Inputs';
import { FormLabel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { helpUrl } from '@/routes';
import ExternalLink from '@/components/ExternalLink';

const propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

const GOOD_PRACTICES_GUIDE = [
  {
    title: 'Interaja manualmente por 7 dias.',
    description: 'Envie mensagens reais e participe de conversas para “esquentar” seu número.'
  },
  {
    title: 'Conecte-se pelo app do celular ou WhatsApp Web.',
    description: 'Faça isso antes de ativar qualquer automação.'
  },
  {
    title: 'Evite mensagens para quem não salvou seu número.',
    description: 'Lembre-se: relacionamento é uma via de mão dupla.'
  },
  {
    title: 'Personalize suas mensagens.',
    description: 'Adapte o tom e o conteúdo para cada contato.'
  },
  {
    title: 'Modere o volume de mensagens.',
    description: 'Evite disparos em excesso para não parecer spam.'
  }
];

function WhatsappGoodPracticesModal(props) {
  const { show, onHide, onConfirm } = props;
  const [canConfirm, setCanConfirm] = useState(false);

  const handleFormSubmit = (formData) => {
    const checkboxValues = Object.keys(formData)
      .filter((key) => key.startsWith('good-practice-'))
      .map((key) => formData[key]);

    const allFilled = checkboxValues.length === GOOD_PRACTICES_GUIDE.length;
    const allTrue = checkboxValues.every((value) => value);

    setCanConfirm(allFilled && allTrue);
  };

  return (
    <ConfirmationModal
      confirmationText='Salvar automação'
      show={show}
      onConfirm={onConfirm}
      onHide={onHide}
      disabled={!canConfirm}
      buttonClassName='px-0'
      variant='primary'
      className='pt-0'
    >
      <ConfirmationModal.Title>
        Automatize com inteligência: evite bloqueios no WhatsApp!
      </ConfirmationModal.Title>

      <ConfirmationModal.Info className='text-start text-dark-gray'>
        Antes de salvar suas automações, siga estas dicas simples para proteger seu número no
        WhatsApp e manter tudo funcionando sem interrupções:
      </ConfirmationModal.Info>

      <Form
        onSubmit={handleFormSubmit}
        className='d-flex my-6 text-dark-gray'
        name='whatsapp-good-practices-form'
      >
        {({ handleSubmit }) => (
          <div className='d-flex flex-column gap-3'>
            {GOOD_PRACTICES_GUIDE.map((item, index) => (
              <div
                key={item.title}
                className='d-flex align-items-center gap-2'
              >
                <FormField
                  key={index}
                  as={Checkbox}
                  name={`good-practice-${index}`}
                  className='text-start mb-1'
                  onChange={handleSubmit}
                />
                <FormLabel htmlFor={`good-practice-${index}`} className='mb-0'>
                  <div className='text-base text-start'>
                    <span>{item.title}</span>
                    <span className='fw-normal'> {item.description}</span>
                  </div>
                </FormLabel>
              </div>
            ))}
          </div>
        )}
      </Form>

      <div className='d-flex flex-column align-items-start text-dark-gray'>
        <p className='mb-2 fw-bold'>
          Veja o guia completo de boas práticas{' '}
          <ExternalLink
            href={helpUrl('whatsapp-good-practices')}
            target='_blank'
            rel='noreferrer'
            className='fw-bold text-dark-gray text-underline'
          >aqui</ExternalLink>.
        </p>
        <p>Checou todas as dicas? Então é só clicar no botão abaixo!</p>
      </div>
    </ConfirmationModal>
  );
}

WhatsappGoodPracticesModal.propTypes = propTypes;

export default WhatsappGoodPracticesModal;

import React, { } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import Icon from '@/components/Icon';
import LoadingButton from '@/components/LoadingButton';
import Button from '@/components/Button';
import { useSwitchTab, useVoipPopoverContext } from '@/contexts/voipPopover';
import { useCreateVoipStartCall } from '@/api';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';
import { VOIP_SERVICES } from '@/utils';

const MAIN_ERROR = 'Ocorreu um erro ao iniciar o discador. Tente novamente mais tarde.';
const ZENVIA_ERRORS = {
  '9': 'Sua conta não tem saldo suficiente.',
  '11': 'O número de telefone é inválido.'
};

const propTypes = {
  number: PropTypes.string,
  service: PropTypes.string
};
function CallButton({ service, number }) {
  const { user } = useAuth();
  const alert = useAlert();
  const tracker = useTracking();
  const switchTab = useSwitchTab();
  const { entity, entityType, loadingCall, setLoadingCall } = useVoipPopoverContext();
  const createCallMutation = useCreateVoipStartCall();

  if (service === VOIP_SERVICES.VIP) {
    return (
      <Button
        className='align-self-baseline ms-auto border-0'
        onClick={() => {
          window.location.href = `tel:${number.replace(/[\D]+/gu, '')}`;
        }}
      >
        <Icon name='call' />
      </Button>
    );
  }

  function alertError(error) {
    alert.show(
      <div>
        <h6>Não foi possível realizar a ligação!</h6>
        <ul className='mb-0 pl-0'>
          <li>{error}</li>
        </ul>
      </div>,
      { variant: 'danger', timeout: 5000 }
    );
  }

  const onClick = () => {
    setLoadingCall(true);
    const params = {
      entity_id: entity.id,
      entity: entityType,
      voip_to: number.replace(/[\D]+/gu, '')
    };
    createCallMutation.mutate(params, {
      onSuccess: (data) => {
        if (data?.success) {
          tracker.trackVoipCallStarted({
            user,
            page: `${entityType}_modal`,
            component: 'entity_modal_actions'
          });

          window.open(data.url, '_blank');
          switchTab('calling');
        } else {
          alertError(ZENVIA_ERRORS[`${data.code}`] ?? MAIN_ERROR);
        }
      },
      onError: () => {
        alertError(MAIN_ERROR);
      },
      onSettled: () => {
        setLoadingCall(false);
      }
    });
  };

  return (
    <LoadingButton
      isLoading={createCallMutation.isLoading}
      loadingText='Iniciando'
      className='align-self-baseline ms-auto border-0'
      onClick={onClick}
      disabled={loadingCall}
    >
      <Icon name='call' />
    </LoadingButton>
  );
}

CallButton.propTypes = propTypes;

export default CallButton;

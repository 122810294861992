import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';

import Card from '@/components/Card';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import { TextInput } from '@/components/Inputs';
import SkeletonWrapper from '@/components/SkeletonWrapper';
import BillingFooter from '@/components/Billing/BillingFooter';
import Button from '@/components/Button';
import CouponInput from '@/components/Billing/CouponInput';
import PlanSelect from '@/components/Billing/PlanSelect';
import Modal from '@/components/Modal';

import { useDebounce } from '@/hooks';
import { redirect } from '@/browser';
import { legacyPlansUrl } from '@/routes';
import { useCheckoutContext } from '@/contexts';
import { toCurrencyString } from '@/number';

const defaultDebounceTime = 600;

const periodicityText = {
  monthly: 'mensalmente',
  quarterly: 'trimestralmente',
  half_yearly: 'semestralmente',
  yearly: 'anualmente'
};

const defaultValues = {
  users_count: 0
};

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  users_count: Yup.number()
    .required('Número de usuários é obrigatório.')
    .min(3, 'A quantidade mínima de usuários é 3.')
});
/* eslint-enable no-magic-numbers */

function ChooseChatPlan() {
  const {
    usersCount,
    setUsersCount,
    checkoutResumeData,
    plan,
    setPlan,
    period,
    couponCode,
    setCoupon,
    nextStep
  } = useCheckoutContext();

  const currentValuePerUser = checkoutResumeData?.monthlyInfo.valuePerUser;
  const maxUsersCount = usersCount;
  const onChangeUsersCount = setUsersCount;
  const periodicity = checkoutResumeData.periodicity;

  const [showModal, setShowModal] = useState(false);
  const [showInput, setShowInput] = useState(couponCode);

  const debounceHandler = useDebounce((fn) => fn(), defaultDebounceTime);

  const initialValues = useMemo(() => ({ users_count: maxUsersCount }), [maxUsersCount]);

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size='md'
        bodyClassName='p-8'
        backdrop='static'
      >
        <div className='pt-3 px-4 text-center'>
          <h2>Redução de plano solicitada</h2>
          <p className='pt-4 text-center'>Nossa equipe entrará em contato com você em breve</p>
        </div>

        <div className='mt-5 d-grid gap-2'>
          <Button variant='primary' onClick={() => setShowModal(false)}>
            Fechar
          </Button>
        </div>
      </Modal>

      <PlanSelect
        internalProduct='chat'
        plan={plan}
        setPlan={setPlan}
      />

      <Card className='p-6 border-light'>
        <Form
          name='form'
          validationSchema={schema}
          defaultValues={defaultValues}
          initialValues={initialValues}
          onSubmit={nextStep}
        >
          {
            ({ handleSubmit }) => <>
              <div className='text-dark-gray fw-bold mb-4'>
                Quantas pessoas da sua empresa irão utilizar o Agendor Chat? (incluindo você)
              </div>

              <div>
                <div className='fw-bold text-small'>Número de usuários</div>
                <FormField
                  as={TextInput}
                  name='users_count'
                  onChange={(value) => debounceHandler(() => onChangeUsersCount(Number(value)))}
                  className='max-width-5 mb-1'
                  type='number'
                  placeholder='4'
                  min='3'
                />
              </div>

              <SkeletonWrapper
                isLoading={!currentValuePerUser}
                SkeletonComponent={() => <div>Carregando</div>}
              >
                <div className='d-flex mb-1'>
                  <h1>{toCurrencyString(currentValuePerUser)}</h1>
                  <h1 className='mx-1'>/</h1>
                  <h4 className='fw-normal align-self-end mb-1'>por usuário</h4>
                </div>
              </SkeletonWrapper>

              <div className='fw-bold text-medium mt-2 mb-1'>
                Valor mensal, pago {periodicityText[periodicity]}
              </div>

              <BillingFooter
                buttonNextText='Avançar'
                onClickPrev={() => redirect(legacyPlansUrl())}
                onClickNext={handleSubmit}
              />
            </>
          }
        </Form>

        <Button
          className='mb-1 text-underline p-0 text-dark-gray text-small align-self-start'
          onClick={() => setShowInput(true)}
          variant='link'
        >
          Aplicar cupom
        </Button>

        {
          showInput &&
          <CouponInput
            usersCount={usersCount}
            period={period}
            couponCode={couponCode}
            setCoupon={setCoupon}
          />
        }
      </Card>
    </>
  );
}

export default ChooseChatPlan;

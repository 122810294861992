import React from 'react';
import { helpUrl } from '@/routes';
import ActionCard from '@/components/ActionCard';
import Button from 'react-bootstrap/Button';
import Badge from '@/components/Badge';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';
import NavLink from '@/components/NavLink';
import { useMediaQuery } from '@/hooks';
const API_DOCS = 'https://api.agendor.com.br/docs/#section/Introduction/Authentication';
const POSTMAN_API_V3_EXEMPLES = 'https://www.postman.com/grey-comet-695161/agendor-api/collection/olkk5nk/agendor-api-v3';
const POSTMAN_USE_WEBHOOK = 'https://www.postman.com/grey-comet-695161/agendor-api/collection/olkk5nk/agendor-api-v3';

function DeveloperArea() {
  const maxWidthCard = useMediaQuery('(min-width: 1440px)');

  return (
    <div className='my-4'>
      <ActionCard
        actionComponent={
          <Button
            as={ExternalLink}
            target='_blank'
            href={API_DOCS}
            variant='link'
            className='m-5'
          >
            <Icon name='external-link' size='sm' className='me-1 mb-1' />
            <span>Acessar</span>
          </Button>
        }
        title='Acesse a nossa documentação da API'
        titleClassName='text-dark h4'
        subtitle='Ela contém o manual para programadores entenderem como usar a API do Agendor.'
        subtitleClassName='text-dark-gray'
        icon='proposal'
        iconClassName='me-2'
        cardClassName={`bg-light border-0 pe-4 ps-3 mb-7 ${maxWidthCard ? 'max-width-7' : ''}`}
      />

      <SectionTitle title='Como utilizar as rotas da API' />
      <SectionDescription text='Veja alguns exemplos de como utilizar a API do Agendor' />

      <ApiLink title='Biblioteca do Postman com exemplos de uso da API V3'
        href={POSTMAN_API_V3_EXEMPLES}
        recommended
      />
      <ApiLink title='Exemplos de como utilizar a API do Agendor' href={helpUrl('api-exemples')} />

      <SectionTitle title='Webhooks no Agendor' />
      <SectionDescription text='Os Webhooks permitem que o seu sistema seja avisado em
       tempo real quando uma mudança ocorrer no Agendor.' />

      <ApiLink title='Biblioteca do Postman com exemplos de uso dos Webhooks.'
        href={POSTMAN_USE_WEBHOOK}
      />
      <ApiLink title='A API do Agendor possui Webhooks (gatilhos)?'
        href={helpUrl('api-webhooks-trigger')}
      />
    </div>
  );
}

const SectionTitle = ({ title }) => <div className='text-dark fw-bold h4 mb-4 mt-6'>{title}</div>;

const SectionDescription = ({ text }) => <div className='mb-4 text-dark-gray'>{text}</div>;

const ApiLink = ({ title, href, recommended = false }) => (
  <NavLink
    className='text-primary fw-bold cursor-pointer mb-4'
    as={ExternalLink}
    target='_blank'
    href={href}
  >
    <Icon name='link' className='text-primary me-1' />
    {title}
    {recommended && (
      <Badge variant='light-green' className='py-1 px-2 text-small ms-2'>RECOMENDADO</Badge>
    )}
  </NavLink>
);

export default DeveloperArea;

import React from 'react';
import { useApiToken } from '@/api';
import { helpUrl } from '@/routes';
import Card from '@/components/Card';
import SecretInput from '@/components/Inputs/SecretInput';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';
import LoadingPage from '@/components/LoadingPage';
import NavLink from '@/components/NavLink';

function IntegrationsApiToken() {
  const { data: apiToken, isLoading } = useApiToken();

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <div className='my-6'>
        <Card className='p-6 border-1' style={{ width: '720px' }}>
          <span className='mt-2 text-dark fw-bold h1'>Token para usar a API do Agendor</span>
          <span className='mt-4 mb-3 text-dark-gray'>
            A API do Agendor foi elaborada para que seja possível a integração do Agendor com
            outros sistemas, como por exemplo: enviar os contatos do formulário de seu site
            automaticamente para o Agendor.
          </span>
          <SecretInput value={apiToken?.data?.token} />
        </Card>
      </div>
      <div className='my-4'>
        <div className='text-dark mb-4 h2 fw-bold'>Saiba mais sobre o Token</div>
        <div className='text-dark-gray'>
          O Token da conta é uma chave de uso confidencial responsável pela integração direta
          entre um sistema e <br /> outro.
        </div>
        <div className='text-dark-gray my-4'>
          Em uma interpretação mais técnica, essa chave permite que sistemas sejam conectados
          <br />
          trocando parâmetros de URL, por meio de códigos ID.
        </div>
        <NavLink
          className='text-primary fw-bold cursor-pointer'
          as={ExternalLink}
          target='_blank'
          href={helpUrl('api-token')}
        >
          <Icon name='external-link' className='text-primary me-1' />
          O que é e para que usamos o Token da conta?
        </NavLink>
      </div>
    </>
  );
}

export default IntegrationsApiToken;

import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntegrations } from '@/api';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingPage from '@/components/LoadingPage';
import IntegrationCard from '@/feature/integration/IntegrationCard';
import IntegrationModal from '@/components/IntegrationModal';
import { legacyIntegrationsUrl, settingsIntegrationsPath } from '@/routes';

function IntegrationsIndex() {
  const { data: integrations, isLoading } = useIntegrations();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const activeIntegrations = useMemo(() => (integrations?.data ?? [])
    .filter((integration) => integration.active)
    .map((integration) => ({
      id: integration?.id,
      logo: integration?.logo?.[0]?.url,
      name: integration?.name,
      tag: integration?.tag?.[0]?.value?.toUpperCase(),
      shortDescription: integration?.shortDescription,
      legacy: integration?.legacy,
      slug: integration?.slug,
      categories: integration?.categories.map((category) => category.value),
      contentFiles: integration?.contentFiles.map((contentFile) => contentFile.url)
    })), [integrations]);

  useEffect(() => {
    if (!isLoading && activeIntegrations.length) {
      const integration = activeIntegrations.find(({ slug }) => pathname?.includes(slug));
      if (integration) {
        if (integration?.legacy) {
          const redirectUrl = `${legacyIntegrationsUrl()}/#${integration.slug}`;

          window.location.href = redirectUrl;
        } else {
          setSelectedIntegration(integration);
          setShowModal(true);
        }
      }
    }
  }, [pathname, activeIntegrations, isLoading]);

  if (isLoading) {
    return (
      <LoadingPage
        subtitle='Aguarde só um pouco, estamos carregando as integrações.'
      />
    );
  }

  const handleOpenModal = (integration) => {
    if (integration.legacy) {
      const redirectUrl = `${legacyIntegrationsUrl()}/#${integration.slug}`;

      window.location.href = redirectUrl;
      return;
    }
    navigate(`${settingsIntegrationsPath()}/${integration.slug}`, { replace: true });
    setShowModal(true);
    setSelectedIntegration(integration);
  };

  const handleCloseModal = () => {
    navigate(settingsIntegrationsPath(), { replace: true });
    setShowModal(false);
    setSelectedIntegration(null);
  };

  return (
    <>
      <div className='my-6'>
        <Row className='g-4 row-cols-auto'>
          {activeIntegrations?.map((integration, index) => (
            <Col key={integration.id}>
              <IntegrationCard key={index} integration={integration} onClick={handleOpenModal} />
            </Col>
          ))}
        </Row>
      </div>

      <IntegrationModal
        slug={selectedIntegration?.slug}
        show={showModal}
        onHide={handleCloseModal}
      />
    </>
  );
}

export default IntegrationsIndex;

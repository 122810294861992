import { useQuery } from 'react-query';
import { accessoryCallCache } from '@/utils';
import { get } from '@/lib/fetch';

export const inboxesKeys = {
  all: ['inboxes'],
  lists: () => [...inboxesKeys.all, 'list'],
  list: (params) => [...inboxesKeys.lists(), params]
};

export async function getACInboxes(params = {}) {
  const { data } = await get('/agendor_chat/inboxes', params);

  return data;
}

export function useACInboxes({ params = {}, config = {} } = {}) {
  return useQuery({
    ...accessoryCallCache,
    ...config,
    queryKey: inboxesKeys.list(params),
    queryFn: () => getACInboxes(params)
  });
}

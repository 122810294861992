import React from 'react';
import { ActivityTypeSelect,
  DurationInput,
  FunnelSelect,
  StageSelect,
  TextArea,
  UserSelect } from '@/components/Inputs';
import ActivitiesTemplatesDropdown
  from '@/components/EntityModal/Activities/ActivitiesTemplatesDropdown';
import DependentFormField from '@/components/DependentFormField';
import FormField from '@/components/FormField';
import * as Yup from 'yup';

export const validations = {
  activity: Yup.object().shape({
    type: Yup.string().nullable()
      .required('Por favor, selecione o tipo da atividade.'),
    owner_user_id: Yup.array().nullable()
      .required('Por favor, selecione o usuário responsável.')
  }),
  funnel: Yup.object().shape({
    funnel_id: Yup.number().nullable()
      .required('Por favor, selecione o funil.')
  }),
  action_funnel: Yup.object().shape({
    funnel_id: Yup.number().nullable()
      .required('Por favor, selecione o funil.')
  }),
  funnel_stage: Yup.object().shape({
    funnel_id: Yup.number().nullable()
      .required('Por favor, selecione o funil.'),
    stage_id: Yup.number().nullable()
      .required('Por favor, selecione a etapa.')
  }),
  email: Yup.object().required(),
  whatsapp: Yup.object().required()
};

export const triggerDefaultValues = {
  funnel_stage: {
    funnel_id: null,
    stage_id: null
  },
  funnel: {
    funnel_id: null
  }
};

export const actionDefaultValues = {
  activity: {
    type: null,
    due_in: null,
    text: '',
    owner_user_id: ['entity_owner_id']
  },
  action_funnel: {
    funnel_id: null
  }
};

function getDefaultOptionsLabel(event) {
  if (event === 'on_deal_stage_arrived') {
    return 'Responsável pelo negócio';
  } else if (event === 'on_deal_created') {
    return 'Responsável pelo negócio';
  } else if (event === 'on_organization_created') {
    return 'Responsável pela empresa';
  } else {
    return 'Responsável pela pessoa';
  }
}

function CardFormField(props) {
  const { type, dependant, event } = props;
  const cardFields = {
    activity: {
      as: ActivityTypeSelect,
      name: 'action_params.type',
      label: 'Tipo da atividade',
      placeholder: 'Ex.: Ligação',
      className: 'flex-1',
      isClearable: true
    },
    funnel: {
      as: FunnelSelect,
      name: 'trigger_params.funnel_id',
      label: 'Selecionar o funil',
      placeholder: 'Ex.: Funil de Vendas',
      className: 'flex-1',
      isClearable: true
    },
    stage: {
      as: StageSelect,
      name: 'trigger_params.stage_id',
      label: 'Selecionar a etapa',
      placeholder: 'Ex.: Contato',
      source: 'trigger_params.funnel_id',
      target: 'funnelId',
      autohide: true,
      className: 'flex-1'
    },
    action_funnel: {
      as: FunnelSelect,
      name: 'action_params.funnel_id',
      label: 'Selecionar o funil',
      placeholder: 'Ex.: Funil de Pós-Vendas',
      className: 'flex-1',
      isClearable: true
    },
    duration: {
      as: DurationInput,
      name: 'action_params.due_in',
      label: 'Para quando?',
      placeholder: 'Ex.: Para 2 dias depois',
      className: 'flex-1'
    },
    activity_description: {
      as: TextArea,
      name: 'action_params.text',
      label: 'Descrição da atividade',
      placeholder: 'Qual o próximo passo?',
      maxRows: 10,
      toolbar: <ActivitiesTemplatesDropdown />
    },
    activity_owners: {
      as: UserSelect,
      name: 'action_params.owner_user_id',
      label: 'Definir responsável pela atividade',
      placeholder: 'Selecione',
      defaultOptions: [
        {
          label: getDefaultOptionsLabel(event),
          value: 'entity_owner_id'
        }
      ],
      multiple: true,
      autoAdjustValues: true,
      isSearchable: true,
      className: 'mb-0'
    }
  };

  const fieldData = cardFields[type];

  if (!fieldData) {
    return null;
  }

  let fields = <FormField {...fieldData} />;

  if (dependant) {
    const dependantData = cardFields[dependant];
    const { as: dependantAs,
      name: dependantName,
      label: dependantLabel,
      placeholder: dependantPlaceholder,
      className: dependantClassName,
      source,
      target,
      autohide } = dependantData;

    fields = (
      <>
        {fields}
        <DependentFormField
          as={dependantAs}
          name={dependantName}
          label={dependantLabel}
          placeholder={dependantPlaceholder}
          className={dependantClassName}
          source={source}
          target={target}
          autohide={autohide}
        />
      </>
    );
  }

  return fields;
}

export default CardFormField;

import React from 'react';
import Dropdown from '@/components/Dropdown';
import Icon from '@/components/Icon';
import ExternalLink from '@/components/ExternalLink';
import Badge from '@/components/Badge';
import HeaderNews from '@/components/HeaderNews';
import {
  legacyAccountUrl,
  legacyUsersUrl,
  legacyGroupsUrl,
  legacyTeamsUrl,
  legacyDepartmentsUrl,
  settingsPath,
  legacyLogoutUrl,
  helpUrl,
  changelogUrl,
  legacyPlansUrl,
  settingsIntegrationsPath
} from '@/routes';
import { useAuth } from '@/lib/auth';

function HeaderMenu() {
  const { user } = useAuth();

  const canSeePlansTab = user.privileges.account_admin;

  return (
    <Dropdown align='end' className='align-self-center me-3'>
      <Dropdown.Toggle
        id='header-menu-toggle'
        className='px-3 py-2 border-0'
        variant='transparent-dark'
        aria-label='Menu'
      >
        <Icon name='menu' />
      </Dropdown.Toggle>

      <Dropdown.Menu renderOnMount
        /*
         * O react-bootstrap não usa o popper quando o dropdown está dentro de
         * uma navbar (não sei por quê), por isso a margin-top aqui.
         */
        className='min-width-5 mt-2 z-index-1060'
      >
        <Dropdown.Item
          id='header-menu-item-news'
          href={changelogUrl()}
          as={ExternalLink}
          target='_blank'
          className='position-relative'
          style={{ '--content': '"Novidades"' }}
        >
          Novidades
        </Dropdown.Item>
        <Dropdown.Divider />

        <Dropdown.Item
          as={ExternalLink}
          href={legacyAccountUrl()}
        >
          Minha conta
        </Dropdown.Item>

        {
          canSeePlansTab && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item
                as={ExternalLink}
                href={legacyPlansUrl()}
              >
                Planos e preços
              </Dropdown.Item>
            </>
          )
        }

        <Dropdown.Divider />

        <Dropdown.Item
          as={ExternalLink}
          href={legacyUsersUrl()}
        >
          Usuários
        </Dropdown.Item>
        <Dropdown.Divider />

        <Dropdown.Item
          as={ExternalLink}
          href={legacyGroupsUrl()}
        >
          Grupos
        </Dropdown.Item>
        <Dropdown.Divider />

        <Dropdown.Item
          as={ExternalLink}
          href={legacyTeamsUrl()}
        >
          Equipes
        </Dropdown.Item>
        <Dropdown.Divider />

        <Dropdown.Item
          as={ExternalLink}
          href={legacyDepartmentsUrl()}
        >
          Departamentos
        </Dropdown.Item>
        <Dropdown.Divider />

        <Dropdown.Item
          href={settingsPath()}
        >
          Configurações

          <Badge variant='primary' className='py-1 px-2 ms-3'>
            Novidade
          </Badge>
        </Dropdown.Item>
        <Dropdown.Divider />

        <Dropdown.Item
          as={ExternalLink}
          href={settingsIntegrationsPath()}
        >
          Integrações
        </Dropdown.Item>
        <Dropdown.Divider />

        <Dropdown.Item
          as={ExternalLink}
          href={legacyLogoutUrl()}
        >
          Sair
        </Dropdown.Item>
        <Dropdown.Divider />

        <Dropdown.Item
          as={ExternalLink}
          href={helpUrl()}
          className='bg-light'
          target='_blank'
        >
          <Icon
            name='help'
            size='sm'
            className='text-primary me-2 align-middle'
            withStroke
          />

          <span className='align-middle'>Central de Ajuda</span>
        </Dropdown.Item>
      </Dropdown.Menu>

      <HeaderNews selector='#header-menu-item-news' />
    </Dropdown>
  );
}

export default HeaderMenu;

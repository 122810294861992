import React from 'react';

import {
  Keyword,
  Section,
  SpoilerCard,
  TemplateCard,
  templates,
  getAutomationSections
} from '@/feature/automation';
import Authorization from '@/components/Authorization';
import { canAccessAgendorChatMessagesAutomation } from '@/policies/automations';

const sections = getAutomationSections(templates);

function AutomationsTemplates() {
  return (
    <div>
      <Authorization
        policy={canAccessAgendorChatMessagesAutomation}
      >
        <>
          <Section name='send_whatsapp' badge='Novidade'>
            {sections.send_whatsapp.map((template) => (
              <TemplateCard key={template.triggerName} template={template} />
            ))}
          </Section>
        </>
      </Authorization>

      <Section name='schedule_activity'>
        {sections.schedule_activity.map((template) => (
          <TemplateCard key={template.triggerName} template={template} />
        ))}

        <SpoilerCard name='Quando um negócio estiver parado em uma etapa → agendar uma atividade'>
          <SpoilerCard.TriggerBox>
            <SpoilerCard.Icon name='funnel' />

            <span>Quando um <Keyword>negócio</Keyword> estiver parado em uma etapa</span>
          </SpoilerCard.TriggerBox>

          <SpoilerCard.ActionBox>
            <SpoilerCard.Icon name='task' />

            <span>Agendar uma <Keyword>atividade</Keyword></span>
          </SpoilerCard.ActionBox>
        </SpoilerCard>

        <SpoilerCard name='Quando uma atividade for finalizada → agendar a próxima'>
          <SpoilerCard.TriggerBox>
            <SpoilerCard.Icon name='task' />

            <span>Quando uma <Keyword>atividade</Keyword> for finalizada</span>
          </SpoilerCard.TriggerBox>

          <SpoilerCard.ActionBox>
            <SpoilerCard.Icon name='task' />

            <span>Agendar a <Keyword>próxima</Keyword></span>
          </SpoilerCard.ActionBox>
        </SpoilerCard>
      </Section>


      <Section name='create_deal'>
        {sections.create_deal.map((template) => (
          <TemplateCard key={template.triggerName} template={template} />
        ))}
      </Section>

      <Section name='send_email'>
        {sections.send_email.map((template) => (
          <TemplateCard key={template.triggerName} template={template} />
        ))}

        <SpoilerCard name='Quando um negócio for adicionado → enviar um e-mail'>
          <SpoilerCard.TriggerBox>
            <SpoilerCard.Icon name='deal' />

            <span>Quando um <Keyword>negócio</Keyword> for adicionado</span>
          </SpoilerCard.TriggerBox>

          <SpoilerCard.ActionBox>
            <SpoilerCard.Icon name='email-sent' />

            <span>Enviar um <Keyword>e-mail</Keyword></span>
          </SpoilerCard.ActionBox>
        </SpoilerCard>

        <SpoilerCard name='Quando uma pessoa for adicionada → enviar um e-mail'>
          <SpoilerCard.TriggerBox>
            <SpoilerCard.Icon name='person' />

            <span>Quando uma <Keyword>pessoa</Keyword> for adicionada</span>
          </SpoilerCard.TriggerBox>

          <SpoilerCard.ActionBox>
            <SpoilerCard.Icon name='email-sent' />

            <span>Enviar um <Keyword>e-mail</Keyword></span>
          </SpoilerCard.ActionBox>
        </SpoilerCard>

        <SpoilerCard name='Quando uma empresa for adicionada → enviar um e-mail'>
          <SpoilerCard.TriggerBox>
            <SpoilerCard.Icon name='organization' />

            <span>Quando uma <Keyword>empresa</Keyword> for adicionada</span>
          </SpoilerCard.TriggerBox>

          <SpoilerCard.ActionBox>
            <SpoilerCard.Icon name='email-sent' />

            <span>Enviar um <Keyword>e-mail</Keyword></span>
          </SpoilerCard.ActionBox>
        </SpoilerCard>
      </Section>
    </div>
  );
}

export default AutomationsTemplates;

import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Tabs from '@/components/Tabs';
import NavLink from '@/components/NavLink';
import Main from '@/components/Main';
import * as Settings from '@/feature/settings';
import { useDocumentTitle } from '@/hooks';
import {
  settingsIntegrationsPath,
  settingsIntegrationsDashboardPath,
  settingsIntegrationsApiTokenPath
} from '@/routes';

const PAGE_TITLE = 'Integrações';

function IntegrationsBase() {
  const { pathname } = useLocation();

  const indexPath = settingsIntegrationsPath();
  const apiTokenPath = settingsIntegrationsApiTokenPath();
  const dashboardPath = settingsIntegrationsDashboardPath();

  const defaultActivePath = (path) => (
    path === indexPath && path !== apiTokenPath && path !== dashboardPath
  );

  useDocumentTitle(PAGE_TITLE);

  return (
    <Main>
      <Settings.PageTitle>
        {PAGE_TITLE}
      </Settings.PageTitle>

      <Tabs className='border-bottom mt-5 border-1'>
        <Tabs.Item>
          <NavLink
            href={settingsIntegrationsPath()}
            active={defaultActivePath(pathname)}
            end
          >
            Integrações prontas
          </NavLink>
        </Tabs.Item>
        <Tabs.Item>
          <NavLink href={settingsIntegrationsApiTokenPath()} end>
            Token da API
          </NavLink>
        </Tabs.Item>
        <Tabs.Item>
          <NavLink href={settingsIntegrationsDashboardPath()} end>
            Área do desenvolvedor
          </NavLink>
        </Tabs.Item>
      </Tabs>
      <Outlet />
    </Main>
  );
}

export default IntegrationsBase;

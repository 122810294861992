import React, { useState } from 'react';
import { TextInput } from '@/components/Inputs';
import Button from '@/components/Button';
import Tooltip from '@/components/Tooltip';
import Icon from '@/components/Icon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';

const propTypes = {
  value: PropTypes.string.isRequired,
  hiddenPlaceholder: PropTypes.string
};

const defaultProps = {
  value: '',
  hiddenPlaceholder: '••••••••••••••••••••••••••••••••••••••••••••••••'
};

const COPY_STATE = 'Copiar';
const COPIED_STATE = 'Copiado!';

function SecretInput({ value, hiddenPlaceholder }) {
  const [tooltipCopyContent, setTooltipCopyContent] = useState(COPY_STATE);
  const [isHidden, setIsHidden] = useState(true);
  const tooltipContent = isHidden ? 'Ver Token' : 'Esconder Token';

  const handleOnCopy = () => setTooltipCopyContent(COPIED_STATE);

  const handleCopyExited = () => setTooltipCopyContent(COPY_STATE);

  const toggleVisibility = () => setIsHidden(!isHidden);


  return (
    <div className='text-small rounded-1 bg-light d-flex align-items-center'>
      <TextInput
        className='mb-0 w-100'
        value={isHidden ? hiddenPlaceholder : value}
        readOnly
      />
      <Tooltip content={tooltipContent}>
        <Button variant='link' className='pe-1' onClick={toggleVisibility}>
          <Icon size='sm' name={isHidden ? 'hidden' : 'visible'} />
        </Button>
      </Tooltip>
      <Tooltip onExited={handleCopyExited} content={tooltipCopyContent}>
        <div>
          <CopyToClipboard onCopy={handleOnCopy} text={value}>
            <Button variant='link' className='ps-0'>
              <Icon size='sm' name='copy' />
            </Button>
          </CopyToClipboard>
        </div>
      </Tooltip>
    </div>
  );
}

SecretInput.propTypes = propTypes;
SecretInput.defaultProps = defaultProps;
export default SecretInput;

import { get } from '@/lib/fetch';
import { useQuery } from 'react-query';

export const chatSubscriptionPreviewKeys = {
  all: ['chat-subscription-preview'],
  lists: () => [...chatSubscriptionPreviewKeys.all, 'list'],
  list: (params) => [...chatSubscriptionPreviewKeys.lists(), params]
};

export async function showChatSubscriptionPreview(params = {}) {
  const { data } = await get('/billing/chat_subscription_preview', params);

  return data;
}

export function useChatSubscriptionPreview({ config = {}, params = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: chatSubscriptionPreviewKeys.list(params),
    queryFn: () => showChatSubscriptionPreview(params)
  });
}

import { useMutation } from 'react-query';
import { post, patch } from '@/lib/fetch';

export async function createChatSubscription(params = {}) {
  const { data } = await post('/billing/chat_subscriptions', params);

  return data;
}

export async function generateChatSecondCharge(params = {}) {
  const { data } = await post('/billing/chat_subscriptions/generate_second_charge', params);

  return data;
}

export async function updateChatSubscription(params = {}) {
  const { data } = await patch('/billing/chat_subscriptions', params);

  return data;
}

export function useCreateChatSubscription({ config = {} } = {}) {
  return useMutation(
    createChatSubscription,
    { ...config }
  );
}

export function useGenerateChatSecondCharge({ config = {} } = {}) {
  return useMutation(
    generateChatSecondCharge,
    { ...config }
  );
}

export function useUpdateChatSubscription({ config = {} } = {}) {
  return useMutation(
    updateChatSubscription,
    { ...config }
  );
}

export async function updateChatSubscriptionPaymentMethod(params = {}) {
  const { data } = await patch('/billing/chat_subscriptions/update_payment_method', params);

  return data;
}

export function useUpdateChatSubscriptionPaymentMethod({ config = {} } = {}) {
  return useMutation(
    updateChatSubscriptionPaymentMethod,
    { ...config }
  );
}

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DetailsCard from '@/components/EntityModal/DetailsCard';
import VoipPopover from '@/components/Voip/CallPopover/Popover';
import DetailsEmail from '@/components/EntityModal/DetailsEmail';
import DetailsWhatsApp from '@/feature/whatsapp/DetailsWhatsApp';
import {
  useOnUpdate,
  useOnUpdatePerson,
  useOrganization,
  useVoip
} from '@/contexts/organizationModal';

function Actions() {
  const organization = useOrganization();
  const onUpdateOrganization = useOnUpdate();
  const onUpdatePerson = useOnUpdatePerson();
  const voip = useVoip();

  return (
    <DetailsCard title='Ações' className='mb-3'>
      <Row className='g-2'>
        <Col sm={6}>
          <DetailsEmail entityId={organization.id} entityType='organization' showText />
        </Col>
        <Col sm={6}>
          <VoipPopover
            voip={voip}
            entity={organization}
            entityType='organization'
            onUpdatePerson={onUpdatePerson}
            onUpdateOrganization={onUpdateOrganization}
          />
        </Col>
        <Col sm={6}>
          <DetailsWhatsApp
            entityType='organization'
            entity={organization}
            relatedEntity={organization?.people}
            onUpdatePerson={onUpdatePerson}
            onUpdateOrganization={onUpdateOrganization}
          />
        </Col>
      </Row>
    </DetailsCard>
  );
}

export default Actions;

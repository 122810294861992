import React, { useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isNotFoundError } from '@/errors';
import { checkoutPath, chatCheckoutPath, legacyPlansUrl } from '@/routes';

import { redirect } from '@/browser';
import {
  useChatSubscriptionPreview,
  useCurrentChatPlan,
  useCurrentPlan,
  useSubscriptionPreview
} from '@/api';
import { useDocumentTitle, useQueryParams, useStepper } from '@/hooks';
import { useOffcanvasPageContext, UpdateSubscriptionProvider } from '@/contexts';

import Button from '@/components/Button';
import ConfirmUpdatePlanStep from '@/components/Billing/ConfirmUpdatePlanStep';
import Footer from '@/components/Footer';
import Icon from '@/components/Icon';
import Main from '@/components/Main';
import PreviewProratedPlanStep from '@/components/Billing/PreviewProratedPlanStep';

const PAGE_TITLE = 'Plano';

const propTypes = {
  crmSubscription: PropTypes.bool
};

function UpdatePlan({ currentPlanData, crmSubscription }) {
  useDocumentTitle(PAGE_TITLE);
  const canvasContext = useOffcanvasPageContext();

  const {
    new_plan_code: queryPlan,
    new_plan_max_users: queryMaxUsers
  } = useQueryParams();

  const planCode = queryPlan || currentPlanData.productCode;
  const maxUsers = Number(queryMaxUsers) || currentPlanData.maxUsersCount;

  const { nextStep, prevStep, step } = useStepper(1);
  const [selectedMaxUsers, setSelectedMaxUsers] = useState(maxUsers);
  const [selectedPlan, setSelectedPlan] = useState(planCode);
  const [updatedSubscriptionPreview, setUpdatedSubscriptionPreview] = useState();
  const [currentSubscritionPreview, setCurrentSubscriptionPreview] = useState();
  const subscriptionPreviewQuery = crmSubscription
    ? useSubscriptionPreview
    : useChatSubscriptionPreview;

  const checkoutUrl = crmSubscription ? checkoutPath : chatCheckoutPath;

  const {
    isSuccess: loadedSubscriptionPreview
  } = subscriptionPreviewQuery({
    config: {
      cacheTime: 0,
      onSuccess: ({ data }) => setUpdatedSubscriptionPreview(data)
    },
    params: {
      period: currentPlanData.periodicity,
      product_code: selectedPlan,
      users_count: selectedMaxUsers,
      only_prorate: true
    }
  });

  const { isSuccess: loadedCurrentSubscriptionPreview } = subscriptionPreviewQuery({
    params: {
      period: currentPlanData.periodicity,
      product_code: currentPlanData.productCode,
      users_count: currentPlanData.maxUsersCount
    },
    config: {
      onSuccess: ({ data }) => setCurrentSubscriptionPreview(data)
    }
  });

  const closePlanConfig = canvasContext.hide;

  const value = {
    currentPlanData,
    selectedMaxUsers,
    setSelectedMaxUsers,
    selectedPlan,
    setSelectedPlan,
    loadedSubscriptionPreview,
    updatedSubscriptionPreview,
    loadedCurrentSubscriptionPreview,
    currentSubscritionPreview,
    closePlanConfig,
    nextStep,
    prevStep,
    crmSubscription
  };

  const CurrentStep = useMemo(() => {
    const steps = [
      <PreviewProratedPlanStep key={1} />,
      <ConfirmUpdatePlanStep key={2} />
    ];

    return steps[step];
  }, [step]);

  if (currentPlanData.expiredOrExpiringToday) {
    return <Navigate to={checkoutUrl()} />;
  }

  return (
    <UpdateSubscriptionProvider value={value}>
      <Main fluid className='pt-6 px-5'>
        <Container className='d-flex h-100 flex-column'>
          <div className='d-flex align-items-start'>
            <div className='flex-fill'>
              <h2 className='mb-2'>{PAGE_TITLE}</h2>
            </div>

            <Button
              className={'rounded border-0 px-2 py-1'}
              variant='light-gray'
              type='button'
              aria-label='Fechar'
              onClick={closePlanConfig}
            >
              <Icon className='text-dark-gray' name='close' />
            </Button>
          </div>

          <hr className='mb-7' />

          {CurrentStep}

        </Container>
      </Main>
      <Footer id='manage-plan-footer' className='mt-auto flex-shrink-0 pb-3 ps-6 pe-9 me-9' />
    </UpdateSubscriptionProvider>
  );
}

function UpdatePlanWrapper({ crmSubscription }) {
  const query = crmSubscription ? useCurrentPlan : useCurrentChatPlan;

  const {
    data: { data: currentPlanData } = {}
  } = query(
    {
      config: {
        onError: (err) => {
          if (isNotFoundError(err)) {
            redirect(legacyPlansUrl());
          }
        }
      }
    }
  );

  if (!currentPlanData) {
    return null;
  }

  return (
    <UpdatePlan
      currentPlanData={currentPlanData}
      crmSubscription={crmSubscription}
    />
  );
}

UpdatePlan.propTypes = propTypes;

export default UpdatePlanWrapper;

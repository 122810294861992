import React from 'react';
import Stack from 'react-bootstrap/Stack';
import Card from '@/components/Card';
import Icon from '@/components/Icon';
import ActivityActionFields from '@/feature/automation/ActivityActionFields';
import DealActionFields from '@/feature/automation/DealActionFields';
import EmailActionFields from '@/feature/automation/EmailActionFields';
import MessageActionFields from '@/feature/automation/MessageActionFields';

const fields = {
  whatsapp: MessageActionFields,
  activity: ActivityActionFields,
  action_funnel: DealActionFields,
  email: EmailActionFields
};

function ActionFields(props) {
  const { template, template: { actionIdentifier, actionIcon, actionForm } } = props ?? {};

  const Fields = fields[actionForm];

  if (!Fields) {
    return null;
  }

  return (
    <Card body className='p-4 border-1'>
      <Stack direction='horizontal' gap={2} className='mb-4'>
        <Card body bg='light' border='light'>
          <Icon name={actionIcon} className='text-dark-gray' />
        </Card>

        <h4>{actionIdentifier}</h4>
      </Stack>

      <Fields template={template} />
    </Card>
  );
}

export default ActionFields;

import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const currentChatPlanKeys = {
  currentChatPlan: ['current-chat-plan']
};

export async function getCurrentChatPlan(params = {}) {
  const { data } = await get('/current_chat_plan', params);

  return data;
}

export function useCurrentChatPlan({ params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: currentChatPlanKeys.currentChatPlan,
    queryFn: () => getCurrentChatPlan(params)
  });
}

import React, { useMemo, useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import * as Yup from 'yup';

import Card from '@/components/Card';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import { TextInput } from '@/components/Inputs';
import SkeletonWrapper from '@/components/SkeletonWrapper';
import BillingFooter from '@/components/Billing/BillingFooter';
import Button from '@/components/Button';
import CouponInput from '@/components/Billing/CouponInput';
import PlanSelect from '@/components/Billing/PlanSelect';
import Icon from '@/components/Icon';
import ExternalLink from '@/components/ExternalLink';
import Modal from '@/components/Modal';

import { useDebounce } from '@/hooks';
import { redirect } from '@/browser';
import { legacyPlansUrl, legacyUsersUrl } from '@/routes';
import { useCheckoutContext } from '@/contexts';
import { useRequestTrialDowngrade } from '@/api';
import { pluralize } from '@/utils';
import { toCurrencyString } from '@/number';

const defaultDebounceTime = 600;

const periodicityText = {
  monthly: 'mensalmente',
  quarterly: 'trimestralmente',
  half_yearly: 'semestralmente',
  yearly: 'anualmente'
};

const defaultValues = {
  users_count: 0
};

const ICP = 3;

const schema = Yup.object().shape({
  users_count: Yup.number()
    .required('Número de usuários é obrigatório.')
    .positive('A quantidade mínima de usuários é 1.')
});

function ChoosePlan() {
  const {
    usersCount,
    setUsersCount,
    checkoutResumeData,
    activeUsersCount,
    accountData,
    currentPlanData,
    plan,
    setPlan,
    period,
    couponCode,
    setCoupon,
    nextStep
  } = useCheckoutContext();

  const isTrial = currentPlanData.subscriptionType === 'trial';
  const currentValuePerUser = checkoutResumeData?.monthlyInfo.valuePerUser;
  const maxUsersCount = usersCount;
  const onChangeUsersCount = setUsersCount;
  const currentActiveUsersCount = activeUsersCount;
  const periodicity = checkoutResumeData.periodicity;
  const planName = checkoutResumeData.name;
  const salespersonNumber = accountData.salespersonNumber;
  const subscriptionMaxUsers = currentPlanData.maxUsersCount;

  const [showModal, setShowModal] = useState(false);
  const [showInput, setShowInput] = useState(couponCode);
  const [error, setError] = useState('');
  const alert = useAlert();

  const debounceHandler = useDebounce((fn) => fn(), defaultDebounceTime);

  const initialValues = useMemo(() => ({ users_count: maxUsersCount }), [maxUsersCount]);

  const employees = parseInt(salespersonNumber, 10);
  const requestTrialDowngrade = useRequestTrialDowngrade();

  useEffect(
    () => {
      const difference = currentActiveUsersCount - maxUsersCount;

      if (maxUsersCount > 0 && maxUsersCount < currentActiveUsersCount) {
        setError(
          `Sua conta tem ${currentActiveUsersCount} ` +
          `${pluralize('usuário', currentActiveUsersCount)}  ativos.\nDesative ${difference} ` +
          `${pluralize('usuário', difference)} para poder contratar ` +
          `o ${planName} ${maxUsersCount}.`
        );
      } else {
        setError('');
      }
    },
    [maxUsersCount]
  );

  const isIcpButton = useMemo(
    () => employees >= ICP &&
      maxUsersCount < ICP &&
      isTrial &&
      subscriptionMaxUsers === employees,
    [maxUsersCount]
  );

  const buttonClass = isIcpButton ? 'width-5' : 'width-4';
  const buttonVariant = isIcpButton ? 'yellow' : 'primary';
  const buttonText = isIcpButton ? 'Solicitar redução de plano' : 'Avançar';

  const handleTrialDowngrade = () => {
    requestTrialDowngrade.mutate({
      users_count: maxUsersCount
    }, {
      onError: (err) => {
        alert.show(
          `Error: ${err.message}`,
          { variant: 'danger', timeout: 5000 }
        );
      },
      onSuccess: () => {
        setShowModal(true);
      }
    });
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size='md'
        bodyClassName='p-8'
        backdrop='static'
      >
        <div className='pt-3 px-4 text-center'>
          <h2>Redução de plano solicitada</h2>
          <p className='pt-4 text-center'>Nossa equipe entrará em contato com você em breve</p>
        </div>

        <div className='mt-5 d-grid gap-2'>
          <Button variant='primary' onClick={() => setShowModal(false)}>
            Fechar
          </Button>
        </div>
      </Modal>

      <PlanSelect plan={plan} setPlan={setPlan} />

      <Card className='p-6 border-light'>
        <Form
          name='form'
          validationSchema={schema}
          defaultValues={defaultValues}
          initialValues={initialValues}
          onSubmit={nextStep}
        >
          {
            ({ handleSubmit }) => <>
              <div className='text-dark-gray fw-bold mb-4'>
                Quantas pessoas da sua empresa irão utilizar o Agendor? (incluindo você)
              </div>

              <div>
                <div className='fw-bold text-small'>Número de usuários</div>
                <FormField
                  as={TextInput}
                  name='users_count'
                  onChange={(value) => debounceHandler(() => onChangeUsersCount(Number(value)))}
                  className='max-width-5 mb-1'
                  type='number'
                  placeholder='4'
                  min='1'
                />
              </div>

              <SkeletonWrapper
                isLoading={!currentValuePerUser}
                SkeletonComponent={() => <div>Carregando</div>}
              >
                <div className='d-flex mb-1'>
                  <h1>{toCurrencyString(currentValuePerUser)}</h1>
                  <h1 className='mx-1'>/</h1>
                  <h4 className='fw-normal align-self-end mb-1'>por usuário</h4>
                </div>
              </SkeletonWrapper>

              <div className='fw-bold text-medium mt-2 mb-1'>
                Valor mensal, pago {periodicityText[periodicity]}
              </div>

              {error && (
                <div className='mt-4 yellow text-warning mb-1' style={{ whiteSpace: 'pre-line' }}>
                  {error}
                  <div className='mt-1'>
                    <Button as={ExternalLink}
                      className='me-3'
                      variant='outline-dark-gray'
                      href={legacyUsersUrl()}
                    >
                      <Icon name='edit' size='sm' className='me-1' />
                      Gerenciar usuários
                    </Button>
                  </div>
                </div>
              )}

              <BillingFooter
                buttonNextText={buttonText}
                onClickPrev={() => redirect(legacyPlansUrl())}
                onClickNext={isIcpButton ? handleTrialDowngrade : handleSubmit}
                disabled={error}
                buttonClass={buttonClass}
                buttonVariant={buttonVariant}
              />
            </>
          }
        </Form>

        <Button
          className='mb-1 text-underline p-0 text-dark-gray text-small align-self-start'
          onClick={() => setShowInput(true)}
          variant='link'
        >
          Aplicar cupom
        </Button>

        {
          showInput &&
          <CouponInput
            usersCount={usersCount}
            period={period}
            couponCode={couponCode}
            setCoupon={setCoupon}
          />
        }
      </Card>
    </>
  );
}

export default ChoosePlan;

import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const integrationsKeys = {
  all: ['integrations'],
  details: () => [...integrationsKeys.all, 'detail'],
  detail: (slug) => [...integrationsKeys.details(), slug]
};

export async function getIntegrations() {
  const { data } = await get('/integrations');
  return data;
}

export async function getIntegration(slug) {
  const { data } = await get(`/integrations/${slug}`);
  return data;
}

export function useIntegrations({ config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: integrationsKeys.all,
    queryFn: () => getIntegrations()
  });
}

export function useIntegration({ slug, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: integrationsKeys.detail(slug),
    queryFn: () => getIntegration(slug)
  });
}

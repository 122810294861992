import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import NavLink from '@/components/NavLink';
import Badge from '@/components/Badge';
import LoadingPage from '@/components/LoadingPage';
import { decode } from 'html-entities';
import { formatAddressLineWithoutPostalCode, replaceFileNamesSources } from '@/utils';
import { useIntegration, useCurrentAccount } from '@/api';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';

IntegrationModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  slug: PropTypes.string
};

IntegrationModal.defaultProps = {
  show: false,
  onHide: () => {},
  slug: ''
};

function IntegrationModal({ show, onHide, slug }) {
  const tracker = useTracking();
  const { user } = useAuth();

  const { data: { data: account } = {} } = useCurrentAccount({
    config: { enabled: Boolean(slug && show), staleTime: 1000 }
  });
  const {
    data: { data: integration } = {},
    isFetching
  } = useIntegration({
    slug, config: { enabled: Boolean(slug && show), staleTime: 1000 }
  });

  useEffect(() => {
    if (show && !isFetching && integration) {
      tracker.trackIntegrationModalOpened({
        user,
        integration: integration.name
      });
    }
  }, [show, isFetching, integration]);

  const integrationsParams = {
    vip_solutions: () => {
      let query = `id-cliente=${account?.id}&email=${user?.email}&telefone=${user?.workPhone}`;
      query = `${query}&nome=${user?.name}&razao-social=${account?.legalName}`;
      query = `${query}&nome-fantasia=${account?.name}&cep=${account?.address.postalCode}`;
      query = `${query}&endereco=${formatAddressLineWithoutPostalCode(account?.address)}`;
      return new URLSearchParams(query.trim()).toString();
    }
  };

  const handleLinkClick = (url) => {
    tracker.trackIntegrationModalCTAClicked({
      user,
      integration: integration.name
    });

    const ctaURL = `${url}?${integrationsParams[integration.slug]?.() ?? ''}`;
    window.open(ctaURL, '_blank');
  };

  const contentFiles = integration?.contentFiles.map((file) => file.url) || [];
  const rawHtml = decode(integration?.content?.replace(/\\/gu, ''));

  const html = replaceFileNamesSources(rawHtml, contentFiles);

  return (
    <Modal
      size='xxl'
      bodyClassName='modal-height-xxl p-0'
      show={show}
      onHide={onHide}
    >
      {isFetching
        ? (
          <LoadingPage
            className='modal-height-xxl'
            subtitle='Aguarde só um pouco, estamos carregando as informações da integração.'
          />
        )
        : (
          <div className='d-flex text-dark-gray h-100'>
            <aside className='p-5 w-25 bg-light rounded-start'>
              <div>
                <img src={integration?.logo[0].url} width={48} alt={'logo'} />
                <h3 className='my-4'>{integration?.name}</h3>
                <p>{integration?.shortDescription}</p>
              </div>

              <hr className='opacity-75' />

              <div className='d-flex flex-column align-items-center my-4'>
                {
                  integration?.primaryButtonText
                    ? (
                      <Button
                        variant='primary'
                        className='w-100'
                        onClick={() => handleLinkClick(integration?.primaryButtonUrl)}
                      >
                        {integration?.primaryButtonText}
                      </Button>
                    )
                    : null
                }
                {
                  integration?.secondaryButtonText
                    ? (
                      <NavLink
                        className='mt-4 mb-2 text-primary'
                        onClick={() => handleLinkClick(integration?.secondaryButtonUrl)}
                      >
                        {integration?.secondaryButtonText}
                      </NavLink>
                    )
                    : null
                }
              </div>
              <hr className='opacity-75' />
              <div className='my-4'>
                <p> Categorias</p>
                {
                  integration?.categories.map((category, index) => (
                    <Badge key={index} variant='dark-gray' className='p-2 me-2 mb-1'>
                      <span className='text-smaller'>
                        {category.value}
                      </span>
                    </Badge>
                  ))
                }
              </div>
              <hr className='opacity-75' />
              <div className='my-4'>
                <p> Desenvolvido por</p>
                <NavLink
                  className='fw-bold text-primary'
                  onClick={() => handleLinkClick(integration?.developedByUrl)}
                >
                  {integration?.developedByText}
                </NavLink>
              </div>
            </aside>

            <div className='p-5 w-75 overflow-y-scroll'>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        )}
    </Modal>
  );
}

export default IntegrationModal;

/* eslint-disable react/jsx-child-element-spacing */
import React, { useState, useMemo } from 'react';
import { useAlert } from 'react-alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import cellphoneRinging from 'images/cellphone-ringing.png';
import Main from '@/components/Main';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import LoadingButton from '@/components/LoadingButton';
import Modal from '@/components/Modal';
import Card from '@/components/Card';
import APIErrorMessage from '@/components/APIErrorMessage';
import ActionCard from '@/components/ActionCard';
import {
  useCurrentAccount,
  useUpdateCurrentAccount,
  useUpdateVoipToggleAccount,
  useVoipVerify
} from '@/api';
import { toCurrencyString } from '@/number';
import { helpUrl } from '@/routes';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';
import ExternalLink from '@/components/ExternalLink';
import UpdateCpfCnpjForm from '@/feature/voip/UpdateCpfCnpjForm';
import VoipGptInfo from '@/components/VoipGptInfo';
// Import emptyVoip from 'images/empty-voip.png';

const PAGE_TITLE = 'Ativar telefone virtual';
const HELP_TEXT = 'Tire suas dúvidas sobre Telefone virtual em nosso canal de suporte ao cliente';

function SettingsModulesVoip() {
  const alert = useAlert();
  const [showCpfCnpjModal, setShowCpfCnpjModal] = useState(false);
  const { data: voip, isLoading: isLoadingVoip } = useVoipVerify();
  const { data: currentAccountData, isLoading: isLoadingCurrentAccount } = useCurrentAccount();
  const updateMutation = useUpdateVoipToggleAccount();
  const updateCurrentAccountMutation = useUpdateCurrentAccount();

  const pricePerMinute = toCurrencyString(voip?.pricePerMinute);
  const accountData = useMemo(() => currentAccountData?.data, [JSON.stringify(currentAccountData)]);
  const isEnabled = useMemo(() => Boolean(voip?.enabled), [voip?.enabled]);
  const isCreated = useMemo(() => Boolean(voip?.created), [voip?.created]);
  const isLoading = isLoadingVoip || updateMutation.isLoading || isLoadingCurrentAccount;

  const tracker = useTracking();
  const { user } = useAuth();

  if (isLoadingVoip) {
    return null;
  }

  const onChangeActive = () => {
    updateMutation.mutate({}, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='modules/voip' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
      },
      onSuccess: (data) => {
        if (data?.activation) {
          tracker.trackVoipActivated({ user });
        }

        if (data?.created) {
          const alertLabel = isEnabled ? 'desativado' : 'ativado';
          alert.show(
            `Telefone virtual ${alertLabel} com sucesso!`,
            { variant: 'success', timeout: 5000 }
          );
        } else {
          alert.show(
            `Falha no telefone virtual.${data.error ? `\nMotivo: ${data.error}` : ''}`,
            { variant: 'danger', timeout: 5000 }
          );
        }
      }
    });
  };

  const onSubmitCpfCnpj = (values) => {
    updateCurrentAccountMutation.mutate(values, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} />,
          { variant: 'danger', timeout: 5000 }
        );
      },
      onSuccess: () => onChangeActive()
    });
  };

  const handleChangeActive = () => {
    if (!accountData?.cpfCnpj && !isEnabled && !showCpfCnpjModal) {
      setShowCpfCnpjModal(true);
    } else {
      onChangeActive();
    }
  };

  return (
    <Main fluid className='py-8 ps-4 pe-8'>
      <Modal
        show={showCpfCnpjModal && !isCreated}
        onHide={() => setShowCpfCnpjModal(false)}
        size='md'
        bodyClassName='p-8'
      >
        <div className='d-flex flex-column'>
          <img src={cellphoneRinging} className='width-4 align-self-center mb-4' />
          <p className='h2 align-self-center mb-2'>Ative o telefone virtual na sua conta</p>
          <p className='align-self-center'> Preencha os dados abaixo para finalizar a ativação. </p>
          {
            isLoading
              ? null
              : (
                <UpdateCpfCnpjForm
                  accountData={accountData}
                  onSubmit={onSubmitCpfCnpj}
                  onCancel={() => setShowCpfCnpjModal(false)}
                />
              )
          }
        </div>
      </Modal>
      <Container>
        <h1 className='mb-8'>{PAGE_TITLE}</h1>
        {/* <Card className='border-0 bg-light-primary py-4 px-8'>
          <Row className='d-flex align-items-center'>
            <Col md={10} sm={12} className='d-flex'>
              <img src={emptyVoip} />
              <div className='my-1'>
                <p className='my-3 h4'> Entenda como funciona nosso telefone virtual</p>
                <p className='text-dark-gray'>
                  Confira nosso vídeo explicando o passo a passo para você utilizar o telefone
                  virtual do Agendor e aumentar a produtividade melhorando o
                  atendimento ao cliente.
                </p>
              </div>
            </Col>
            <Col md={2} sm={12} className='d-flex justify-content-end'>
              <Modal
                show={showVideoTutorialModal}
                onHide={() => (setShowVideoTutorialModal(false))}
                size='xl'
                bodyClassName='p-0 height-6'
                restoreFocus={false}
                onExited={() => setShowVideoTutorialModal(false)}
              >
                <iframe
                  width='100%'
                  height='100%'
                  src='https://www.youtube.com/embed/GeqqYjqYBLY'
                  title='YouTube video player'
                  allowFullScreen
                >
                </iframe>
              </Modal>
              <Button
                onClick={() => setShowVideoTutorialModal(true)}
                variant='outline-dark-gray'
                className='border-outline-dark-gray me-2 rounded-1'>
                <Icon name='play' className='me-1' /> Ver video
              </Button>
            </Col>
          </Row>
        </Card> */}
        {
          !isCreated && !isLoading
            ? (
              <div className='text-success bg-light-green p-4 rounded my-2'>
                <Icon name='tag' className='me-4' size='lg' />
                Ative pela primeira vez o módulo de telefone virtual e ganhe
                <span className='fw-bold'> R$ 25,00 </span>
                em créditos para experimentar nosso serviço.
              </div>
            )
            : null
        }
        <Card className='border-light p-6'>
          <Row>
            <Col md={10} sm={12}>
              <div className='h4'>Telefone virtual</div>
              <div className='d-flex text-dark-gray justify-content-between my-2'>
                Permite chamadas de voz em tempo real e pode ajudar a
                melhorar a comunicação da empresa, aumentando a produtividade,
                reduzindo custos e melhorando o atendimento ao cliente.
              </div>
            </Col>
            <Col md={2} sm={12} className='d-flex justify-content-end align-items-center'>
              <div className='me-3'>
                {
                  isEnabled
                    ? (
                      <LoadingButton
                        isLoading={isLoading}
                        loadingText='Desativando...'
                        variant='light'
                        onClick={handleChangeActive}
                      >
                        Desativar
                      </LoadingButton>
                    )
                    : (
                      <LoadingButton
                        isLoading={isLoading}
                        loadingText='Ativando...'
                        variant='primary'
                        onClick={handleChangeActive}
                      >
                        Ativar agora
                      </LoadingButton>
                    )
                }
              </div>
            </Col>
          </Row>
        </Card>
        <Card className='border-light p-6 mt-4'>
          <div className='h4 mb-3'>Benefícios</div>
          <div className='d-flex'>
            <VoipGptInfo />
          </div>
          <div className='d-flex text-dark-gray mt-3'>
            <Icon name='check' size='sm' className='me-1 mt-1 text-dark-gray' />
            Funciona de forma pré-paga
          </div>
          <div className='d-flex text-dark-gray mt-4'>
            <Icon name='check' size='sm' className='me-1 mt-1 text-dark-gray' />
            Custo por minuto {pricePerMinute} para todo o Brasil
          </div>
          <div className='d-flex text-dark-gray mt-4'>
            <Icon name='check' size='sm' className='me-1 mt-1 text-dark-gray' />
            As ligações são gravadas e o áudio é anexado automaticamente no histórico do
            negócio
          </div>
        </Card>
        <div className='mt-4'>
          <ActionCard
            actionComponent={
              <Button
                as={ExternalLink}
                target='_blank'
                href={helpUrl('activate-voip')}
                variant='outline-dark-gray'
                className='h-25 m-5'
              >
                <Icon
                  name='external-link'
                  size='sm'
                  className='me-1'
                />
                <span>Acessar</span>
              </Button>
            }
            title='Central de Ajuda'
            titleClassName='text-dark h5'
            subtitle={HELP_TEXT}
            subtitleClassName='text-medium-gray'
            icon='help'
            iconClassName='bg-primary bg-opacity-10 m-5'
            cardClassName='border-lighter-gray mb-3 pe-4 ps-3'
          />
        </div>
      </Container>
    </Main>
  );
}

export default SettingsModulesVoip;

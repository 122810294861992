import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { distanceFromNowInCalendarDays, parseDate, toTextualMonthDateString } from '@/date';
import { checkoutPath, legacyUsersUrl, settingsManageCurrentPlanPath } from '@/routes';
import { pluralize } from '@/utils';
import { useUpdateSubscriptionContext } from '@/contexts';
import Alert from '@/components/Alert';
import CurrentSubscriptionPreview from '@/components/Billing/CurrentSubscriptionPreview';
import ExternalLink from '@/components/ExternalLink';
import Form from '@/components/Form';
import ManagePlanFooter from '@/components/Billing/ManagePlanFooter';
import { TextInput } from '@/components/Inputs';
import PendingBankSlipWarning from '@/components/PendingBankSlipWarning';
import { useAuth } from '@/lib/auth';
import UpdatePlanFields from '@/components/Billing/UpdatePlanFields';
import UpdatedSubscriptionPreview from '@/components/Billing/UpdatedSubscriptionPreview';

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  users_count: Yup.number()
    .when('$crmSubscription', {
      is: true,
      then: (validationSchema) => validationSchema
        .required('Número de usuários é obrigatório.')
        .positive('A quantidade mínima de usuários é 1.'),
      otherwise: (validationSchema) => validationSchema
        .required('Número de usuários é obrigatório.')
        .min(3, 'A quantidade mínima de usuários é 3.')
    }),
  plan_select: Yup.string()
    .when('$crmSubscription', {
      is: true,
      then: (validationSchema) => validationSchema
        .required('Por favor, selecione um plano.')
        .oneOf(['pro_plan', 'performance_plan', 'corporate_plan'], ''),
      otherwise: (validationSchema) => validationSchema.notRequired()
    })
});
/* eslint-enable no-magic-numbers */

const periodicityText = {
  monthly: 'mensal',
  quarterly: 'trimestral',
  half_yearly: 'semestral',
  yearly: 'anual'
};

function PreviewProratedPlanStep() {
  const {
    currentPlanData,
    selectedMaxUsers,
    selectedPlan,
    nextStep,
    updatedSubscriptionPreview,
    crmSubscription
  } = useUpdateSubscriptionContext();

  const { user } = useAuth();
  const { lastBankSlipExpirationDate } = user.account;

  const navigate = useNavigate();

  const remainingDays = distanceFromNowInCalendarDays(currentPlanData.expirationDate);

  const renovationDate = remainingDays === 0
    ? 'Hoje'
    : toTextualMonthDateString(parseDate(currentPlanData.expirationDate));

  const actualMaxUsers =
    `${currentPlanData.maxUsersCount} ${pluralize('usuário', currentPlanData.maxUsersCount)}`;

  const isDifferentPlan = selectedPlan !== currentPlanData.productCode;
  const isDifferentMaxUsers = selectedMaxUsers !== currentPlanData.maxUsersCount;
  const isUpgradeOrDowngrade = isDifferentPlan || isDifferentMaxUsers;

  const needReduceUsers = crmSubscription && selectedMaxUsers > 0 &&
  currentPlanData.currentActiveUsersCount > selectedMaxUsers;

  const diffUsersDowngrade = currentPlanData.currentActiveUsersCount - selectedMaxUsers;

  const pluralizedActiveUsersText = `
   ${pluralize('usuário', selectedMaxUsers)} ${pluralize('ativo', selectedMaxUsers)}.
  `;

  const initialValues = useMemo(() => ({
    users_count: selectedMaxUsers,
    plan_select: selectedPlan
  }), [selectedMaxUsers, selectedPlan]);

  const redirectToCheckout = () => {
    const queryParams = new URLSearchParams();

    queryParams.set('plan', selectedPlan);
    queryParams.set('maxUsers', selectedMaxUsers);

    const url = `${checkoutPath()}?${queryParams.toString()}`;

    navigate(url);
  };

  return (
    <>
      <Form
        name='form'
        validationSchema={schema}
        initialValues={initialValues}
        initialSubmit
        validationContext={{ crmSubscription }}
      >
        {({ isValid }) => (
          <>
            <Row>
              <Col sm={5} className='pe-0'>
                <div className='fw-bold mb-3'>PLANO ATUAL</div>
                {crmSubscription && (
                  <TextInput
                    name='actual_plan'
                    value={currentPlanData.name}
                    className='mb-3'
                    disabled
                  />
                )}
                <TextInput
                  name='actual_max_users'
                  value={actualMaxUsers}
                  disabled
                />
              </Col>

              <Col
                sm={1}
                className='d-flex justify-content-center align-items-center text-primary'
              >
                Para
              </Col>

              <Col sm={6} className='ps-0'>
                <UpdatePlanFields />
              </Col>
            </Row>
            <ManagePlanFooter
              buttonNextText='Avançar'
              onClickPrev={() => navigate(settingsManageCurrentPlanPath())}
              onClickNext={
                (lastBankSlipExpirationDate && crmSubscription) ? redirectToCheckout : nextStep
              }
              disabled={!isValid || !isUpgradeOrDowngrade || needReduceUsers}
            />
          </>
        )}
      </Form>
      {isUpgradeOrDowngrade && (
        <>
          {crmSubscription && (
            <PendingBankSlipWarning
              currentPlanFullName={`${currentPlanData.name} ${currentPlanData.maxUsersCount}`}
              selectedPlanFullName={`${updatedSubscriptionPreview?.name} ${selectedMaxUsers}`}
            />
          )}
          <Row>
            <Col sm={6} className='pe-0 fw-bold text-small'>
              <CurrentSubscriptionPreview
                periodicityText={periodicityText[currentPlanData.periodicity]}
                renovationDate={renovationDate}
              />
            </Col>
            <Col sm={6} className='ps-0 fw-bold text-small'>
              <UpdatedSubscriptionPreview
                periodicityText={periodicityText[currentPlanData.periodicity]}
                renovationDate={renovationDate}
              />
            </Col>
          </Row>

          {needReduceUsers && (
            <Alert variant='warning' className='text-warning mt-auto'>
              Para reduzir seu plano para o {updatedSubscriptionPreview?.name} {selectedMaxUsers},
              é necessário ter no máximo {selectedMaxUsers} {pluralizedActiveUsersText}
              <b>Sua conta possui {currentPlanData.currentActiveUsersCount} usuários ativos. </b>
              Para desativar {diffUsersDowngrade} deles{' '}
              <ExternalLink
                variant='link'
                target={'_blank'}
                href={legacyUsersUrl()}
                className='p-0 text-warning text-small'
              >
                <u>clique aqui</u>
              </ExternalLink>.
            </Alert>
          )}
        </>
      )}
    </>
  );
}

export default PreviewProratedPlanStep;

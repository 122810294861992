import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { useDocumentTitle } from '@/hooks';
import { useOffcanvasPageContext } from '@/contexts';
import { useTransaction } from '@/api';
import { useAlert } from 'react-alert';

import BankSlipCompletion from '@/components/Billing/BankSlipCompletion';
import Button from '@/components/Button';
import Footer from '@/components/Footer';
import Icon from '@/components/Icon';
import Main from '@/components/Main';
import PixCompletion from '@/components/Billing/PixCompletion';

import loading from 'images/full-page-loading.gif';

const REFETCH_INTERVAL = 5000;

function ChargeCompletion() {
  const location = useLocation();
  const { transactionId, paymentType } = location.state;
  const alert = useAlert();

  const [pollingEnabled, setPollingEnabled] = useState(true);
  const [transactionData, setTransactionData] = useState(null);

  const isPix = paymentType === 'pix';

  const PAGE_TITLE = isPix ? 'Gerar Pix' : 'Gerar boleto';
  useDocumentTitle(PAGE_TITLE);

  const canvasContext = useOffcanvasPageContext();
  const closeChargeCompletion = canvasContext.hide;

  const handleTransactionError = () => {
    alert.show(
      <div>
        <h6>Infelizmente, não conseguimos gerar o seu pix/boleto</h6>
        <div>Aguarde alguns minutos e tente novamente.</div>
        <div>Se o erro persistir, entre em contato com o nosso suporte.</div>
      </div>,
      { variant: 'danger' }
    );
  };

  useTransaction({
    billingTransactionId: transactionId,
    config: {
      refetchInterval: REFETCH_INTERVAL,
      cacheTime: 0,
      enabled: Boolean(pollingEnabled && transactionId),
      onSuccess: ({ data }) => {
        switch (data.statusName) {
          case 'Aguardando Pagto':
          case 'Aprovado':
          case 'Completo':
            return;
          case 'Em análise':
            if (isPix) {
              const details = data.transactionDetails;

              setTransactionData({
                qrCode: details.qr_code,
                expiresAt: details.expires_at,
                value: data.value
              });
            } else {
              const details = data.transactionDetails;

              setTransactionData({
                pdf: details.pdf,
                number: details.number,
                expiresAt: details.expires_at,
                value: data.value
              });
            }
            setPollingEnabled(false);

            return;
          default:
            setPollingEnabled(false);
            handleTransactionError();
        }
      }
    }
  });

  const renderCompletionContent = () => {
    const CompletionComponent = isPix ? PixCompletion : BankSlipCompletion;

    return (
      <CompletionComponent data={transactionData} />
    );
  };

  const renderLoadingContent = () => (
    pollingEnabled &&
      (
        <div className='mb-7 align-self-center' >
          <img width={70} src={loading} />
          <h5 className='mt-7'>Gerando...</h5>
        </div>
      )
  );

  return (
    <>
      <Main fluid className='pt-6 px-5'>
        <Container className='d-flex h-100 flex-column'>
          <div className='d-flex align-items-start'>
            <div className='flex-fill'>
              <h2 className='mb-2'>{PAGE_TITLE}</h2>
            </div>

            <Button
              className={'rounded border-0 px-2 py-1'}
              variant='light-gray'
              type='button'
              aria-label='Fechar'
              onClick={closeChargeCompletion}
            >
              <Icon className='text-dark-gray' name='close' />
            </Button>
          </div>

          <hr className='mb-7' />

          {!transactionData ? renderLoadingContent() : renderCompletionContent()}
        </Container>
      </Main>
      <Footer className='mt-auto flex-shrink-0 pb-6 ps-6 me-8' >
        <Container>
          <Row>
            <Col
              md={{ offset: 1, span: 10 }}
              className='d-flex'
            >
              <Button
                className='ms-auto me-3'
                variant='light-gray'
                disabled={pollingEnabled}
                onClick={closeChargeCompletion}
              >
                Fechar
              </Button>
            </Col>
          </Row>
        </Container>
      </Footer>
    </>
  );
}

export default ChargeCompletion;

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Card from '@/components/Card';
import Icon from '@/components/Icon';

const propTypes = {
  actionComponent: PropTypes.node.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cardClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  iconClassName: PropTypes.string
};

const defaultProps = {
  actionComponent: null,
  icon: '',
  title: '',
  subtitle: '',
  cardClassName: '',
  titleClassName: '',
  subtitleClassName: '',
  iconClassName: ''
};

function ActionCard({
  icon,
  title,
  subtitle,
  actionComponent,
  cardClassName,
  titleClassName,
  subtitleClassName,
  iconClassName
}) {
  return (
    <Card className={classnames('d-flex justify-content-between flex-row', cardClassName)}>
      <div className='d-flex align-items-center'>
        <div className={
          classnames(
            'rounded p-1 text-dark-gray lh-0 position-relative',
            iconClassName
          )}>
          <Icon name={icon} />
        </div>
        <div>
          <div className={classnames('mt-4 mb-2', titleClassName)}>
            {title}
          </div>
          <div className={classnames('mb-5', subtitleClassName)}>
            {subtitle}
          </div>
        </div>
      </div>
      {actionComponent}
    </Card>
  );
}

ActionCard.propTypes = propTypes;
ActionCard.defaultProps = defaultProps;

export default ActionCard;

import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import Icon from '@/components/Icon';
import Truncate from '@/components/Truncate';
import Button from '@/components/Button';
import CallButton from '@/feature/voip/ContactList/CallButton';

const propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    entity: PropTypes.oneOf(['organization', 'person']),
    phones: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.string,
        type: PropTypes.oneOf(['whatsapp', 'work_phone', 'mobile_phone'])
      })
    )
  }).isRequired,
  service: PropTypes.string,
  onClickAddContact: PropTypes.func
};

function ContactItem({ contact, service, onClickAddContact }) {
  const filledPhones = useMemo(
    () => {
      const fileldContacts = contact.phones.filter((phone) => Boolean(phone.callableNumber));
      return uniqBy(fileldContacts, 'callableNumber');
    },
    [contact.phones]
  );

  return (
    <div key={contact.id} className='d-flex'>
      <div className='d-flex bg-light-gray rounded p-2 align-self-baseline'>
        <Icon name={contact.entityType} />
      </div>
      <div className='ms-3 flex-fill'>
        <Truncate as='div' className='width-5 text-darker-gray fw-bold mt-2 mb-2'>
          {contact.name}
        </Truncate>

        {filledPhones.map((phone, index) => (
          <Fragment key={phone.number}>
            {index > 0 && <hr className='my-2 text-light-gray opacity-50' />}
            <div className='d-flex align-items-center my-1'>
              <div className='flex-fill text-dark-gray'>{phone.displayNumber}</div>

              <CallButton service={service} number={phone.callableNumber} />
            </div>
          </Fragment>
        ))}

        <Button
          variant='link'
          className='text-primary fw-bold text-nowrap ps-0 mb-2'
          onClick={onClickAddContact}
        >
          <Icon name='plus' /> Adicionar telefone
        </Button>
      </div>
    </div>
  );
}

ContactItem.propTypes = propTypes;

export default ContactItem;

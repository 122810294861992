import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import { pluralize } from '@/utils';
import LoadingButton from '@/components/LoadingButton';
import Icon from '@/components/Icon';
import Truncate from '@/components/Truncate';
import DetailsCard from '@/components/EntityModal/DetailsCard';
import Authorization from '@/components/Authorization';
import Popover from '@/components/Popover';
import ProductSelect from '@/components/Inputs/ProductSelect';

EntityProducts.defaultProps = {
  products: [],
  entity: null,
  entityType: '',
  entityTypeModal: '',
  entityEditPath: '',
  entityCustomFields: [],
  onEditEntity: () => {},
  onCreatePerson: () => {},
  dropboxEmail: null,
  showTitle: true
};

EntityProducts.propTypes = {
  loading: PropTypes.bool,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  entityTypeModal: PropTypes.string,
  entityEditPath: PropTypes.string,
  entityCustomFields: PropTypes.array,
  canEditPolicy: PropTypes.func,
  onEditEntity: PropTypes.func,
  dropboxEmail: PropTypes.string,
  showTitle: PropTypes.bool
};

function EntityProducts({
  canEditPolicy,
  updateEntity,
  entity,
  entityType
}) {
  const [showModal, setShowModal] = useState(false);

  const openEditMode = () => setShowModal(true);
  const closeEditMode = () => setShowModal(false);

  const handleSubmit = (ids) => {
    updateEntity({ products_id: ids });
    closeEditMode();
  };

  return (
    <DetailsCard title='Produtos e serviços' className='mt-3' size='lg'>
      <Popover
        rootCloseEvent='mousedown'
        placement='left-start'
        hide={!showModal}
        trigger={['clickIn']}
        flip
        showArrow
        className='max-width-6 width-6'
        content={() => (
          <PopoverContent
            products={entity.products}
            closeEditMode={closeEditMode}
            handleSubmit={handleSubmit}
            entityType={entityType}
          />
        )}
      >
        <div>
          <div className='max-height-5 overflow-y-auto'>
            <ProductList
              products={entity.products}
              policy={canEditPolicy}
              openEditMode={openEditMode}
            />
          </div>
          <div className='w-90'>
            <Authorization policy={canEditPolicy}>
              <Button
                className='d-flex align-items-center text-nowrap mx-2 text-primary w-100'
                variant='light'
                aria-label='Adicionar produtos e serviços'
                onClick={openEditMode}
              >
                <Icon name='plus' className='me-2' size='fixed' />
                <span>Adicionar produtos e serviços</span>
              </Button>
            </Authorization>
          </div>
        </div>
      </Popover>
    </DetailsCard>
  );
}

const PopoverContent = ({
  closeEditMode,
  products,
  handleSubmit,
  entityType
}) => {
  const [productsId, setProductsId] = useState(products.map(({ id }) => id));

  return (
    <>
      <h4>
        Adicionar produtos e serviços
      </h4>
      <div className='pb-2'>
        <span className='light-text'>
          Essa {
            entityType === 'person'
              ? 'pessoa'
              : 'empresa'
          } tem potencial de comprar quais produtos?
        </span>
      </div>
      <ProductSelect
        label=''
        placeholder='Buscar...'
        multiple
        isSearchable
        value={productsId}
        queryArgs={{ params: { per_page: 600 } }}
        onChange={setProductsId}
      />
      <div className='d-flex justify-content-end'>
        <Button
          variant='link'
          onClick={closeEditMode}
        >
          Cancelar
        </Button>
        <LoadingButton
          variant='primary'
          onClick={() => handleSubmit(productsId)}
          isLoading={false}
          disabled={false}
        >
          Salvar
        </LoadingButton>
      </div>
    </>
  );
};

const ProductList = ({ products, policy, openEditMode }) => {
  const productsCount = products.length;

  if (productsCount === 0) {
    return (
      <div>
        <div className='mb-2'>
          <span className='light-text'>
            Nenhum produto ou serviço cadastrado.
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className='readable-component'>
      <div>
        <span className='fw-bold text-small d-flex' style={{ lineHeight: '21px' }}>
          Total de {productsCount} {pluralize('produto', productsCount)}
          <Authorization policy={policy}>
            <div className='readable-component-button lh-0'>
              <Icon
                onClick={openEditMode}
                className='text-primary cursor-pointer'
                name='edit-field'
              />
            </div>
          </Authorization>
        </span>
      </div>
      <div onClick={(e) => (e.stopPropagation())} className='cursor-text'>
        <ul className='primary-marker'>
          {
            products.map(({ name, active }, key) => (
              <li className='pt-3 w-100' key={key.toString()}>
                <div className='d-flex justify-content-between'>
                  <Truncate
                    alt={name}
                    title={name}
                    className='user-select-all'
                  >
                    {name}
                  </Truncate>
                  {
                    !active && (
                      <span className='text-small text-medium-gray user-select-none'>
                        (inativo)
                      </span>
                    )
                  }
                </div>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
};

export default EntityProducts;

import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export async function getApiToken() {
  const { data } = await get('/api_tokens');
  return data;
}

export function useApiToken({ config = {} } = {}) {
  return useQuery({
    ...config,
    queryFn: () => getApiToken()
  });
}

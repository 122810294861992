import React, { forwardRef, useRef } from 'react';
import { useFormikContext } from 'formik';
import { Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import { validateCNPJ, validateCPF } from 'validations-br';
import PropTypes from 'prop-types';
import Form from '@/components/Form';
import Card from '@/components/Card';
import {
  CitySelect,
  MaskedInput,
  StateSelect,
  TextInput,
  WhatsAppInput
} from '@/components/Inputs';
import FormField from '@/components/FormField';
import { useAutoCompleteCep } from '@/hooks';
import DependentFormField from '@/components/DependentFormField';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  requiresWhatsapp: PropTypes.bool
};

const defaultProps = {
  requiresWhatsapp: false
};

const CPF_LENGTH = 11;
const CNPJ_LENGTH = 14;

/* eslint-disable no-magic-numbers */
const schema = Yup.object().shape({
  cpf_cnpj: Yup.string()
    .test({
      test: (value, ctx) => {
        const hasValue = Boolean(value);
        if (!hasValue) {
          return ctx.createError({ message: 'CPF/CNPJ é um campo obrigatório.' });
        }
        const validSize = [CPF_LENGTH, CNPJ_LENGTH].includes(value.length);
        if (!validSize) {
          return ctx.createError({ message: 'CPF ou CNPJ inválido.' });
        }

        if (value.length === CPF_LENGTH && !validateCPF(value)) {
          return ctx.createError({ message: 'CPF inválido.' });
        }
        if (value.length === CNPJ_LENGTH && !validateCNPJ(value)) {
          return ctx.createError({ message: 'CNPJ inválido.' });
        }
        return true;
      }
    }),
  billing_emails: Yup.string()
    .test({
      test: (value, ctx) => {
        let emails = [];
        if (value) {
          emails = value.split(',');
        }
        const allValid = emails.every(
          (email) => Yup.string()
            .email()
            .isValidSync(email)
        );
        if (!allValid) {
          return ctx.createError({ message: 'Um ou mais emails inválidos.' });
        }
        return true;
      }
    }),
  legal_name: Yup.string()
    .required('Razão social é um campo obrigatório')
    .max(255, 'A razão social da empresa deve ter no máximo 255 caracteres.'),
  contact: Yup.object().shape({
    work_phone: Yup.string()
      .required('Telefone é um campo obrigatório')
      .min(10, 'Número inválido.')
      .max(11, 'Número inválido.'),
    whatsapp: Yup.string()
      .when('$requiresWhatsapp', {
        is: true,
        then: (validationSchema) => validationSchema
          .required('WhatsApp é um campo obrigatório.')
          .min(12, 'Número inválido.')
          .max(16, 'Número inválido.'),
        otherwise: (validationSchema) => validationSchema.notRequired()
      })
  }),
  address: Yup.object().shape({
    postal_code: Yup.string()
      .required('CEP é obrigatório')
      .length(8, 'CEP deve ter 8 dígitos.'),
    country: Yup.string()
      .required('País é obrigatório')
      .max(60, 'País deve ter no máximo 60 caracteres.'),
    state: Yup.string()
      .nullable()
      .required('Estado é obrigatório'),
    district: Yup.string()
      .required('Bairro é obrigatório')
      .max(60, 'Bairro deve ter no máximo 60 caracteres.'),
    city_id: Yup.string()
      .nullable()
      .required('Cidade é obrigatória'),
    street_name: Yup.string()
      .required('Rua é obrigatório')
      .max(255, 'Rua deve ter no máximo 255 caracteres.'),
    street_number: Yup.number()
      .required('Número é obrigatório')
      .integer(),
    additional_info: Yup.string()
      .nullable()
      .max(255, 'Complemento deve ter no máximo 255 caracteres.')
  })
});
/* eslint-enable no-magic-numbers */

const defaultValues = {
  cpf_cnpj: '',
  work_phone: '',
  whatsapp: '',
  legal_name: '',
  billing_emails: '',
  address: {
    postal_code: '',
    country: '',
    district: '',
    street_name: '',
    street_number: '',
    additional_info: '',
    state: '',
    city_id: ''
  }
};

function AddressFields() {
  const numberRef = useRef(null);

  const { setFieldValue, handleBlur } = useFormikContext();

  const { isFetching, onPostalCodeBlur } = useAutoCompleteCep({
    postalCodeFieldName: 'address.postal_code',
    onSuccess: (data) => {
      ['district', 'state', 'country'].forEach((key) => {
        setFieldValue(`address.${key}`, data[key]);
      });

      setFieldValue('address.street_name', data.streetName);
      setFieldValue('address.city_id', data.city?.id);

      if (numberRef.current) {
        numberRef.current.focus();
      }
    }
  });

  return (
    <>
      <Row className='gx-3'>
        <Col sm={4}>
          <FormField
            as={MaskedInput}
            name='address.postal_code'
            label='CEP'
            onBlur={onPostalCodeBlur}
            maskType='postalCode'
            placeholder='00000-000'
            readOnly={isFetching}
            autoComplete='off'
            loading={isFetching}
            loadingMessage='Buscando...'
          />
        </Col>

        <Col sm={5}>
          <FormField
            as={TextInput}
            name='address.country'
            label='País'
            placeholder='Brasil'
            maxLength={60}
            onBlur={handleBlur}
            autoComplete='off'
          />
        </Col>

        <Col sm={3}>
          <FormField
            as={StateSelect}
            name='address.state'
            placeholder='Selecione'
          />
        </Col>
      </Row>

      <Row className='gx-3'>
        <Col sm={6}>
          <DependentFormField
            as={CitySelect}
            name='address.city_id'
            placeholder='Selecione'
            source='address.state'
            target='state'
            isSearchable
          />
        </Col>

        <Col sm={6}>
          <FormField
            as={TextInput}
            name='address.district'
            label='Bairro'
            placeholder='Bairro X'
            maxLength={60}
            onBlur={handleBlur}
            autoComplete='off'
          />
        </Col>
      </Row>

      <Row className='gx-3'>
        <Col sm={9}>
          <FormField
            as={TextInput}
            name='address.street_name'
            label='Rua'
            placeholder='Rua Y'
            maxLength={255}
            onBlur={handleBlur}
            autoComplete='off'
          />
        </Col>

        <Col sm={3}>
          <FormField
            as={MaskedInput}
            name='address.street_number'
            label='Número'
            placeholder='77'
            maskType='natural'
            inputRef={numberRef}
            onBlur={handleBlur}
            autoComplete='off'
          />
        </Col>

        <Col sm={12}>
          <FormField
            as={TextInput}
            name='address.additional_info'
            label='Complemento'
            placeholder='Sala 153, Bloco B'
            maxLength={255}
            onBlur={handleBlur}
            autoComplete='off'
          />
        </Col>
      </Row>
    </>
  );
}

const AccountDataForm = forwardRef(({ onSubmit, initialValues, requiresWhatsapp }, ref) => {
  const minSize = 6;
  const maxSize = 12;
  const cpfCnpjInputSize = requiresWhatsapp ? maxSize : minSize;

  return (
    <Form
      ref={ref}
      defaultValues={defaultValues}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      validationContext={{ requiresWhatsapp }}
    >
      {() => (
        <>
          <Card body className='p-3 mb-3 border-light'>
            <h2 className='mb-2 text-dark-gray'>Dados para o faturamento</h2>
            <Row className='gx-3'>
              <Col sm={cpfCnpjInputSize}>
                <FormField
                  as={MaskedInput}
                  name='cpf_cnpj'
                  label='CPF/CNPJ'
                  maskType='cpf_cnpj'
                  placeholder='Digite o CPF ou CNPJ'
                />
              </Col>

              <Col sm={6}>
                <FormField
                  as={MaskedInput}
                  name='contact.work_phone'
                  label='Telefone'
                  maskType='phone'
                  placeholder='(00) 0000-0000'
                />
              </Col>
              {requiresWhatsapp && (
                <Col sm={6}>
                  <FormField
                    as={WhatsAppInput}
                    name='contact.whatsapp'
                    label='Whatsapp'
                    autoComplete='off'
                    placeholder='+00 00 00000-0000'
                  />
                </Col>
              )}

              <Col sm={12}>
                <FormField
                  as={TextInput}
                  name='legal_name'
                  label='Nome/Razão Social'
                  placeholder='Digite o nome da conta'
                />
              </Col>

              <Col sm={12}>
                <FormField
                  as={TextInput}
                  name='billing_emails'
                  label='Emails do financeiro'
                  placeholder='Ex.: financeiro@agendor.com.br, jorge@agendor.com.br'
                />
              </Col>
            </Row>
          </Card>

          <Card body className='p-3 border-light'>
            <h2 className='mb-2 text-dark-gray'>Endereço de faturamento</h2>
            <AddressFields />
          </Card>
        </>
      )}
    </Form>
  );
});

AccountDataForm.displayName = 'AccountDataForm';
AccountDataForm.propTypes = propTypes;
AccountDataForm.defaultProps = defaultProps;

export default AccountDataForm;

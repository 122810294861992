import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useProposalModels } from '@/api';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { useStoredState, useDebounce } from '@/hooks';
import Button from '@/components/Button';
import { TextInput } from '@/components/Inputs';
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import Form from '@/components/Form';
import LoadingPage from '@/components/LoadingPage';
import ProposalModelCard from '@/components/Proposal/ProposalModelCard';
import ProposalModelSortInput from '@/components/Proposal/ProposalModelSortInput';
import FormField from '@/components/FormField';
import InputAdornment from '@/components/InputAdornment';
import LoadingWrapper from '@/components/LoadingWrapper';
import FeatureControlPopover from '@/components/FeatureControlPopover';
import CreatedProposalList from '@/components/Proposal/CreatedProposalList';
import Skeleton from '@/components/Skeleton';
import IconLabel from '@/components/IconLabel';
import emptyProposalModel from 'images/empty-proposal-model.png';

function ProposalNavItem({ label, iconName, active, loading, onClick }) {
  if (loading) {
    return <Skeleton width={200} height={30} />;
  }
  return (
    <div className='ms-4'>
      <Nav.Item>
        <Nav.Link
          onClick={onClick}
          className={classnames(
            'px-6',
            { 'border-bottom border-primary': active }
          )}
        >
          <IconLabel
            icon={{ name: iconName }}
            text={label}
            textSize='h5'
            className={active ? 'text-primary' : ''}
          />
        </Nav.Link>
      </Nav.Item>
    </div>
  );
}

ProposalModelsModal.propTypes = {
  show: PropTypes.bool,
  deal: PropTypes.object,
  relatedEntity: PropTypes.object,
  modalSize: PropTypes.string,
  onHide: PropTypes.func,
  onOpenProposal: PropTypes.func,
  onOpenProposalModel: PropTypes.func,
  onRenameProposalModel: PropTypes.func,
  onSaveNewProposalModel: PropTypes.func,
  onNewProposalModel: PropTypes.func,
  onDisabledCreateChange: PropTypes.func
};

ProposalModelsModal.defaultProps = {
  show: false,
  deal: {},
  relatedEntity: {},
  modalSize: 'xxl',
  onHide: () => {},
  onOpenProposal: () => {},
  onOpenProposalModel: () => {},
  onRenameProposalModel: () => {},
  onSaveNewProposalModel: () => {},
  onNewProposalModel: () => {},
  onDisabledCreateChange: () => {}
};

const DEBOUNCE_DELAY = 250;
const DEFAULT_SEARCH = { q: '', sorts: 'updated_at desc' };
const PROPOSAL_LIST = 'proposals';
const PROPOSAL_MODEL_LIST = 'proposalModels';
const PROPOSAL_MODEL_SEARCH_FORM = 'proposal-models-search';

function ProposalModelsModal(props) {
  const {
    children,
    modalSize,
    onHide,
    onNewProposalModel,
    onOpenProposalModel,
    onOpenProposal,
    onRenameProposalModel,
    onSaveNewProposalModel,
    show,
    deal,
    relatedEntity,
    onDisabledCreateChange
  } = props;

  const { user } = useAuth();
  const tracker = useTracking();
  const debounceSearch = useDebounce((fn) => fn(), DEBOUNCE_DELAY);
  const {
    store: storeSearchParams, value: searchParams
  } = useStoredState(PROPOSAL_MODEL_SEARCH_FORM, DEFAULT_SEARCH);

  const [clearSearch, setClearSearch] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [isPreparingProposal, setIsPreparingProposal] = useState(false);
  const [currentTab, setCurrentTab] = useState(PROPOSAL_LIST);

  const hasProposalAccess = user.account.plan.name === 'Pro' ||
    user.account.legacyPro || user.account.performanceOrHigher;
  const isProposalList = currentTab === PROPOSAL_LIST;
  const isProposalModelList = currentTab === PROPOSAL_MODEL_LIST;

  const {
    data: proposalModelsData,
    isFetching: isLoadingProposalModels
  } = useProposalModels({
    params: searchParams,
    config: {
      enabled: show && isProposalModelList,
      onSuccess: () => setClearSearch(false)
    }
  });

  const proposalModels = proposalModelsData?.data || [];
  const disabledCreate = proposalModels.length >= user.maxProposalModels;

  useEffect(() => {
    onDisabledCreateChange(disabledCreate);
  }, [proposalModels, disabledCreate, onDisabledCreateChange]);

  const resetSearchAndClose = () => {
    tracker.trackProposalNotCreated({ user });
    setCurrentTab(PROPOSAL_LIST);
    storeSearchParams(DEFAULT_SEARCH);
    onHide();
  };

  const verifyPlan = () => {
    if (hasProposalAccess) {
      setShowPopover(false);
      onNewProposalModel();
    } else {
      setShowPopover(true);
    }
  };

  const handleUpdateSearch = (params) => {
    debounceSearch(() => storeSearchParams(params));
  };

  const handleEditProposal = (proposal) => {
    setIsPreparingProposal(true);
    onOpenProposal(proposal, () => {
      setIsPreparingProposal(false);
    });
  };

  const handlePreparingProposal = (done) => {
    setIsPreparingProposal(true);
    done?.();
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <Modal
        show={show}
        onHide={resetSearchAndClose}
        size={modalSize}
        bodyClassName={classnames(
          'modal-height-xxl p-0',
          { 'py-1 px-0 h-75 mh-75 vh-75 min-vh-75': isPreparingProposal }
        )}
      >
        {
          isPreparingProposal
            ? (
              <LoadingPage
                title='Preparando a proposta'
                subtitle='Aguarde só um pouco, estamos carregando os dados.'
              />
            )
            : (
              <div>
                <div className='px-8 py-4'>
                  <h1 className='text-darker-gray'>Gerador de proposta</h1>
                </div>

                <div className='pt-2'>
                  <Tab.Container
                    activeKey={currentTab}
                    defaultActiveKey={PROPOSAL_LIST}
                  >
                    <Nav className='border-bottom border-1 border-flat-gray'>
                      <ProposalNavItem
                        label='Propostas criadas'
                        iconName='send'
                        active={isProposalList}
                        onClick={() => setCurrentTab(PROPOSAL_LIST)}
                      >
                      </ProposalNavItem>

                      <ProposalNavItem
                        label='Nova proposta'
                        iconName='note'
                        active={isProposalModelList}
                        onClick={() => setCurrentTab(PROPOSAL_MODEL_LIST)}
                      >
                      </ProposalNavItem>
                    </Nav>
                  </Tab.Container>
                </div>
                <div className='px-8 pb-8 pt-8'>
                  {
                    isProposalList
                      ? (
                        <CreatedProposalList
                          deal={deal}
                          relatedEntity={relatedEntity}
                          onLoadingMetadata={handlePreparingProposal}
                          onClickEditProposal={handleEditProposal}
                          onClickNewProposal={() => setCurrentTab(PROPOSAL_MODEL_LIST)}
                          onSaveNewProposalModel={onSaveNewProposalModel}
                          isDisabledCreate={disabledCreate}
                        />
                      )
                      : null
                  }
                  {
                    isProposalModelList
                      ? (
                        <>
                          <div>
                            <div className='d-flex justify-content-between align-items-center pb-6'>
                              <FeatureControlPopover
                                user={user}
                                showPopover={showPopover}
                                placement='bottom'
                              >
                                <Button
                                  variant='primary'
                                  onClick={verifyPlan}
                                >
                                  <Icon name='plus' className='me-1' />
                                  Iniciar proposta em branco
                                </Button>
                              </FeatureControlPopover>

                              <div className='d-flex align-items-center'>
                                <Form
                                  name={PROPOSAL_MODEL_SEARCH_FORM}
                                  className='d-flex'
                                  defaultValues={searchParams}
                                  initialValues={searchParams}
                                  onSubmit={handleUpdateSearch}
                                >
                                  {({ submitForm }) => (
                                    <>
                                      <FormField
                                        as={TextInput}
                                        name='q'
                                        isClearable
                                        onChange={submitForm}
                                        onClear={() => setClearSearch(true)}
                                        placeholder='Buscar por nome do modelo'
                                        autoComplete='off'
                                        className='search-proposal-models-input'
                                        rightAdornment={({ focus }) => (
                                          <InputAdornment alignment='right' onClick={focus}>
                                            <Icon className='text-primary' name='search' />
                                          </InputAdornment>
                                        )}
                                      />
                                      <FormField
                                        as={ProposalModelSortInput}
                                        name='sorts'
                                        value={searchParams.sorts}
                                        onChange={submitForm}
                                        innerClassName='mx-3'
                                      />
                                    </>
                                  )}
                                </Form>
                              </div>
                            </div>
                            <div className='d-flex mb-5'>
                              <h3>Comece a partir de um modelo</h3>
                            </div>
                            <div
                              className='overflow-hidden overflow-y-auto proposal-model-list-height'
                            >
                              <LoadingWrapper isLoading={isLoadingProposalModels}>
                                {
                                  proposalModels.length > 0
                                    ? (
                                      <Row className='g-4'>
                                        {proposalModels.map((proposalModel) => (
                                          <Col sm={3} key={proposalModel.id}>
                                            <ProposalModelCard
                                              onRename={onRenameProposalModel}
                                              onOpenProposal={onOpenProposalModel}
                                              proposalModel={proposalModel}
                                              disabledCreate={disabledCreate}
                                            />
                                          </Col>
                                        ))}
                                      </Row>
                                    )
                                    : (
                                      <div
                                        className={classnames(
                                          'd-flex',
                                          'flex-column',
                                          'align-items-center',
                                          'justify-content-center'
                                        )}
                                      >
                                        {
                                          clearSearch || searchParams.q
                                            ? (
                                              <div className='text-center text-dark-gray'>
                                                Nenhum resultado encontrado.
                                              </div>
                                            )
                                            : (
                                              <>
                                                <img
                                                  width={200}
                                                  src={emptyProposalModel}
                                                  alt='Nenhum modelo salvo'
                                                  className='mt-6'
                                                />
                                                <h4 className='my-4'>Nenhum modelo criado</h4>
                                                <span className='text-dark-gray'>
                                                  Configure um novo modelo para vê-lo aqui
                                                </span>
                                              </>
                                            )}
                                      </div>
                                    )
                                }
                              </LoadingWrapper>
                            </div>
                          </div>
                        </>
                      )
                      : null
                  }
                </div>
              </div>
            )
        }
      </Modal>
      {children}
    </>
  );
}
export default ProposalModelsModal;

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { ENTITIES_TRACKER_SCREEN, prepareTrackerIntent } from '@/utils';
import Skeleton from '@/components/Skeleton';
import DealActivitiesNavs from '@/components/EntityModal/Deal/Activities/DealActivitiesNavs';
import Form from '@/components/EntityModal/Activities/Form';
import { useUpdateHistoricSuggestion } from '@/api';
import { formatDate } from '@/date';

DealActivities.defaultProps = {
  deal: {},
  activities: [],
  dealCustomFields: [],
  historicSuggestions: [],
  entity: {},
  entityType: '',
  entityCustomFields: [],
  loading: false,
  isFetchingActivities: false,
  isFetchingNextPageActivities: false,
  fetchNextPageActivities: () => {},
  hasNextPageActivities: false,
  onCreateActivity: () => {},
  onUpdateActivity: () => {},
  onDeleteActivity: () => {},
  onUpdateDeal: () => {},
  onEditEntity: () => {}
};

DealActivities.propTypes = {
  deal: PropTypes.object,
  activities: PropTypes.array,
  dealCustomFields: PropTypes.array,
  historicSuggestions: PropTypes.array,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  entityCustomFields: PropTypes.array,
  loading: PropTypes.bool,
  isFetchingActivities: PropTypes.bool,
  isFetchingNextPageActivities: PropTypes.bool,
  fetchNextPageActivities: PropTypes.func,
  hasNextPageActivities: PropTypes.bool,
  onCreateActivity: PropTypes.func,
  onUpdateActivity: PropTypes.func,
  onDeleteActivity: PropTypes.func,
  onUpdateDeal: PropTypes.func,
  onEditEntity: PropTypes.func
};

function DealActivities({
  deal, activities, historicSuggestions, isFetchingActivities,
  onCreateActivity, onUpdateActivity, onDeleteActivity,
  isFetchingNextPageActivities, fetchNextPageActivities, hasNextPageActivities,
  dealCustomFields, entity, entityType, entityCustomFields, loading, onUpdateDeal, onEditEntity
}) {
  const textAreaRef = useRef(null);
  const { user } = useAuth();
  const tracker = useTracking();
  const [suggestedActivity, setSuggestedActivity] = useState(null);
  const [selectedIntent, setSelectedIntent] = useState(null);
  const updateSuggestionsMutation = useUpdateHistoricSuggestion({
    params: { entity: 'deal', entity_id: deal.id }
  });

  const onSelectIntent = (currentIntent) => {
    const textAreaEl = textAreaRef?.current;
    if (textAreaEl) {
      textAreaEl.focus();
      textAreaEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    const suggestionData = currentIntent?.suggestionData;

    setSelectedIntent(currentIntent);
    setSuggestedActivity({
      type: currentIntent?.type?.replace('activity.', ''),
      text: suggestionData?.text,
      date: formatDate(suggestionData?.date),
      time: suggestionData?.time
    });
  };

  const handleOnClose = (saved) => {
    if (selectedIntent) {
      if (saved) {
        tracker.trackSmartSuggestionConfirmed({
          user,
          activity: selectedIntent.activity,
          screen: ENTITIES_TRACKER_SCREEN.deal,
          intents: [],
          currentIntent: prepareTrackerIntent(selectedIntent)
        });

        const params = { processing: false, confirmed: true, cancelled: false, open: false };
        updateSuggestionsMutation.mutate({ id: selectedIntent.id, params });
      } else {
        tracker.trackSmartSuggestionCancelled({
          user,
          activity: selectedIntent?.activity,
          screen: ENTITIES_TRACKER_SCREEN.deal,
          intents: [],
          currentIntent: prepareTrackerIntent(selectedIntent)
        });

        const params = { processing: false, confirmed: false, cancelled: true, open: false };
        updateSuggestionsMutation.mutate({ id: selectedIntent.id, params });
      }
    }
    setSelectedIntent(null);
    setSuggestedActivity(null);
  };

  if (loading) {
    return (
      <>
        <Skeleton width='100%' height={108} className='mb-6' />
        <Skeleton width={210} height={20} className='mb-3' />
        <Skeleton width='100%' height={275} />
      </>
    );
  }
  return (
    <div>
      <Form
        entityName='deal'
        entityId={deal.id}
        onCreate={onCreateActivity}
        onClose={handleOnClose}
        textAreaRef={textAreaRef}
        suggestedValues={suggestedActivity}
      />
      <DealActivitiesNavs
        deal={deal}
        activities={activities}
        historicSuggestions={historicSuggestions}
        dealCustomFields={dealCustomFields}
        entity={entity}
        entityType={entityType}
        entityCustomFields={entityCustomFields}
        loading={loading}
        isFetchingActivities={isFetchingActivities}
        isFetchingNextPageActivities={isFetchingNextPageActivities}
        fetchNextPageActivities={fetchNextPageActivities}
        hasNextPageActivities={hasNextPageActivities}
        onDeleteActivity={onDeleteActivity}
        onUpdateActivity={onUpdateActivity}
        onUpdateDeal={onUpdateDeal}
        onEditEntity={onEditEntity}
        onSelectIntent={onSelectIntent}
      />
    </div>
  );
}

export default DealActivities;

import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@/components/Popover';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { plansPath, settingsManageUpdatePlanPath } from '@/routes';

const propTypes = {
  user: PropTypes.object,
  showPopover: PropTypes.bool,
  children: PropTypes.node,
  placement: PropTypes.string,
  flip: PropTypes.bool
};

const defaultProps = {
  user: {},
  showPopover: true,
  children: null,
  placement: 'right',
  type: 'plan',
  flip: false
};

const typeTexts = {
  plan: {
    name: 'Plano Performance',
    features: [
      'Automações',
      'Qualificação obrigatória',
      'Telefone virtual inteligente',
      'Modelos de proposta comerciais'
    ],
    button: 'Contratar agora'
  },
  agendorChat: {
    name: 'Agendor Chat',
    features: [
      'Automações no CRM com mensagens',
      'Vários atendentes em um único número',
      'Distribuição automática de conversas entre os atendentes',
      'Relatórios de atendimentos por atendente'
    ],
    button: 'Conhecer Agendor Chat'
  }
};

const FeatureControlPopover = ({ user, showPopover, children, placement, type, flip }) => {
  const hasAccess = user.account.legacyPro || user.account.performanceOrHigher;
  const newBillingPro = user.account.newBilling && user.account.paid && !hasAccess;
  const notExpiredNewPro = newBillingPro && !user.account.expired;

  const noAccessUrl = notExpiredNewPro
    ? settingsManageUpdatePlanPath({ new_plan_code: 'performance_plan' })
    : plansPath();
  const noAccessTextButton = notExpiredNewPro ? 'Contratar agora' : 'Ver planos e preços';

  return (
    <Popover
      hide={!showPopover}
      placement={placement}
      flip={flip}
      showArrow
      content={() => (
        <div className='d-flex flex-column justify-content-center max-width-5'>
          <h4 className='text-darker-gray mb-3'>
            Disponível no {typeTexts[type].name}
          </h4>
          <hr className='mb-4 text-darker-gray' />
          <div className='text-dark-gray mb-2'>
            Assine o {typeTexts[type].name} e tenha<br /> acesso às funcionalidades:
            {typeTexts[type].features.map((feature) => (
              <div className='d-flex mt-2 gap-2' key={feature}>
                <Icon name='check' size='sm' className='flex-shrink-0' />
                {feature}
              </div>))
            }
          </div>
          <Button
            href={noAccessUrl}
            variant='primary'
            className='mt-4'
          >
            <Icon name='rocket' className='me-2 text-white' />
            {defaultProps.type ? noAccessTextButton : typeTexts[type].button}
          </Button>
        </div>
      )}
    >
      <span>
        {children}
      </span>
    </Popover>
  );
};

FeatureControlPopover.propTypes = propTypes;
FeatureControlPopover.defaultProps = defaultProps;

export default FeatureControlPopover;

export function canAccessLatestAutomationFeatures({ user, _data }) {
  let effect;

  if (user.features?.automationEnabled) {
    effect = 'allow';
  }

  return { effect };
}

export function canAccessAgendorChatMessagesAutomation({ user }) {
  let effect;

  if (user.features?.agendorChatMessagesAutomationEnabled) {
    effect = 'allow';
  }

  return { effect };
}

export function canCreateAutomation({ user }) {
  let effect, reason;
  const planName = 'Performance';

  if (!user.account.legacyPro && !user.account.performanceOrHigher) {
    effect = 'paywall';
    reason = 'Para editar automações, é necessário fazer upgrade para o Plano Performance.';
  } else if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem criar automações.';
  } else {
    effect = 'allow';
  }

  return { effect, reason, planName };
}

export function canUpdateAutomation({ user }) {
  let effect, reason;
  const planName = 'Performance';

  if (!user.account.legacyPro && !user.account.performanceOrHigher) {
    effect = 'paywall';
    reason = 'Para editar automações, é necessário fazer upgrade para o Plano Performance.';
  } else if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem editar automações.';
  } else {
    effect = 'allow';
  }

  return { effect, reason, planName };
}

export function canDeleteAutomation({ user }) {
  let effect, reason;
  const planName = 'Performance';

  if (!user.account.legacyPro && !user.account.performanceOrHigher) {
    effect = 'paywall';
    reason = 'Para excluir automações, é necessário fazer upgrade para o Plano Performance.';
  } else if (!user.admin) {
    effect = 'disable';
    reason = 'Apenas administradores podem excluir automações.';
  } else {
    effect = 'allow';
  }

  return { effect, reason, planName };
}

import React from 'react';
import { Routes as Base, Route, Navigate, useNavigate } from 'react-router-dom';
import { useAuth, Login, BlockedAccess } from '@/lib/auth';
import Page from '@/components/Page';
import OffcanvasPage from '@/components/OffcanvasPage';
import FunnelsIndex from '@/pages/funnels';
import FunnelsBase from '@/pages/funnels/base';
import LastViewedFunnel from '@/pages/funnels/last-viewed-funnel';
import FunnelsShow from '@/pages/funnels/show';
import FunnelStagesIndex from '@/pages/funnels/stages/index';
import FunnelsDealsRelocationsIndex from '@/pages/funnels/deals_relocations';
import StagesDealsRelocationsIndex from '@/pages/funnels/stages/deals_relocations';
import OrganizationsIndex from '@/pages/organizations';
import OrganizationsNew from '@/pages/organizations/new';
import OrganizationsEdit from '@/pages/organizations/edit';
import PeopleIndex from '@/pages/people';
import PeopleNew from '@/pages/people/new';
import PeopleEdit from '@/pages/people/edit';
import DealsIndex from '@/pages/deals';
import DealsNew from '@/pages/deals/new';
import DealsEdit from '@/pages/deals/edit';
import NotFound from '@/pages/not-found';
import PaymentReturn from '@/pages/payment_return';
import CustomFieldsBase from '@/pages/settings/custom_fields/base';
import OrganizationFormPreview from '@/pages/custom_fields/organizations';
import PeopleFormPreview from '@/pages/custom_fields/people';
import DealsFormPreview from '@/pages/custom_fields/deals';
import SettingsBase from '@/pages/settings/base';
import SettingsProducts from '@/pages/settings/products';
import SettingsProductCategories from '@/pages/settings/product_categories';
import AutomationsBase from '@/pages/settings/automations/base';
import AutomationsTemplates from '@/pages/settings/automations/templates';
import AutomationsIndex from '@/pages/settings/automations';
import AutomationsNew from '@/pages/settings/automations/new';
import AutomationsEdit from '@/pages/settings/automations/edit';
import AutomationsEmail from '@/pages/settings/automations/email';
import AutomationsMessage from '@/pages/settings/automations/message';
import IntegrationsBase from '@/pages/settings/integrations/base';
import IntegrationsIndex from '@/pages/settings/integrations';
import IntegrationsDeveloperArea from '@/pages/settings/integrations/developer_area';
import IntegrationsApiToken from '@/pages/settings/integrations/api_token';
import SettingEmailTemplates from '@/pages/settings/email_templates';
import SettingsCurrentPlan from '@/pages/settings/current_plan';
import Checkout from '@/pages/checkout';
import BankSlip from '@/pages/bank_slip';
import Pix from '@/pages/pix';
import SettingsModulesVoip from '@/pages/settings/modules/voip';
import ImportsIndex from '@/pages/imports/index';
import ImportsShow from '@/pages/imports/show';
import DealModal from '@/components/EntityModal/Deal/DealModal';
import OrganizationModal from '@/components/EntityModal/Organization/OrganizationModal';
import IntegrationModal from '@/components/IntegrationModal';
import PersonModal from '@/components/EntityModal/Person/PersonModal';
import { useQueryParams } from '@/hooks';
import FunnelStagesRequiredFieldsIndex from '@/pages/funnels/stages/required_fields/index';
import FunnelStagesRequiredFieldsNew from '@/pages/funnels/stages/required_fields/new';
import FunnelStagesRequiredFieldsEdit from '@/pages/funnels/stages/required_fields/edit';
import SettingsVoipPanel from '@/pages/settings/voip/panel';
import SettingsCallsHistory from '@/pages/settings/voip/calls';
import VoipCheckout from '@/pages/voip_checkout';
import ChatCheckout from '@/pages/chat_checkout';
import AutomationsModal from '@/pages/settings/automations/modal';
import ProfileForm from '@/pages/profile_form';
import SettingsPlans from '@/pages/settings/plans';
import ManageCurrentPlan from '@/pages/settings/account/manage';
import UpdatePlan from '@/pages/settings/account/update';
import PaymentMethod from '@/pages/settings/account/payment_method';
import News from '@/pages/news/index';
import ChargeCompletion from '@/pages/settings/account/charge_completion';

const BLOCKED_PATHS = [
  '/deals',
  '/organizations',
  '/people',
  '/tasks',
  '/funnels'
];

function Routes() {
  const { user } = useAuth();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const { pathname } = window.location;

  if (!user) {
    return <Login />;
  }

  const isAdmin = user?.admin;
  const isBlockedPath = BLOCKED_PATHS.find((path) => pathname.startsWith(path));
  if (user?.account?.blockedAccess) {
    if (!isAdmin || isBlockedPath) {
      return <BlockedAccess />;
    }
  }

  const showProfileForm = user.features?.newProfileFormEnabled
    ? !user.hasCompleteProfile
    : !user.hasLegacyCompleteProfile;

  return (
    <>
      <Base>
        <Route path='checkout' element={<Checkout />} />
        <Route path='payment_return' element={<PaymentReturn />} />
        <Route path='voip_checkout' element={<VoipCheckout />} />
        <Route path='chat_checkout' element={<ChatCheckout />} />
        <Route path='bank_slip' element={<BankSlip />} />
        <Route path='pix' element={<Pix />} />
        <Route path='news' element={<News />} />
        <Route path='profile' element={<ProfileForm />} />
        <Route path='/' element={showProfileForm ? <Navigate to='profile' /> : <Page />}>
          <Route index element={<LastViewedFunnel />} />
          <Route path='funnels'>
            <Route index element={<LastViewedFunnel />} />
            <Route path=':funnelId' element={<FunnelsBase />} >
              <Route index element={<FunnelsShow />} />
            </Route>
          </Route>

          <Route path='organizations'>
            <Route index element={<OrganizationsIndex />} />
            <Route path='new' element={<OrganizationsNew />} />
            <Route path=':organizationId/edit' element={<OrganizationsEdit />} />
          </Route>

          <Route path='people'>
            <Route index element={<PeopleIndex />} />
            <Route path='new' element={<PeopleNew />} />
            <Route path=':personId/edit' element={<PeopleEdit />} />
          </Route>

          <Route path='deals'>
            <Route index element={<DealsIndex />} />
            <Route path='new' element={<DealsNew />} />
            <Route path=':dealId/edit' element={<DealsEdit />} />
          </Route>

          <Route path='plans' element={<SettingsPlans />} />

          <Route path='settings' element={<SettingsBase />}>
            <Route index element={<Navigate to='funnels' />} />

            <Route path='account'>
              <Route path='current_plan'>
                <Route index element={<SettingsCurrentPlan />} />
                <Route path='manage'>
                  <Route index element={<SettingsCurrentPlan />} />
                  <Route path='update/chat' element={<SettingsCurrentPlan />} />
                  <Route path='update/crm' element={<SettingsCurrentPlan />} />
                  <Route path='payment_method/chat' element={<SettingsCurrentPlan />} />
                  <Route path='payment_method/crm' element={<SettingsCurrentPlan />} />
                  <Route path='charge_completion' element={<SettingsCurrentPlan />} />
                </Route>
              </Route>

              <Route path='*' element={<NotFound />} />
            </Route>

            <Route path='modules'>
              {isAdmin ? <Route path='voip' element={<SettingsModulesVoip />} /> : null}
              <Route index element={<NotFound />} />
            </Route>

            <Route path='voip'>
              {
                isAdmin
                  ? (
                    <>
                      <Route path='panel' element={<SettingsVoipPanel />} />
                      <Route path='calls' element={<SettingsCallsHistory />} />
                    </>
                  )
                  : null
              }
              <Route index element={<NotFound />} />
            </Route>

            <Route path='custom_fields' element={<CustomFieldsBase />}>
              <Route index element={<Navigate to='organizations' />} />

              <Route path='organizations' element={<OrganizationFormPreview />} />
              <Route path='people' element={<PeopleFormPreview />} />
              <Route path='deals' element={<DealsFormPreview />} />
            </Route>

            <Route path='funnels'>
              <Route index element={<FunnelsIndex />} />
              <Route path=':funnelId'>
                <Route path='deals_relocation' element={<FunnelsDealsRelocationsIndex />} />
                <Route path='stages'>
                  <Route index element={<FunnelStagesIndex />} />
                  <Route
                    path=':stageId/deals_relocation'
                    element={<StagesDealsRelocationsIndex />}
                  />
                  <Route path='required_fields' element={<FunnelStagesIndex />} />
                  <Route path='required_fields/new' element={<FunnelStagesIndex />} />
                  <Route path='required_fields/edit' element={<FunnelStagesIndex />} />
                </Route>
              </Route>
            </Route>

            <Route path='products'>
              <Route index element={<SettingsProducts />} />
              <Route path='categories' element={<SettingsProductCategories />} />
            </Route>

            <Route path='automations' element={<AutomationsBase />}>
              <Route path='templates'>
                <Route index element={<AutomationsTemplates />} />
                <Route path='new' element={<AutomationsTemplates />} />
                <Route path='new/email' element={<AutomationsTemplates />} />
                <Route path='new/message' element={<AutomationsTemplates />} />
              </Route>

              <Route index element={<AutomationsIndex />} />
              <Route path='edit' element={<AutomationsIndex />} />
              <Route path='edit/email' element={<AutomationsIndex />} />
              <Route path='edit/message' element={<AutomationsIndex />} />
            </Route>

            <Route path='integrations' element={<IntegrationsBase />}>
              <Route index element={<IntegrationsIndex />} />
              <Route path='api_token' element={<IntegrationsApiToken />} />
              <Route path='dashboard' element={<IntegrationsDeveloperArea />} />

              <Route path=':slug' element={<IntegrationsIndex />} />
            </Route>

            <Route path='email_template'>
              <Route index element={<SettingEmailTemplates />} />
            </Route>
          </Route>

          <Route path='imports'>
            <Route path='organizations'>
              <Route index element={<ImportsIndex entity='organizations' />} />
              <Route path=':importId' element={<ImportsShow entity='organizations' />} />
            </Route>

            <Route path='people'>
              <Route index element={<ImportsIndex entity='people' />} />
              <Route path=':importId' element={<ImportsShow entity='people' />} />
            </Route>

            <Route path='products'>
              <Route index element={<ImportsIndex entity='products' />} />
              <Route path=':importId' element={<ImportsShow entity='products' />} />
            </Route>

            <Route path='activities'>
              <Route index element={<ImportsIndex entity='activities' />} />
              <Route path=':importId' element={<ImportsShow entity='activities' />} />
            </Route>

            <Route path='deals'>
              <Route index element={<ImportsIndex entity='deals' />} />
              <Route path=':importId' element={<ImportsShow entity='deals' />} />
            </Route>
            <Route index element={<NotFound />} />
          </Route>

          <Route path='*' element={<NotFound />} />
        </Route>
      </Base>

      <Base>
        <Route
          path='/settings/funnels/:funnelId/stages/required_fields'
          element={<OffcanvasPage />}
        >
          <Route index element={<FunnelStagesRequiredFieldsIndex />} />
          <Route path='new' element={<FunnelStagesRequiredFieldsNew />} />
          <Route path='edit' element={<FunnelStagesRequiredFieldsEdit />} />
        </Route>

        <Route
          path='/settings/account/current_plan/manage'
          element={<OffcanvasPage />}
        >
          <Route index element={<ManageCurrentPlan />} />
          <Route path='update/chat' element={<UpdatePlan />} />
          <Route path='update/crm' element={<UpdatePlan crmSubscription/>} />
          <Route path='payment_method/chat' element={<PaymentMethod crmSubscription={false} />} />
          <Route path='payment_method/crm' element={<PaymentMethod crmSubscription />} />
          <Route path='charge_completion' element={<ChargeCompletion />} />
        </Route>

        <Route path='*' element={null} />
      </Base>

      {
        Boolean(queryParams.dealId) && (
          <Base>
            <Route
              path='*'
              element={
                <DealModal
                  show={Boolean(queryParams.dealId)}
                  id={queryParams.dealId}
                />
              }
            />
          </Base>
        )
      }

      {
        Boolean(queryParams.organizationId) && (
          <Base>
            <Route
              path='*'
              element={
                <OrganizationModal
                  id={queryParams.organizationId}
                />
              }
            />
          </Base>
        )
      }

      {
        Boolean(queryParams.personId) && (
          <Base>
            <Route
              path='*'
              element={
                <PersonModal
                  id={queryParams.personId}
                />
              }
            />
          </Base>
        )
      }

      {
        Boolean(queryParams.integration) && (
          <Base>
            <Route
              path='*'
              element={
                <IntegrationModal
                  show
                  slug={queryParams.integration}
                  onHide={() => navigate(-1)}
                />
              }
            />
          </Base>
        )
      }

      <Base>
        <Route path='/settings/automations/templates/new' element={<AutomationsModal />}>
          <Route index element={<AutomationsNew />} />
          <Route path='email' element={<AutomationsEmail />} />
          <Route path='message' element={<AutomationsMessage />} />
        </Route>

        <Route path='/settings/automations/edit' element={<AutomationsModal />}>
          <Route index element={<AutomationsEdit />} />
          <Route path='email' element={<AutomationsEmail />} />
          <Route path='message' element={<AutomationsMessage />} />
        </Route>

        <Route path='*' element={null} />
      </Base>
    </>
  );
}

export default Routes;

import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useCheckoutContext } from '@/contexts';
import BillingFooter from '@/components/Billing/BillingFooter';
import AccountDataForm from '@/components/Billing/AccountDataForm';
import { useUpdateCurrentAccount } from '@/api';

const propTypes = {
  onCancel: PropTypes.func,
  requiresWhatsapp: PropTypes.bool
};

const defaultProps = {
  requiresWhatsapp: false
};

function AccountData({ onCancel, requiresWhatsapp }) {
  const formRef = useRef(null);
  const { nextStep, prevStep, accountData, setIsAccountDataSubmitted } = useCheckoutContext();
  const updateMutation = useUpdateCurrentAccount();

  const initialValues = useMemo(() => ({
    legal_name: accountData.legalName ?? '',
    cpf_cnpj: accountData.cpfCnpj ?? '',
    billing_emails: accountData.billingEmails?.join(',') ?? '',
    contact: {
      work_phone: accountData.contact.workPhone ?? '',
      whatsapp: accountData.contact.whatsapp ?? ''
    },
    address: {
      city_id: accountData.address.city?.id ?? '',
      additional_info: accountData.address.additionalInfo ?? '',
      country: accountData.address.country ?? '',
      district: accountData.address.district ?? '',
      state: accountData.address.state ?? '',
      postal_code: accountData.address.postalCode ?? '',
      street_number: (accountData.address.streetNumber ?? '').toString(),
      street_name: accountData.address.streetName ?? ''
    }
  }), [accountData]);

  const onSubmit = (values) => updateMutation.mutate(values, {
    onSuccess: () => {
      nextStep();
    }
  });

  return (
    <>
      <AccountDataForm
        ref={formRef}
        initialValues={initialValues}
        onSubmit={onSubmit}
        requiresWhatsapp={requiresWhatsapp}
      />

      <BillingFooter
        buttonNextText='Avançar'
        onClickPrev={onCancel || prevStep}
        onClickNext={() => {
          if (formRef.current) {
            formRef.current.submitForm();
            setIsAccountDataSubmitted(true);
          }
        }}
      />
    </>
  );
}

AccountData.propTypes = propTypes;
AccountData.defaultProps = defaultProps;

export default AccountData;

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { toCurrencyString } from '@/number';
import { pluralize } from '@/utils';

import DotList from '@/components/DotList';
import Icon from '@/components/Icon';
import Badge from '@/components/Badge';
import DealTabTable from '@/components/EntityModal/Table/DealTabTable';

import { useInfiniteDeals } from '@/api';
import { DealSortInput } from '@/components/Inputs';

DealTab.propTypes = {
  entityId: PropTypes.number.isRequired,
  entityType: PropTypes.string.isRequired
};

DealTab.defaultProps = {
  entityType: 'organization'
};

function DealTab({ entityId, entityType }) {
  const [searchParams, setSearchParams] = useState('created_at desc');

  const params = useMemo(() => {
    const baseParams = {
      sorts: searchParams
    };
    if (entityType === 'organization') {
      baseParams.organization_id_with_related_people = entityId;
    }
    if (entityType === 'person') {
      baseParams[`${entityType}_id_eq`] = entityId;
    }
    return baseParams;
  }, [entityId, entityType]);

  const {
    data:
    deals,
    isLoading: isLoadingDeals,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteDeals({
    config: {
      staleTime: 1000
    },
    params
  });

  const dealsMemoized = useMemo(() => {
    const dealsPages = deals?.pages ?? [];
    const dealsData = dealsPages.flatMap((page) => page.data);
    const dealsMetadata = dealsPages[0]?.meta ?? {};
    const dealTotal = toCurrencyString(dealsMetadata.total_amount);

    return { dealsData, dealsMetadata, dealTotal };
  }, [JSON.stringify(deals)]);

  const { dealsData, dealsMetadata, dealTotal } = dealsMemoized;

  const statusBadges = [
    {
      count: dealsMetadata?.won_count,
      icon: 'won-deal',
      text: pluralize('Ganho', dealsMetadata.won_count)
    },
    {
      count: dealsMetadata.ongoing_count,
      icon: 'active-deal',
      text: 'Em andamento'
    },
    {
      count: dealsMetadata?.lost_count,
      icon: 'lost-deal',
      text: pluralize('Perdido', dealsMetadata.lost_count)
    }
  ];

  return (
    <div className='bg-white p-4 rounded-bottom'>
      <div className='d-flex align-items-center gap-3'>
        <h2 className='mb-0'>Negócios</h2>
        <h4 className='mb-0'>{dealTotal}</h4>
      </div>

      <div className='pt-3 pb-5 d-flex align-items-center'>
        <DotList
          className='d-flex'
          items={[
            <Badge variant='dark-gray' className='fs-6 h-100 d-flex align-items-center' key='badge'>
              {dealsMetadata.total_count} {pluralize('negócio', dealsMetadata.total_count)}
            </Badge>,
            <div className='d-flex gap-2' key='status'>
              {
                statusBadges.map(({ text, count, icon }) => (
                  <Badge
                    key={text}
                    variant='light'
                    className='text-dark-gray fs-6 d-flex align-items-center'
                  >
                    <Icon name={icon} size='sm' className='me-1' />
                    {count} {text}
                  </Badge>
                ))
              }
            </div>
          ]}
        />
        <div className='ms-auto'>
          <DealSortInput value={searchParams} onChange={setSearchParams} />
        </div>
      </div>

      <DealTabTable
        entityId={entityId}
        relatedEntityType={entityType}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isLoading={isLoadingDeals}
        items={dealsData}
        itemsTotal={dealsMetadata.total_count}
      />
    </div>
  );
}

export default DealTab;

import React from 'react';
import PropTypes from 'prop-types';
import FetchableSelect from '@/components/Inputs/FetchableSelect';
import { useACInboxes } from '@/api';

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  allowNull: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func
};

const defaultProps = {
  name: 'user_id_in',
  label: 'Caixa de entrada',
  placeholder: 'Qualquer caixa de entrada',
  multiple: false,
  allowNull: false,
  isClearable: true,
  isSearchable: true,
  defaultValue: null,
  onChange: () => {}
};

function transformer(fetchedData) {
  const connectorApi = fetchedData?.data?.filter((inbox) => inbox.channel_type === 'Channel::Api');

  return connectorApi.map((inbox) => ({
    label: inbox.name,
    value: inbox.id
  }));
}

function InboxSelect(props) {
  return (
    <FetchableSelect
      { ...props }
      transformer={transformer}
      query={useACInboxes}
    />
  );
}

InboxSelect.propTypes = propTypes;
InboxSelect.defaultProps = defaultProps;

export default InboxSelect;

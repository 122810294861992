import React from 'react';
import PropTypes from 'prop-types';
import fullPageGif from 'images/full-page-loading.gif';

LoadingPage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string
};

LoadingPage.defaultProps = {
  title: 'Carregando...',
  subtitle: '',
  className: ''
};

function LoadingPage({ title, subtitle, className }) {
  return (
    <div className={`d-flex align-items-center w-100 ${className ? className : 'min-vh-100'}`}>
      <div className='w-100 mx-auto text-center text-darker-gray mt-n4'>
        <img
          alt={title}
          title={title}
          className='text-center width-4 height-4'
          src={fullPageGif}
        />
        <h2 className='mt-8'>{title}</h2>
        <p>{subtitle}</p>
      </div>
    </div>
  );
}

export default LoadingPage;

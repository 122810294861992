import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Stack from 'react-bootstrap/Stack';
import Form from '@/components/Form';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import Icon from '@/components/Icon';
import {
  TriggerFields,
  Connector,
  ActionFields,
  ModalSummary
} from '@/feature/automation';
import {
  validations, triggerDefaultValues, actionDefaultValues
} from '@/feature/automation/CardFormField';
import Authorization from '@/components/Authorization';
import { canCreateAutomation } from '@/policies/automations';
import { useAuth } from '@/lib/auth';
import FeatureControlPopover from '@/components/FeatureControlPopover';
import WhatsappGoodPracticesModal from '@/feature/automation/WhatsappGoodPracticesModal';

const propTypes = {
  template: PropTypes.object,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  saveButtonLabel: PropTypes.string,
  type: PropTypes.string
};

export const validationSchema = Yup.object().shape({
  trigger_params: Yup.object().nullable()
    .when(
      '$template',
      ([template]) => validations[template.triggerValidation]
    ),
  action_params: Yup.object()
    .when(
      '$template',
      ([template]) => validations[template.actionForm]
    )
});

function AutomationForm(props) {
  const {
    template, initialValues, onSubmit, onDelete, onCancel, title,
    saveButtonLabel, type
  } = props;

  const defaultValues = useMemo(() => getDefaultValues(template), [template]);
  const { user } = useAuth();
  const hasAccess = user.account.legacyPro || user.account.performanceOrHigher;
  const hasAgendorChat = user.account.accountChat;
  const isWhatsappForm = template.actionForm === 'whatsapp';
  const hasFullAccess = isWhatsappForm ? (hasAccess && hasAgendorChat) : hasAccess;

  const [showWhatsappGoodPracticesModal, setShowWhatsappGoodPracticesModal] = useState(false);
  const formRef = useRef(null);
  const isCreateAutomationType = type === 'create';
  const shouldLoading = !isWhatsappForm || (isWhatsappForm && !isCreateAutomationType);

  const handleSubmit = (values, { setFieldError }) => {
    if (isWhatsappForm && !showWhatsappGoodPracticesModal && isCreateAutomationType) {
      return setShowWhatsappGoodPracticesModal(true);
    }
    return onSubmit(values, { setFieldError });
  };

  return (
    <>
      <Form
        defaultValues={defaultValues}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validationContext={{ template }}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        {({ isSubmitting }) => (
          <Stack gap={4}>
            <h2>
              {title}
            </h2>

            <div>
              <TriggerFields template={template} />

              <Connector variant='primary' className='border-2' />

              <ActionFields template={template} />
            </div>

            <ModalSummary template={template} />

            <Stack direction='horizontal' gap={3}>
              {
                onDelete &&
                <Button
                  variant='outline-danger'
                  onClick={onDelete}
                  className='me-auto'
                >
                  <Icon className='me-1 text-outline-danger' name='delete' />
                  <span className='fw-bold'>
                  Excluir
                  </span>
                </Button>
              }

              <Button
                variant='outline-dark-gray'
                onClick={onCancel}
                className='ms-auto'
              >
                Cancelar
              </Button>

              {hasFullAccess
                ? (
                  <Authorization policy={canCreateAutomation}>
                    <LoadingButton
                      type='submit'
                      isLoading={isSubmitting && shouldLoading}
                    >
                      {saveButtonLabel}
                    </LoadingButton>
                  </Authorization>
                )
                : (
                  <FeatureControlPopover
                    user={user}
                    type={hasAccess ? 'agendorChat' : 'plan'}
                  >
                    <Button variant='primary'>
                      <Icon name='lock' size='sm' className='me-1' />
                      Salvar
                    </Button>
                  </FeatureControlPopover>
                )}
            </Stack>
          </Stack>
        )}
      </Form>
      <WhatsappGoodPracticesModal
        show={showWhatsappGoodPracticesModal}
        onHide={() => setShowWhatsappGoodPracticesModal(false)}
        onConfirm={() => {
          if (formRef.current) {
            formRef.current.submitForm();
          }
        }}
      />
    </>
  );
}

function getDefaultValues(template) {
  return {
    trigger_name: template.triggerName,
    trigger_params: triggerDefaultValues[template.triggerValidation],
    action_name: template.actionName,
    action_params: actionDefaultValues[template.actionForm]
  };
}

AutomationForm.propTypes = propTypes;

export default AutomationForm;

import strings from '@/strings';
import { isUnprocessableEntityError } from '@/errors';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import get from 'lodash/get';
import { differenceInDays, isBefore } from 'date-fns';
import { toDecimalString } from '@/number';
import { parseDate } from '@/date';
import { applyMask } from '@/masks';
import storage from '@/lib/storage';

export const ACTIVITY_TYPES = {
  TASK: 'task',
  NOTE: 'note',
  EMAIL: 'email',
  CALL: 'call',
  WHATSAPP: 'whatsapp',
  PROPOSAL: 'proposal',
  MEETING: 'meeting',
  VISIT: 'visit'
};

export const LIST_TYPE = {
  LIST: 'list',
  SEARCH: 'search',
  FILTER: 'filter'
};

export const DEAL_STATUSES = {
  ONGOING: 1,
  WON: 2,
  LOST: 3
};

export const DEAL_STATUSES_KEY = {
  ongoing: 'ongoing',
  won: 'won',
  lost: 'lost'
};

export const ENTITIES = {
  person: 'person',
  organization: 'organization',
  deal: 'deal'
};

export const ENTITIES_TRACKER_SCREEN = {
  person: 'Pessoa',
  organization: 'Empresa',
  deal: 'Negocio'
};

export const EMAIL_STATUSES = {
  PENDING: 'pending',
  SENT: 'sent',
  READ: 'read',
  RECEIVED: 'received',
  FAILED: 'failed'
};

export const PROPOSAL_SECTIONS_TYPE = {
  RICH_TEXT: 'rich_text',
  PRODUCTS: 'products'
};

export const PROPOSAL_SECTIONS_TITLE = {
  RICH_TEXT: 'Seção personalizada',
  PRODUCTS: 'Produtos e serviços'
};

export const VOIP_SERVICES = {
  VIP: 'vip',
  ZENVIA: 'zenvia'
};

export const IMAGE_EXTENSIONS = ['.png', '.jpeg', '.jpg', '.jfif', '.webp', '.gif'];
export const MEDIA_EXTENSIONS = ['.mp3', '.ogg', '.m4a', '.wav'];
export const DOC_EXTENSIONS = [
  '.txt',
  '.doc',
  '.docx',
  '.pdf',
  '.tsv',
  '.csv',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.odp',
  '.pps',
  '.ppsx',
  '.msg',
  '.xml'
];
export const JSON_EXTENSION = '.json';
export const PDF_EXTENSION = '.pdf';
export const VIDEO_EXTENSIONS = ['.mp4', '.webm', '.avi', '.mov', '.wmv', '.flv', '.mkv'];
export const COMMON_EXTENSIONS = ['.svg', '.html', '.ods', '.zip', '.rar', '.dwg'];
export const FILE_EXTENSIONS = [
  ...IMAGE_EXTENSIONS,
  ...MEDIA_EXTENSIONS,
  ...DOC_EXTENSIONS,
  ...VIDEO_EXTENSIONS,
  ...COMMON_EXTENSIONS
];
export const STATES = [
  { acronym: 'AC', full: 'Acre' },
  { acronym: 'AL', full: 'Alagoas' },
  { acronym: 'AM', full: 'Amazonas' },
  { acronym: 'AP', full: 'Amapá' },
  { acronym: 'BA', full: 'Bahia' },
  { acronym: 'CE', full: 'Ceará' },
  { acronym: 'DF', full: 'Distrito Federal' },
  { acronym: 'ES', full: 'Espírito Santo' },
  { acronym: 'GO', full: 'Goiás' },
  { acronym: 'MA', full: 'Maranhão' },
  { acronym: 'MG', full: 'Minas Gerais' },
  { acronym: 'MS', full: 'Mato Grosso do Sul' },
  { acronym: 'MT', full: 'Mato Grosso' },
  { acronym: 'PA', full: 'Pará' },
  { acronym: 'PB', full: 'Paraíba' },
  { acronym: 'PE', full: 'Pernambuco' },
  { acronym: 'PI', full: 'Piauí' },
  { acronym: 'PR', full: 'Paraná' },
  { acronym: 'RJ', full: 'Rio de Janeiro' },
  { acronym: 'RN', full: 'Rio Grande do Norte' },
  { acronym: 'RO', full: 'Rondônia' },
  { acronym: 'RR', full: 'Roraima' },
  { acronym: 'RS', full: 'Rio Grande do Sul' },
  { acronym: 'SC', full: 'Santa Catarina' },
  { acronym: 'SE', full: 'Sergipe' },
  { acronym: 'SP', full: 'São Paulo' },
  { acronym: 'TO', full: 'Tocantins' }
];

export const VOIP_DDD = [
  { ddd: 11, acronym: 'SP', full: 'São Paulo' },
  { ddd: 12, acronym: 'SP', full: 'São José dos Campos' },
  { ddd: 13, acronym: 'SP', full: 'Santos' },
  { ddd: 14, acronym: 'SP', full: 'Bauru' },
  { ddd: 15, acronym: 'SP', full: 'Sorocaba' },
  { ddd: 16, acronym: 'SP', full: 'Ribeirão Preto' },
  { ddd: 17, acronym: 'SP', full: 'São José do Rio Preto' },
  { ddd: 18, acronym: 'SP', full: 'Presidente Prudente' },
  { ddd: 19, acronym: 'SP', full: 'Campinas' },
  { ddd: 21, acronym: 'RJ', full: 'Rio de Janeiro' },
  { ddd: 22, acronym: 'RJ', full: 'Campos Dos Goytacazes' },
  { ddd: 24, acronym: 'RJ', full: 'Volta Redonda' },
  { ddd: 27, acronym: 'ES', full: 'Vitória' },
  { ddd: 31, acronym: 'MG', full: 'Belo Horizonte' },
  { ddd: 32, acronym: 'MG', full: 'Juiz de Fora' },
  { ddd: 33, acronym: 'MG', full: 'Governador Valadares' },
  { ddd: 34, acronym: 'MG', full: 'Uberlândia' },
  { ddd: 35, acronym: 'MG', full: 'Varginha' },
  { ddd: 37, acronym: 'MG', full: 'Divinópolis' },
  { ddd: 38, acronym: 'MG', full: 'Montes Claros' },
  { ddd: 41, acronym: 'PR', full: 'Curitiba' },
  { ddd: 45, acronym: 'PR', full: 'Foz do Iguaçu' },
  { ddd: 46, acronym: 'PR', full: 'Pato Branco' },
  { ddd: 47, acronym: 'SC', full: 'Joinville' },
  { ddd: 48, acronym: 'SC', full: 'Florianópolis' },
  { ddd: 51, acronym: 'RS', full: 'Porto Alegre' },
  { ddd: 61, acronym: 'GO', full: 'Brasília' },
  { ddd: 62, acronym: 'GO', full: 'Goiânia' },
  { ddd: 67, acronym: 'MS', full: 'Anaurilândia' },
  { ddd: 71, acronym: 'BA', full: 'Salvador' },
  { ddd: 81, acronym: 'PE', full: 'Recife' },
  { ddd: 85, acronym: 'CE', full: 'Fortaleza' }
];

export const CNPJ_SIZE = 14;
export const CPF_SIZE = 11;

export const VOIP_MAIN_ERROR = 'Ocorreu um erro ao iniciar o discador. Tente novamente mais tarde.';
export const ZENVIA_ERRORS = {
  '9': 'Sua conta não tem saldo suficiente.',
  '11': 'O número de telefone é inválido.'
};

export function getDocType(cpfCnpj = '') {
  if (cpfCnpj?.length === CNPJ_SIZE) {
    return 'cnpj';
  }
  if (cpfCnpj?.length === CPF_SIZE) {
    return 'cpf';
  }
  return null;
}

export function isImageExtension(fileName) {
  const extension = `.${fileName.split('.').pop()}`.toLowerCase();
  return IMAGE_EXTENSIONS.includes(extension);
}

export function isDocumentExtension(fileName) {
  const extension = `.${fileName.split('.').pop()}`.toLowerCase();
  return [
    ...MEDIA_EXTENSIONS,
    ...DOC_EXTENSIONS,
    ...VIDEO_EXTENSIONS,
    ...COMMON_EXTENSIONS
  ].includes(extension);
}

export function filterForImages(attachments = []) {
  return attachments?.filter((data) => {
    const fileName = data?.fileName || data?.metadata?.filename;
    return isImageExtension(fileName);
  });
}

export function filterForDocuments(attachments = []) {
  return attachments?.filter((data) => {
    const fileName = data?.fileName || data?.metadata?.filename;
    return isDocumentExtension(fileName);
  });
}

export function valuesFromKeys(obj, keys) {
  const entries = Object.entries(obj);
  const filtered = entries.filter(([key, _value]) => keys.includes(key));

  return filtered.map(([_key, value]) => value);
}

export function getSocialMedias(contact = {}) {
  const { facebook, twitter, linkedin, instagram, skype } = contact ?? {};

  const socialMedias = [
    { name: 'facebook', url: facebook ? `https://${facebook}` : null, text: facebook },
    { name: 'twitter', url: twitter ? `https://${twitter}` : null, text: twitter },
    { name: 'linkedin', url: linkedin ? `https://${linkedin}` : null, text: linkedin },
    { name: 'instagram', url: instagram ? `https://${instagram}` : null, text: instagram },
    { name: 'skype', url: skype ? `skype:${skype}?call` : null, text: skype }
  ];

  return socialMedias.filter((socialMedia) => socialMedia.url);
}

export function concatDateForFilter(oldData = '', newData = '') {
  return `${oldData}|${newData}`.replace(/[/]*/gu, '').replace('|', '/');
}

export function concatStringForFilter(oldData = '', newData = '') {
  return `${oldData} ${newData}`;
}

export function getListTypeByParams(params) {
  if (isEmpty(params)) {
    return LIST_TYPE.LIST;
  }
  const { q, sorts, custom_fields: customFields, ...restParams } = params;
  if (q) {
    return LIST_TYPE.SEARCH;
  }
  const filtered = { ...restParams, ...customFields };
  if (Object.keys(filtered).find((key) => filtered[key])) {
    return LIST_TYPE.FILTER;
  }
  return LIST_TYPE.LIST;
}

export function getDisplayName(Component) {
  return Component.name || 'Component';
}

export function isBlank(value) {
  return (value === undefined) || (value === null);
}

export function objectHasValue(object) {
  return Object.values(object).some((value) => (
    value !== null && compact(Object.values(value)).length > 0
  ));
}

export function pluralize(word, count = 0) {
  return `${word}${count === 1 ? '' : 's'}`;
}

export function getString(path, defaultValue) {
  const result = get(strings, path, defaultValue);
  return result;
}

export function getErrorMessage(path, defaultValue) {
  return getString(['errors', ...path], defaultValue);
}

export function getValidationErrors(err, scope) {
  const { data: { errors } = {} } = err;

  if (isUnprocessableEntityError(err) && errors) {
    const entries = Object.entries(errors);

    return entries.reduce((result, [attribute, [firstKey]]) => (
      {
        ...result,
        [attribute]: getErrorMessage([scope, attribute, firstKey], 'Inválido')
      }
    ), {});
  }

  return {};
}

// Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/gu, '\\$&');
}

export function replaceFileNamesSources(html = '', files = []) {
  // eslint-disable-next-line prefer-named-capture-group
  return html.replace(/(<[^>]+src=")([^"]+)("[^>]*>)/gu, (match, prefix, _oldSrc, suffix) => {
    const newSrc = files.shift();
    return newSrc ? `${prefix}${newSrc}${suffix}` : match;
  });
}

export function reshuffle(rows) {
  return rows.map((row, index) => ({ ...row, order: index + 1 }));
}

export function updateListItem(list, itemId, props) {
  let copy = [...list];

  const sourceIndex = copy.findIndex((item) => item.id === Number(itemId));
  const destinationIndex = props.order !== undefined ? props.order - 1 : sourceIndex;

  const sourceItem = copy[sourceIndex];
  const targetItem = { ...sourceItem, ...props };

  if (sourceIndex === destinationIndex) {
    copy[sourceIndex] = targetItem;
  } else {
    copy.splice(sourceIndex, 1);
    copy.splice(destinationIndex, 0, targetItem);

    copy = reshuffle(copy);
  }

  return copy;
}

export function getUrlWithQueryString(url, params = {}) {
  if (Object.keys(params).length) {
    const searchParams = new URLSearchParams(params);
    return `${url}?${searchParams.toString()}`;
  }
  return url;
}

export function customFieldValue(value, customField) {
  if (value !== null && value !== undefined) {
    switch (customField.type) {
      case 'integer':
        return Number(value).toFixed(0);
      case 'decimal':
        return toDecimalString(value);
      case 'multiselect':
        return value
          .map(
            (optionId) => customField.customFieldOptions?.find(
              (option) => option.id === optionId
            )?.option
          )
          .filter(Boolean)
          .join(', ');
      case 'select':
        return customField.customFieldOptions?.find(
          (option) => option.id === value
        )?.option;
      default:
        return value;
    }
  }
}

export function isOptin(featureFlag = null) {
  return isBoolean(featureFlag) ? featureFlag : true;
}

export function getScreenSizeLabel(screenSize) {
  if (screenSize.isSM) {
    return 'sm';
  }
  if (screenSize.isMD) {
    return 'md';
  }
  if (screenSize.isLG) {
    return 'lg';
  }
  if (screenSize.isXL) {
    return 'xl';
  }
  return 'xxxl';
}

export function getPhonesMasked(contact) {
  const uniquePhones = new Set();
  const whatsapp = (contact?.whatsapp ?? '').replace('+55', '');
  uniquePhones.add(applyMask(whatsapp, 'phone'));
  uniquePhones.add(applyMask(contact?.mobilePhone, 'phone'));
  uniquePhones.add(applyMask(contact?.workPhone, 'phone'));

  return Array.from(uniquePhones).filter(Boolean);
}

export function getPhonesOnlyNumbers(contact, showDDI = true) {
  const uniquePhones = new Set();
  const whatsapp = contact?.whatsapp?.replace(/[\D]+/gu, '');
  const mobilePhone = contact?.mobilePhone?.replace(/[\D]+/gu, '');
  const workPhone = contact?.workPhone?.replace(/[\D]+/gu, '');

  uniquePhones.add(whatsapp ? `+${whatsapp}` : '');
  uniquePhones.add(mobilePhone ? `${showDDI ? '+55' : ''}${mobilePhone}` : '');
  uniquePhones.add(workPhone ? `${showDDI ? '+55' : ''}${workPhone}` : '');

  return Array.from(uniquePhones).filter(Boolean);
}

/*
 * Retorna se essa data é superior ou inferior a
 * data mínima para o acesso ao legado
 */
const NEW_USER_DATE_TO_HIDE_LEGACY = '2023-06-14T00:00:00.000Z';

export const showLegacyButton = ({ account, features }) => {
  const blockLegacyEnabled = features?.blockLegacyEnabled;
  if (!account?.createdAt || blockLegacyEnabled) {
    return false;
  }

  const creationDate = parseDate(account.createdAt);
  return isBefore(creationDate, parseDate(NEW_USER_DATE_TO_HIDE_LEGACY));
};

export function extractComponentText(content) {
  if (content.props && content.props.children) {
    return extractComponentText(content.props.children);
  }
  if (Array.isArray(content)) {
    return content.map(extractComponentText).join('');
  }
  if (typeof content === 'string') {
    return content;
  }
  return '';
}
export function formatAddressLine(address = {}) {
  const streetName = address?.streetName ?? '';
  const streetNumber = address?.streetNumber ?? '';
  const district = address?.district ?? '';
  const cityName = address?.city?.name ?? '';
  const state = address?.state ?? '';
  const postalCode = applyMask(address?.postalCode, 'postalCode');
  if (postalCode && cityName && streetName) {
    return `${streetName}, ${streetNumber}, ${district}, ${cityName} - ${state}, ${postalCode}`;
  }
  return '';
}

export function formatAddressLineWithoutPostalCode(address = {}) {
  const streetName = address?.streetName ?? '';
  const streetNumber = address?.streetNumber ?? '';
  const district = address?.district ?? '';
  const cityName = address?.city?.name ?? '';
  const state = address?.state ?? '';
  if (cityName && streetName) {
    return `${streetName}, ${streetNumber}, ${district}, ${cityName} - ${state}`;
  }
  return '';
}

export function getForgottenFilters(params) {
  return [params.next_task, params.latest_activity].filter(Boolean).join(',');
}

const ONE_HOUR_IN_MILLISECONDS = 3600000;
const FIVE_MINUTES_IN_MILLISECONDS = 300000;

export const accessoryCallCache = {
  staleTime: ONE_HOUR_IN_MILLISECONDS,
  cacheTime: ONE_HOUR_IN_MILLISECONDS + FIVE_MINUTES_IN_MILLISECONDS
};

export function formatRGBA(color) {
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
}

export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    if (blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    } else {
      resolve(null);
    }
  });
}

export async function downloadJSONFromUrl(url, opts = {}) {
  try {
    if (!url) {
      return null;
    }
    const response = await fetch(url, { mode: 'cors', ...opts });
    if (!response.ok) {
      return null;
    }
    const json = await response.json();
    return json;
  } catch {
    return null;
  }
}

export async function downloadBlob(url, opts = {}) {
  try {
    if (!url) {
      return null;
    }
    const response = await fetch(url, { mode: 'cors', ...opts });
    if (!response.ok) {
      return null;
    }
    const blob = await response.blob();
    return blob;
  } catch {
    return null;
  }
}

export async function downloadBlobToBase64(url, opts = {}) {
  try {
    const blob = await downloadBlob(url, opts);
    return blobToBase64(blob);
  } catch {
    return null;
  }
}

export function isFeedbackRecent(screen) {
  const DAYS_LIMIT = 30;
  const lastFeedbackDate = storage.get(`lastFeedbackDate_${screen}`);

  const isWithinDaysLimit = Boolean(lastFeedbackDate) &&
    differenceInDays(new Date(), new Date(lastFeedbackDate)) < DAYS_LIMIT;

  return isWithinDaysLimit;
}

export const prepareTrackerIntent = (intent = null) => {
  if (!intent) {
    return null;
  }

  const { id, type, value, suggestionData } = intent;

  return {
    id,
    value,
    suggestion_data: suggestionData,
    suggestion_type: type.startsWith('activity.') ? 'activity' : type.replace('contact.', ''),
    activity_type: type.startsWith('activity.') ? type.replace('activity.', '') : null
  };
};

export function prepareTrackerIntentsFrom(suggestions) {
  return suggestions?.map((
    { id, suggestionType: type, capturedText, suggestionData }
  ) => prepareTrackerIntent({ id, type, value: capturedText, suggestionData }));
}

export function prepareProposalModelFrom(proposalData) {
  let proposalModelSections = proposalData?.proposalModelSections;
  if (!proposalData?.id) {
    proposalModelSections = proposalModelSections?.filter((section) => !section?._destroy);
  }
  const params = {
    ...proposalData,
    cpf_cnpj: proposalData?.cpfCnpj,
    legal_name: proposalData?.legalName,
    bg_color: proposalData?.bgColor,
    text_color: proposalData?.textColor,
    hidden_fields: proposalData?.hiddenFields,
    expires_in_days: proposalData?.expiresInDays,
    proposal_model_sections: proposalModelSections?.map((section) => {
      const { sectionType } = section;
      return { ...section, section_type: sectionType };
    })
  };
  return params;
}

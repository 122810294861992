import React from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';

import Dropdown from '@/components/Dropdown';
import Icon from '@/components/Icon';

import { useGenerateChatSecondCharge, useGenerateSecondCharge } from '@/api';
import { chargeCompletionPath } from '@/routes';

const propTypes = {
  crmSubscription: PropTypes.bool
};

const defaultProps = {
  crmSubscription: false
};

const PAYMENT_TYPE_IDS = {
  bank_slip: 14,
  pix: 15
};

function GenerateSecondChargeButton({ crmSubscription }) {
  const alert = useAlert();
  const navigate = useNavigate();
  const generateSecondChargeMutation = useGenerateSecondCharge();
  const generateChatSecondChargeMutation = useGenerateChatSecondCharge();
  const getMutation = () => (
    crmSubscription ? generateSecondChargeMutation : generateChatSecondChargeMutation
  );
  const mutation = getMutation();

  const handleGenerateChargeError = () => {
    alert.show(
      <div>
        <h6>Infelizmente, não conseguimos gerar o seu pix/boleto</h6>
        <div>Aguarde alguns minutos e tente novamente.</div>
        <div>Se o erro persistir, entre em contato com o nosso suporte.</div>
      </div>,
      { variant: 'danger' }
    );
  };

  const onCreate = (type) => {
    const params = { payment_type_id: PAYMENT_TYPE_IDS[type] };

    mutation.mutate(params, {
      onSuccess: ({ data }) => {
        const updatedData = {
          transactionId: data.id,
          paymentType: type
        };

        navigate(chargeCompletionPath(), { state: updatedData });
      },
      onError: () => {
        handleGenerateChargeError();
      }
    });
  };

  return (
    <div className='width-4'>
      <Dropdown align='end' className='w-100 p-0'>
        <Dropdown.Toggle
          id='menu-toggle-generate-pix-boleto'
          variant='link'
          className='p-0 border-0 my-1 fw-bold'
        >
          <Icon name='download' className='ms-0 ps-0 me-1' /> Gerar pix/boleto
        </Dropdown.Toggle>

        <Dropdown.Menu
          renderOnMount
          className='min-width-5'
        >
          <Dropdown.Item
            id='menu-item-pix'
            as='button'
            onClick={() => onCreate('pix')}
            className='text-dark-gray fw-bold'
          >
            <Icon
              name='pix'
              size='sm'
              className='text-primary me-2 align-middle'
            />
            <span className='align-middle'>Pagar com Pix</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            id='menu-item-bank-slip'
            as='button'
            onClick={() => onCreate('bank_slip')}
            className='text-dark-gray fw-bold'
          >
            <Icon
              name='bar-code'
              size='sm'
              className='text-primary me-2 align-middle'
            />
            <span className='align-middle'>Pagar com boleto</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

GenerateSecondChargeButton.propTypes = propTypes;
GenerateSecondChargeButton.defaultProps = defaultProps;

export default GenerateSecondChargeButton;

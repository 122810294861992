import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';

import { parseDate, toTextualDateString } from '@/date';
import { toCurrencyString } from '@/number';
import { useCheckoutContext } from '@/contexts';
import { settingsManageUpdatePlanPath } from '@/routes';

import DiscountBanner from '@/components/Billing/DiscountBanner';
import Icon from '@/components/Icon';
import PeriodSelect from '@/components/Billing/PeriodSelect';
import ProratedSummary from '@/components/Billing/ProratedSummary';
import Popover from '@/components/Popover';
import Link from '@/components/Link';

const propTypes = {
  periodicity: PropTypes.oneOf(['monthly', 'quarterly', 'half_yearly', 'yearly']).isRequired,
  finalValue: PropTypes.number.isRequired,
  expirationDate: PropTypes.string.isRequired,
  currentValuePerUser: PropTypes.number,
  finalDiscountAmount: PropTypes.number,
  withoutDiscountValue: PropTypes.number,
  defaultDiscount: PropTypes.shape({
    title: PropTypes.string,
    percentage: PropTypes.number,
    finalDiscountAmount: PropTypes.number
  }),
  customDiscount: PropTypes.shape({
    title: PropTypes.string,
    percentage: PropTypes.number,
    finalDiscountAmount: PropTypes.number
  }),
  maxUsersCount: PropTypes.number,
  isPlanStep: PropTypes.bool,
  monthlyInfo: PropTypes.shape({
    value: PropTypes.number,
    valuePerUser: PropTypes.number,
    valueWithoutDiscount: PropTypes.number
  })
};

const periodicityTexts = {
  monthly: 'mensal',
  quarterly: 'trimestral',
  half_yearly: 'semestral',
  yearly: 'anual'
};

function PaymentSummary({ isPlanStep }) {
  const {
    currentPlanData,
    checkoutResumeData: {
      periodicity,
      name,
      finalValue,
      totalValue,
      currentExpirationDate,
      expirationDate,
      finalValueWithoutDiscount,
      discounts,
      currentDiscounts,
      prorateDiscounts,
      maxUsersCount,
      remainingDays,
      proratedInfo
    }
  } = useCheckoutContext();

  const hasDiscount = (discounts || []).length > 0;

  const withoutDiscountValueFormatted = toCurrencyString(finalValueWithoutDiscount);
  const expirationDateFormatted = toTextualDateString(parseDate(expirationDate));
  const finalValueFormatted = toCurrencyString(finalValue);
  const totalValueFormatted = toCurrencyString(totalValue);
  const periodicityText = periodicityTexts[periodicity];
  const currentPeriodicityText = periodicityTexts[currentPlanData.periodicity];
  const fullPlanText = `${name} ${maxUsersCount} ${capitalize(periodicityText)}`;

  return (
    <div
      id='payment-summary'
      className={
        classnames(
          'w-100',
          'text-small',
          'bg-light',
          'p-6 rounded'
        )
      }
    >
      {isPlanStep ? <ChoosePlan /> : <InvoiceInfo />}

      <h4 className='mt-2 mb-4'>Resumo</h4>

      <h5 className='mb-2 fw-normal'>Plano {fullPlanText}</h5>

      {proratedInfo && (
        <>
          <RowContent
            title={
              <div className='d-flex align-items-center text-body'>
                <span className='me-2'>Pró-rata</span>

                <ProratedSummary
                  remainingDays={remainingDays}
                  planFullName={proratedInfo.planFullName}
                  currentPlanFullName={proratedInfo.currentPlanFullName}
                  valuePerMonth={proratedInfo.valuePerMonth}
                  currentValuePerMonth={proratedInfo.currentValuePerMonth}
                  diffValuePerMonth={proratedInfo.diffValuePerMonth}
                  diffValuePerDay={proratedInfo.diffValuePerDay}
                  value={proratedInfo.value}
                  valueWithoutCustomDiscount={proratedInfo.valueWithoutCustomDiscount}
                  discounts={discounts}
                  currentDiscounts={currentDiscounts}
                  prorateDiscounts={prorateDiscounts}
                  periodicityText={capitalize(currentPeriodicityText)}
                />
              </div>
            }
            content={toCurrencyString(proratedInfo.value)}
          />

          <RowContent
            title={<span className='fw-normal'>Período</span>}
            content={`Até ${toTextualDateString(parseDate(currentExpirationDate))}`}
          />

          <hr className='mt-3 mb-2 opacity-100' />
        </>
      )}

      <RowContent
        title={<span className='text-body'>Renovação do Plano</span>}
        content={
          <>
            {hasDiscount &&
              <span className='me-1'>
                <s>{withoutDiscountValueFormatted}</s>
              </span>
            }

            <span className='text-success'>{finalValueFormatted}</span>
          </>
        }
      />

      <RowContent
        title={<span className='fw-normal'>Período</span>}
        content={`Até ${expirationDateFormatted}`}
      />

      <hr className='mt-3 mb-2 opacity-100' />

      {hasDiscount &&
        discounts.map((discount) => (
          <DiscountBanner
            key={discount.id}
            discount={discount}
            periodicityText={periodicityText}
          />
        ))
      }

      <div className='mt-4 d-flex align-items-baseline justify-content-between'>
        <h4 className='text-dark-gray'>Valor total</h4>

        <h2>{totalValueFormatted}</h2>
      </div>

      {
        proratedInfo && (
          <div className='d-flex justify-content-end mt-1'>
            <SummaryExplanation />
          </div>
        )
      }
    </div>
  );
}

function SummaryExplanation() {
  return (
    <Popover
      content={() => (
        <div className='w-100 text-dark-gray'>
          <h4 className='mb-3'>Entenda o cálculo</h4>

          <div className='mb-3'>
            Nessa tela, você irá contratar a renovação do seu plano. Isso significa que<br />
            você estará contratando o Agendor por mais um período.<br />
            Se você mudar o plano e houver diferença no valor, você também irá<br />
            pagar por essa diferença (pró-rata) direto nessa tela.
          </div>

          <div className='fw-bold'>
            Se você quiser contratar somente a mudança de plano sem contratar<br />
            um novo período, <Link href={settingsManageUpdatePlanPath()}><u>clique aqui</u></Link>.
          </div>
        </div>
      )}
      className='mw-100'
      contentClassName='px-3 py-4 bg-dark-light rounded-1'
      trigger={['click']}
      placement='left-start'
      rootClose
      rootCloseEvent='mousedown'
      flip
    >
      <div className='text-dark-gray cursor-pointer text-underline'>
        Entenda o cálculo
      </div>
    </Popover>
  );
}

function ChoosePlan() {
  return (
    <>
      <h5 className='text-dark-gray'>Assinatura do plano</h5>

      <div className='mt-2'>
        <PeriodSelect />
      </div>

      <hr className='mt-2 mb-2 opacity-100' />
    </>
  );
}

function InvoiceInfo() {
  return (
    <div className={classnames(
      'd-flex',
      'align-items-center',
      'mb-2',
      'mt-1',
      'py-2',
      'ps-2',
      'pe-8',
      'bg-white',
      'rounded',
      'text-dark-gray'
    )}>
      <div>
        <Icon name='info' />
      </div>
      <div className='ms-3'>
        A nota fiscal será gerada e enviada para o seu e-mail
        automaticamente em até 24 horas após a confirmação do pagamento
      </div>
    </div>
  );
}

function RowContent({ title, content }) {
  return (
    <div className='d-flex align-items-baseline justify-content-between mb-1'>
      <div className='fw-bold text-dark-gray me-8'>{title}</div>

      <div className='ms-2'>{content}</div>
    </div>
  );
}

PaymentSummary.propTypes = propTypes;

export default PaymentSummary;

import React from 'react';
import PropTypes from 'prop-types';
import DetailsCode from '@/components/EntityModal/Deal/DetailsCode';
import DetailsContact from '@/components/EntityModal/DetailsContact';
import DetailsData from '@/components/EntityModal/Deal/DetailsData';
import DetailsIntegrations from '@/components/EntityModal/Deal/DetailsIntegrations';
import DetailsProducts from '@/components/EntityModal/Deal/DetailsProducts';
import { useVoip } from '@/contexts/dealModal';

Details.defaultProps = {
  deal: {},
  dealCustomFields: [],
  entity: {},
  entityType: '',
  entityEditPath: '',
  entityCustomFields: [],
  loading: true,
  onRefreshModal: () => {},
  onEditDeal: () => {},
  onEditEntity: () => {},
  onCreateProposal: () => {},
  onCreateActivity: () => {}
};

Details.propTypes = {
  deal: PropTypes.object,
  dealCustomFields: PropTypes.array,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  entityEditPath: PropTypes.string,
  entityCustomFields: PropTypes.array,
  canEditPolicy: PropTypes.func,
  loading: PropTypes.bool,
  onRefreshModal: PropTypes.func,
  onEditDeal: PropTypes.func,
  onEditEntity: PropTypes.func,
  onCreateProposal: PropTypes.func,
  onCreateActivity: PropTypes.func
};

function Details({
  deal,
  dealCustomFields,
  entity,
  entityType,
  entityEditPath,
  entityCustomFields,
  canEditPolicy,
  loading,
  onRefreshModal,
  onEditDeal,
  onEditEntity,
  onCreateProposal,
  onCreateActivity
}) {
  const voip = useVoip();
  return (
    <>
      <DetailsIntegrations
        deal={deal}
        loading={loading}
        entity={entity}
        entityType={entityType}
        onRefreshModal={onRefreshModal}
        onCreateProposal={onCreateProposal}
        onCreateActivity={onCreateActivity}
        onUpdateEntity={onEditEntity}
      />
      <DetailsProducts
        dealId={deal.id}
        products={deal.entityProducts}
        dealValue={deal.value}
        loading={loading}
        onEditDeal={onEditDeal}
      />
      <DetailsData
        customFields={dealCustomFields}
        deal={deal}
        loading={loading}
        onEditDeal={onEditDeal}
      />
      <DetailsContact
        deal={deal}
        voip={voip}
        onEditEntity={onEditEntity}
        entity={entity}
        entityType={entityType}
        entityTypeModal='deal'
        entityEditPath={entityEditPath}
        entityCustomFields={entityCustomFields}
        canEditPolicy={canEditPolicy}
        loading={loading}
        onCreatePerson={onRefreshModal}
        dropboxEmail={deal?.dropboxEmail}
      />
      <DetailsCode deal={deal} loading={loading} />
    </>
  );
}

export default Details;

import React, { useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';
import { useNavigate } from 'react-router-dom';
import {
  checkoutPath,
  chatCheckoutPath,
  legacyAccountUrl,
  legacyPlansUrl,
  legacyRegisterUserUrl,
  legacyUsersUrl,
  settingsManagePaymentMethodPath,
  settingsManageChatPaymentMethodPath,
  settingsManageUpdatePlanPath,
  settingsManageUpdateChatPlanPath,
  plansPath
} from '@/routes';

import Button from '@/components/Button';
import Badge from '@/components/Badge';
import Card from '@/components/Card';
import CreateExportModal from '@/components/CreateExportModal';
import Dropdown from '@/components/Dropdown';
import ExternalLink from '@/components/ExternalLink';
import GenerateSecondChargeButton from '@/components/Billing/GenerateSecondChargeButton';
import Icon from '@/components/Icon';
import Link from '@/components/Link';
import Main from '@/components/Main';
import Modal from '@/components/Modal';
import Tabs from '@/components/Tabs';
import NavLink from '@/components/NavLink';

import { useCurrentAccount, useCurrentPlan, useCurrentChatPlan } from '@/api';
import { redirect } from '@/browser';
import { useOffcanvasPageContext } from '@/contexts';
import { isNotFoundError } from '@/errors';
import { useDocumentTitle } from '@/hooks';
import { pluralize } from '@/utils';
import { parseDate, toDateString } from '@/date';
import { applyMask } from '@/masks';

import chattingImage from 'images/billing/chatting.svg';

const PAGE_TITLE = 'Gerenciar plano';
const PAGE_SUBTITLE = `
Gerencie seu plano no Agendor, método de pagamento e outras informações importantes.
`;

function ManageCurrentPlan() {
  const [exportEntity, setExportEntity] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useDocumentTitle(PAGE_TITLE);

  const canvasContext = useOffcanvasPageContext();

  const handleError = (err) => {
    if (isNotFoundError(err)) {
      redirect(legacyPlansUrl());
    }
  };

  const { data: { data: planData } = {}, isSuccess: loadedCurrentPlan } = useCurrentPlan({
    config: { onError: handleError }
  });
  const {
    data: { data: chatPlanData } = {},
    isSuccess: isChatSuccess,
    error: chatError
  } = useCurrentChatPlan();

  const chatNotFound = chatError && isNotFoundError(chatError);

  const { data: accountData, isSuccess: loadedCurrentAccount } = useCurrentAccount();

  if (!loadedCurrentPlan || !loadedCurrentAccount || (!isChatSuccess && !chatNotFound)) {
    return <div className='flex-grow-1 bg-light' />;
  }

  const { stats, billingEmails, name, cpfCnpj } = accountData.data;

  const isFreePlan = (data) => (
    data.subscriptionType === 'free'
  );

  const isTrialPlan = (data) => (
    data.subscriptionType === 'trial'
  );

  const isBlockedOrCanceled = (data) => (
    data.blocked || data.canceled
  );

  const showChatSection = !chatNotFound && !isTrialPlan(chatPlanData) && !isFreePlan(chatPlanData);

  const createExport = (entity) => {
    setExportEntity(entity);
    setShowExportModal(true);
  };

  const formattedEmails = billingEmails
    ? billingEmails.slice(0, 2).join(', ') + (billingEmails.length > 2 ? '...' : '')
    : 'Nenhum e-mail informado';

  const defaultActiveTab = location.state?.target?.type ?? 'crm';

  return (
    <Main fluid className='py-8 ps-4 pe-4 bg-light'>
      <Container>
        <div className='d-flex align-items-start'>
          <div className='flex-fill'>
            <h2 className='mb-2'>{PAGE_TITLE}</h2>
          </div>

          <Button
            className={'rounded border-0 px-2 py-1'}
            variant='light-gray'
            type='button'
            aria-label='Fechar'
            onClick={canvasContext.hide}
          >
            <Icon className='text-dark-gray' name='close' />
          </Button>
        </div>

        <p>{PAGE_SUBTITLE}</p>

        <hr className='mb-2' />

        <Tabs.Container defaultActiveKey={defaultActiveTab}>
          <Tabs>
            <Tabs.Item>
              <NavLink eventKey='crm'>
                Agendor CRM
              </NavLink>
            </Tabs.Item>

            {showChatSection && (
              <Tabs.Item>
                <NavLink eventKey='chat'>
                  Agendor Chat
                </NavLink>
              </Tabs.Item>
            )}
          </Tabs>

          <Tabs.Content>
            <Tabs.Pane eventKey='crm'>
              <CardDetails title='Estatísticas da conta'>
                <div className='flex-fill'>
                  <div className='my-1 text-dark-gray'>
                    <Icon name='organization' className='me-1 text-dark-gray' />
                    <b>{stats.organizationsCount}</b>{' '}
                    {pluralize('empresa', stats.organizationsCount)}
                  </div>

                  <div className='my-1 text-dark-gray'>
                    <Icon name='person' className='me-1 text-dark-gray' />
                    <b>{stats.peopleCount}</b> {pluralize('pessoa', stats.peopleCount)}
                  </div>

                  <div className='my-1 text-dark-gray'>
                    <Icon name='deal' className='me-1 text-dark-gray' />
                    <b>{stats.dealsCount}</b> {pluralize('negócio', stats.dealsCount)}
                  </div>
                </div>

                <div className='width-4'>
                  <Dropdown align='end' className='w-100 p-0'>
                    <Dropdown.Toggle
                      id='menu-toggle'
                      variant='light'
                      className='w-100'
                    >
                      <Icon name='download' className='me-1' /> Exportar
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      renderOnMount
                      className='min-width-5'
                    >
                      <Dropdown.Item
                        id='menu-item-export-organization'
                        as='button'
                        onClick={() => createExport('organization')}
                        className='text-dark-gray fw-bold'
                      >
                        <Icon
                          name='organization'
                          size='sm'
                          className='text-primary me-2 align-middle'
                          withStroke
                        />
                        <span className='align-middle'>Exportar empresas</span>
                      </Dropdown.Item>

                      <Dropdown.Divider />
                      <Dropdown.Item
                        id='menu-item-export-people'
                        as='button'
                        onClick={() => createExport('person')}
                        className='text-dark-gray fw-bold'
                      >
                        <Icon
                          name='person'
                          size='sm'
                          className='text-primary me-2 align-middle'
                          withStroke
                        />
                        <span className='align-middle'>Exportar pessoas</span>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        id='menu-item-export-deal'
                        as='button'
                        onClick={() => createExport('deal')}
                        className='text-dark-gray fw-bold'
                      >
                        <Icon
                          name='deal'
                          size='sm'
                          className='text-primary me-2 align-middle'
                          withStroke
                        />
                        <span className='align-middle'>Exportar negócios</span>
                      </Dropdown.Item>

                      <Dropdown.Divider />
                      <Dropdown.Item
                        id='menu-item-export-proposal'
                        as='button'
                        onClick={() => createExport('activity')}
                        className='text-dark-gray fw-bold'
                      >
                        <Icon
                          name='proposal'
                          size='sm'
                          className='text-primary me-2 align-middle'
                          withStroke
                        />
                        <span className='align-middle'>Exportar histórico</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <CreateExportModal
                  exportEntity={exportEntity}
                  showExportModal={showExportModal}
                  onHide={() => setShowExportModal(false)}
                  initialSize={'md'}
                  withoutOptions={true}
                />
              </CardDetails>

              <SubscriptionCardItem
                data={planData}
                setShowModal={setShowModal}
                isFreeCancelable
                managePaymentMethodPath={settingsManagePaymentMethodPath()}
                manageUpdatePlanPath={settingsManageUpdatePlanPath()}
                subscriptionCheckoutPath={checkoutPath()}
                secondChargeButtonProps={{ crmSubscription: true }}
              />

              <UsersDetails planData={planData} />

              {!isBlockedOrCanceled(planData) && !isFreePlan(planData) && (
                <CardDetails title='Detalhes do faturamento'>
                  <div className='d-flex flex-column flex-fill'>
                    <div className='d-flex align-items-center'>
                      <div className='flex-fill'>
                        {name}{cpfCnpj && `, ${applyMask(cpfCnpj, 'cpf_cnpj')}`}
                      </div>
                      <Button
                        as={ExternalLink}
                        variant='light'
                        className='my-1 min-width-4'
                        href={legacyAccountUrl()}
                      >
                        <Icon name='filter-settings' className='me-1' /> Editar
                      </Button>
                    </div>
                    <div className='my-1 text-dark-gray flex-fill mt-4'>
                      <b>E-mail(s) do financeiro: </b>{formattedEmails}
                    </div>
                    <span className='text-small text-dark-gray'>
                      As faturas e notas fiscais serão enviados para este e-mail.
                    </span>
                  </div>
                </CardDetails>
              )}
            </Tabs.Pane>

            {showChatSection && (
              <Tabs.Pane eventKey='chat'>
                <SubscriptionCardItem
                  data={chatPlanData}
                  setShowModal={setShowModal}
                  managePaymentMethodPath={settingsManageChatPaymentMethodPath()}
                  manageUpdatePlanPath={settingsManageUpdateChatPlanPath()}
                  subscriptionCheckoutPath={chatCheckoutPath()}
                  secondChargeButtonProps={{ crmSubscription: false }}
                />
              </Tabs.Pane>
            )}
          </Tabs.Content>
        </Tabs.Container>
        <CancelModal setShowModal={setShowModal} showModal={showModal} />
      </Container>
    </Main>
  );
}

function SubscriptionCardItem({
  data,
  managePaymentMethodPath,
  manageUpdatePlanPath,
  subscriptionCheckoutPath,
  secondChargeButtonProps,
  isFreeCancelable,
  setShowModal
}) {
  const navigate = useNavigate();

  const isFreePlan = data.subscriptionType === 'free';
  const isBlockedOrCanceled = data.blocked || data.canceled;
  const isExpiredOrExpiringToday = data.expiredOrExpiringToday;

  const blockedDaysLabel = data.daysBeenBlocked === 0
    ? 'Bloqueada hoje'
    : `Bloqueada há ${data.daysBeenBlocked} ${pluralize('dia', data.daysBeenBlocked)}`;

  const canceledDaysLabel = data.canceledDate
    ? `Cancelada em ${toDateString(parseDate(data.canceledDate))}`
    : null;

  const warningLabel = canceledDaysLabel || blockedDaysLabel;

  const recurrencyPeriod = useMemo(() => {
    const map = {
      monthly: 'mensal',
      yearly: 'anual',
      quarterly: 'trimestral',
      half_yearly: 'semestral'
    };

    return map[data.periodicity];
  }, [data.periodicity]);

  const paymentMethodDetails = useMemo(() => {
    const paymentInfo = data.paymentInfo;

    if (!paymentInfo) {
      return null;
    }

    if (paymentInfo.method === 'bank_slip') {
      return 'Boleto';
    } else if (paymentInfo.method === 'pix') {
      return 'Pix';
    }

    const digits = paymentInfo.lastFourDigits;

    return `Cartão de crédito${digits ? ` (${digits})` : ''}`;
  }, [data.paymentInfo?.method, data.paymentInfo?.lastFourDigits]);

  return (
    <CardDetails title='Detalhes da assinatura'>
      <div className='flex-fill'>
        {!isFreePlan && (
          <div className='my-1 text-dark-gray' >
            <b>Assinatura: </b> Recorrência {recurrencyPeriod}
          </div>
        )}

        <div className='my-1 text-dark-gray' >
          <b>Plano: </b> {data.name} {data.maxUsersCount}
        </div>
        {isBlockedOrCanceled && !isFreePlan && (
          <div className='my-1 text-danger' >
            {warningLabel}
          </div>
        )}

        {!isBlockedOrCanceled && !isFreePlan && (
          <>
            <div className='my-1 text-dark-gray' >
              <b>Método de pagamento: </b> {paymentMethodDetails}
            </div>

            <GenerateSecondChargeButton {...secondChargeButtonProps} />
          </>
        )}

      </div>

      <div className={'d-flex flex-column width-4'}>
        {!isFreePlan && (
          <Button
            variant='light'
            className='my-1 w-100'
            as={Link}
            href={subscriptionCheckoutPath}
          >
            <Icon name='plus' className='me-1' />
            Renovar
          </Button>
        )}

        {!isBlockedOrCanceled && !isFreePlan &&
          <div className='width-4'>
            <Dropdown align='end' className='w-100 p-0'>
              <Dropdown.Toggle
                id='menu-toggle-edit'
                variant='light'
                className='my-1 w-100'
              >
                <Icon name='filter-settings' className='me-1' />Editar
              </Dropdown.Toggle>

              <Dropdown.Menu
                renderOnMount
                className='min-width-5'
              >
                {!isExpiredOrExpiringToday && (
                  <>
                    <Dropdown.Item
                      id='menu-item-update-plan'
                      as='button'
                      onClick={() => navigate(manageUpdatePlanPath)}
                      className='text-dark-gray fw-bold'
                    >
                      <Icon
                        name='edit-field'
                        size='sm'
                        className='text-primary me-2 align-middle'
                        withStroke
                      />
                      <span className='align-middle'>Editar plano</span>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                )}
                <Dropdown.Item
                  id='menu-item-update-payment-method'
                  as='button'
                  onClick={() => navigate(managePaymentMethodPath)}
                  className='text-dark-gray fw-bold'
                >
                  <Icon
                    name='note'
                    size='sm'
                    className='text-primary me-2 align-middle'
                    withStroke
                  />
                  <span className='align-middle'>Editar método de pagamento</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        }

        {isFreePlan && (
          <Button
            as={ExternalLink}
            href={plansPath()}
            className='my-1 w-100'
          >
            <Icon name='descending' className='me-1' /> Ver planos
          </Button>
        )}

        {(!isBlockedOrCanceled || (isFreeCancelable && isFreePlan)) && (
          <Button
            onClick={() => setShowModal(true)}
            variant='light'
            className='my-1 w-100'
          >
            <Icon name='close' className='me-1' /> Cancelar
          </Button>
        )}
      </div>
    </CardDetails>
  );
}

function CardDetails({ title, children }) {
  return (
    <Card className='my-2 px-4 py-3 border-light'>
      <Card.Header
        as='h5'
        className='text-dark-gray bg-transparent border-light mb-1 pb-2 px-0'
      >
        {title}
      </Card.Header>

      <Card.Body className='d-flex align-items-center px-0'>
        {children}
      </Card.Body>
    </Card>
  );
}

function CancelModal({ showModal, setShowModal }) {
  const { showNewMessages } = useIntercom();

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size='lg'
      bodyClassName='py-9 px-8 text-center'
    >
      <img className='mb-6 img-fluid' src={chattingImage} />

      <h1 className='mb-4'>Solicitar cancelamento da assinatura</h1>

      <div className='mb-2 fw-bold text-dark-gray'>
        Caso você deseje cancelar sua assinatura, pedimos que entre em contato
        com o nosso suporte para que possamos ajudá-lo com o processo.
      </div>

      <span className='text-dark-gray'>
        Nosso horário de atendimento é de segunda a sexta, das 9h às 18h.
      </span>

      <div className='mt-9'>
        <Button
          onClick={() => showNewMessages('Quero cancelar minha assinatura')}
          className='me-3'>
          Falar com suporte
        </Button>

        <Button variant='light' onClick={() => setShowModal(false)}>Voltar</Button>
      </div>
    </Modal>
  );
}

function UsersDetails({ planData }) {
  const availableInvites = planData.maxUsersCount - planData.currentActiveUsersCount;
  const hasAvailableInvites = Boolean(availableInvites);

  return (
    <CardDetails title='Usuários'>
      <div className='flex-fill'>
        <div className='my-1 text-dark-gray'>
          <b>
            {planData.currentActiveUsersCount}
            {' '}
            {pluralize('usuário', planData.currentActiveUsersCount)}
            {' '}
            {pluralize('ativo', planData.currentActiveUsersCount)}
          </b>
        </div>

        {hasAvailableInvites && (
          <Badge variant='light-green'>
            {availableInvites} {pluralize('convite', availableInvites)} de usuário{' '}
            {availableInvites > 1 ? 'disponíveis' : 'disponível'}
          </Badge>
        )}
      </div>

      <div className='d-flex flex-column width-4'>
        {hasAvailableInvites && (
          <Button
            as={ExternalLink}
            href={legacyRegisterUserUrl()}
            variant='light'
            className='my-1 w-100'
          >
            <Icon name='add' className='me-1' /> Convidar
          </Button>
        )}

        <Button
          as={ExternalLink}
          variant='light'
          className='my-1 w-100'
          href={legacyUsersUrl()}
        >
          <Icon name='filter-settings' className='me-1' /> Gerenciar
        </Button>
      </div>
    </CardDetails>
  );
}

export default ManageCurrentPlan;

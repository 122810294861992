import React from 'react';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import Button from '@/components/Button';
import { goBack } from '@/browser';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import { TextArea, UserSelect } from '@/components/Inputs';
import Breadcrumb from '@/components/Breadcrumb';
import { useAutomationContext } from '@/pages/settings/automations/modal';
import strings from '@/strings';
import InboxSelect from '@/components/Inputs/InboxSelect';
import Tooltip from '@/components/Tooltip';
import Icon from '@/components/Icon';

const MESSAGE_MAX_LENGTH = 10_000;

/* eslint-disable no-magic-numbers */
const validationSchema = Yup.object().shape({
  inbox_id: Yup.number()
    .nullable()
    .required('Por favor, selecione a caixa de entrada.'),
  from: Yup.string()
    .nullable()
    .required('Por favor, selecione o remetente.'),
  to: Yup.string()
    .nullable()
    .required('Por favor, selecione o destinatário.'),
  message: Yup.string()
    .required('Por favor, preencha a mensagem.')
    .max(MESSAGE_MAX_LENGTH, `Mensagem deve ter no máximo ${MESSAGE_MAX_LENGTH} caracteres`)
});
/* eslint-enable no-magic-numbers */

const fromDefaultOptions = [
  {
    options: [
      {
        value: 'owner_user',
        label: strings.labels.automation.owner_user
      }
    ]
  }
];

const toDefaultOptions = [
  {
    options: [
      {
        value: 'related_entity',
        label: strings.labels.automation.related_entity
      }
    ]
  }
];

const defaultValues = {
  inbox_id: '',
  from: 'owner_user',
  to: 'related_entity',
  message: ''
};

function AutomationsMessage() {
  const location = useLocation();
  const crumb = location.pathname.includes('edit')
    ? 'Editar automação'
    : 'Criar nova automação';

  const { initialValues, setInitialValues } = useAutomationContext();
  const { action_params: actionParams = {} } = initialValues;

  const onSubmit = (values) => {
    setInitialValues({ ...initialValues, action_params: values });
    goBack();
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs='span'>
          {crumb}
        </Breadcrumb.Item>

        <Breadcrumb.Item active>
          Adicionar mensagem
        </Breadcrumb.Item>
      </Breadcrumb>

      <h2 className='mb-4'>
        Adicionar mensagem
      </h2>

      <Form
        defaultValues={defaultValues}
        initialValues={actionParams}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {
          () => (
            <>
              <FormField
                as={InboxSelect}
                label='Caixa de entrada'
                name='inbox_id'
              />

              <div className='d-flex gap-4 mb-4'>
                <div className='w-100 d-flex flex-column'>
                  <div className='d-flex justify-content-between'>
                    <Form.Label htmlFor='from'>
                      De
                    </Form.Label>
                    <Tooltip
                      placement='left'
                      // eslint-disable-next-line max-len
                      content='Caso o responsável pelo negócio não esteja no Chat, vamos enviar a mensagem através do nosso Bot do Chat e sem atribuição'
                    >
                      <span>
                        <Icon name='info' size='sm' className='text-dark-gray' />
                      </span>
                    </Tooltip>
                  </div>
                  <FormField
                    as={UserSelect}
                    name='from'
                    label=''
                    defaultOptions={fromDefaultOptions}
                    className='w-100'
                  />
                </div>

                <div className='w-100 d-flex flex-column'>
                  <div className='d-flex justify-content-between'>
                    <Form.Label htmlFor='to'>
                      Para
                    </Form.Label>
                    <Tooltip
                      placement='left'
                      // eslint-disable-next-line max-len
                      content='Para a mensagem ser enviada com sucesso, o contato precisa ter um número válido no WhatsApp'
                    >
                      <span>
                        <Icon name='info' size='sm' className='text-dark-gray' />
                      </span>
                    </Tooltip>
                  </div>
                  <FormField
                    as={UserSelect}
                    name='to'
                    label=''
                    defaultOptions={toDefaultOptions}
                    className='w-100'
                  />
                </div>
              </div>

              <FormField
                as={TextArea}
                label='Mensagem'
                name='message'
                placeholder='Digite algo'
                maxLength={MESSAGE_MAX_LENGTH}
                innerClassName='resize-none min-height-3'
              />

              <div className='d-flex gap-2 justify-content-end'>
                <Button
                  variant='outline-dark-gray'
                  onClick={goBack}
                >
                  Voltar
                </Button>

                <Button
                  type='submit'
                >
                  Salvar
                </Button>
              </div>
            </>
          )
        }
      </Form>
    </div>
  );
}

export default AutomationsMessage;

import React from 'react';
import PropTypes from 'prop-types';

import { parseDate, toDateString } from '@/date';

import PaymentMethod from '@/components/Billing/PaymentMethod';

const propTypes = {
  subscriptionType: PropTypes.oneOf(['free', 'trial', 'paid']).isRequired,
  periodicity: PropTypes.string,
  expirationDate: PropTypes.string,
  method: PropTypes.string,
  lastFourDigits: PropTypes.string,
  canceled: PropTypes.bool,
  expired: PropTypes.bool,
  blocked: PropTypes.bool
};

const periodicityText = {
  monthly: 'Mensal',
  quarterly: 'Trimestral',
  half_yearly: 'Semestral',
  yearly: 'Anual'
};

function PaymentDetailsCard(props) {
  const {
    periodicity,
    expirationDate,
    method,
    subscriptionType,
    lastFourDigits,
    canceled,
    blocked,
    expired
  } = props;


  const isPaid = subscriptionType === 'paid';
  const isBlockedOrCanceled = blocked || canceled;

  const subscriptionText = () => {
    if (blocked || canceled) {
      return 'Cancelada';
    } else if (expired) {
      return 'Expirada';
    } else {
      return `Renova em ${expirationDate ? toDateString(parseDate(expirationDate)) : null}`;
    }
  };

  return (
    <div
      className='d-flex flex-column h-100 p-4 border rounded border-light text-dark-gray text-small'
    >
      {
        isPaid
          ? (
            <div>
              <h6 className='py-1 text-darker-gray'>
                Assinatura {periodicityText[periodicity] || null}
              </h6>
              <span>
                {subscriptionText()}.
              </span>

              <hr />

              <h6 className='py-1 text-darker-gray'>Método de pagamento</h6>
              <div className='d-flex'>
                <PaymentMethod
                  method={!isBlockedOrCanceled && method}
                  lastFourDigits={lastFourDigits}
                />
              </div>
            </div>
          )
          : (
            <div className='d-flex flex-fill align-items-center justify-content-center text-center'>
              Nenhum método de pagamento ativo no momento.
            </div>
          )
      }
    </div>
  );
}

PaymentDetailsCard.propTypes = propTypes;

export default PaymentDetailsCard;

import React, { createContext, useContext } from 'react';

const DealModalContext = createContext(undefined);

export function useDealModalContext() {
  const context = useContext(DealModalContext);

  if (context === undefined) {
    throw new Error('useDealModalContext must be used within DealModalProvider');
  }

  return context;
}

export const useVoip = () => useDealModalContext().voip;

export function DealModalProvider({ children, value: componentValue }) {
  const { voip } = componentValue;

  const value = {
    voip
  };

  return (
    <DealModalContext.Provider value={value}>
      {children}
    </DealModalContext.Provider>
  );
}

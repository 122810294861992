import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import successful from 'images/billing/successful.svg';
import { settingsCurrentPlanPath } from '@/routes';
import { applyMask } from '@/masks';

const propTypes = {
  show: PropTypes.bool,
  email: PropTypes.string,
  whatsapp: PropTypes.string
};

const defaultProps = {
  show: false
};

function PaymentSuccessModal({ show, email, whatsapp }) {
  const navigate = useNavigate();

  const handleClick = () => navigate(settingsCurrentPlanPath());

  return (
    <Modal
      size='lg'
      show={show}
      bodyClassName='d-flex flex-column px-8 pb-8 align-items-center text-center'
      backdrop='static'
      onHide={handleClick}
    >
      <div className='px-8'>
        <img src={successful} className='mb-2 width-5 height-5' />
        <h1>Sua conta no Agendor Chat está quase pronta</h1>
        <div className='text-dark-gray fw-bold mt-3 mb-5'>
          <span>
          Estamos finalizando a configuração. Em breve, você receberá as
          instruções e credenciais da sua conta por e-mail {' '}
            <span className='text-darker-gray'> ({email})</span> {' '}e WhatsApp{' '}
            <span className='text-darker-gray'>{` (${applyMask(whatsapp, 'whatsapp')})`}</span>.
          </span>
          <div>Fique de olho!</div>
        </div>

        <Button
          className='mb-2 width-5'
          onClick={handleClick}
        >
          Entendi
        </Button>
      </div>
    </Modal>
  );
}

PaymentSuccessModal.propTypes = propTypes;
PaymentSuccessModal.defaultProps = defaultProps;

export default PaymentSuccessModal;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import EditOptionForm from '@/components/EditOptionForm';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import CreateOptionForm from '@/components/CreateOptionForm';

const propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    option: PropTypes.string.isRequired,
    order: PropTypes.number
  })).isRequired,
  onChange: PropTypes.func.isRequired
};

const defaultProps = {
  options: [],
  onChange: () => {}
};

function OptionsList({ options, onChange }) {
  const [showForm, setShowForm] = useState(false);
  const [optionList, setOptionList] = useState(() => {
    const newOptionList = options.map((option, idx) => ({
      ...option,
      order: idx + 1
    }));
    return newOptionList;
  });

  const [nextOrder, setNextOrder] = useState(options.length + 1);
  const alert = useAlert();

  const handleAddClick = () => {
    if (!showForm) {
      setShowForm(true);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) {
      return;
    }

    const newItems = [...optionList];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);

    newItems.forEach((el, idx) => {
      el.order = idx + 1;
    });

    setOptionList(newItems);
    onChange(newItems);
  };

  const onAdd = (newOption) => {
    let exists = false;
    optionList.forEach((el) => {
      if (el.option === newOption.option && !exists) {
        exists = true;
      }
    });
    if (!exists) {
      const list = optionList;
      list.push(newOption);
      setOptionList(list);
      setShowForm(false);
      alert.show(
        `Opção ${newOption.option} adicionada com sucesso.`,
        { variant: 'success', timeout: 5000 }
      );
      setNextOrder(list.length + 1);
      onChange(list);
    } else {
      alert.show(
        `Já existe uma opção '${newOption.option}'.`,
        { variant: 'danger', timeout: 5000 }
      );
    }
  };
  const onDelete = (option) => {
    const list = [];
    optionList.forEach((el) => {
      if (el.order === option.order) {
        if (!option.id) {
          return;
        }
        el.deleted = true;
      }
      list.push(el);
    });
    list.forEach((el, index) => {
      el.order = index + 1;
    });
    setOptionList(list);
    setNextOrder(list.length + 1);
    onChange(list);
  };

  const handleOnChangeItem = (option) => {
    const list = [...optionList];
    list[option.order - 1] = option;
    setOptionList(list);
    onChange(list);
  };

  const onCancel = () => setShowForm(false);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='options-list pb-1'>
        <Droppable
          droppableId='options-list'
          type='row'
        >
          {(droppableProvided, droppableSnapshot) => (
            <div
              className={classnames(
                { 'pe-none': droppableSnapshot.isDraggingOver }
              )}
              ref={droppableProvided.innerRef}
              { ...droppableProvided.droppableProps }
            >
              {optionList.map((item, index) => {
                if (item.deleted) {
                  return null;
                }

                return (
                  <Draggable
                    draggableId={String(item.order)}
                    index={index}
                    key={item.order}
                    isDragDisabled={item.isDragDisabled}
                  >
                    {(draggableProvided, draggableSnapshot) => (
                      <EditOptionForm
                        className='options-item'
                        optionDelete={onDelete}
                        onChange={handleOnChangeItem}
                        innerRef={draggableProvided.innerRef}
                        innerProps={draggableProvided.draggableProps}
                        dragProps={draggableProvided.dragHandleProps}
                        isDragging={draggableSnapshot.isDragging}
                        option={item}/>
                    )}
                  </Draggable>

                );
              })}

              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
      { showForm
        ? <CreateOptionForm
          nextOrder={nextOrder}
          onAdd={onAdd}
          onCancel={onCancel}
        />
        : <Button
          variant='transparent-light'
          className='py-2 px-4 border-0 funnel-delete-button'
          onClick={handleAddClick}
        >
          <Icon name='add' className='me-2' />

          <span>Adicionar opção</span>
        </Button>
      }
    </DragDropContext>
  );
}

OptionsList.propTypes = propTypes;
OptionsList.defaultProps = defaultProps;

export default OptionsList;


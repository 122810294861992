import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import InfiniteScroll from '@/components/InfiniteScroll';
import DragScrollable from '@/components/DragScrollable';
import Table from '@/components/Table';
import TableIndex from '@/components/TableIndex';
import PaginationCounter from '@/components/List/PaginationCounter';
import EntitySkeleton from '@/components/List/EntitySkeleton';
import EntityLink from '@/components/EntityLink';
import Truncate from '@/components/Truncate';

const propTypes = {
  isFetchingNextPage: PropTypes.bool,
  fetchNextPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  isLoading: PropTypes.bool,
  entityId: PropTypes.string,
  entityType: PropTypes.string,
  items: PropTypes.array,
  itemsTotal: PropTypes.number
};

const defaultProps = {
  hasNextPage: false
};

const TableHead = ({ headers }) => (
  <thead className='position-sticky top-0 bg-white z-index-1'>
    <tr>
      <th className={classnames(
        'width-5',
        'border-end',
        'border-1',
        'position-sticky',
        'start-0',
        'bg-white'
      )}>
        <span className='px-3 invisible'>
          <TableIndex index={0} />
        </span>

        <span className='px-3'>
          {headers[0]}
        </span>
      </th>
      {
        headers.slice(1).map((head, index) => (
          <th key={index} className='text-nowrap'>
            {head}
          </th>
        ))
      }
    </tr>
  </thead>
);

const Loading = () => <EntitySkeleton maxCols={4} iconless />;

const TableData = ({ children }) => (
  <td className='align-middle'>
    <div className='text-nowrap'>
      {
        children || (
          <span className='text-medium-gray'>
            Indefinido
          </span>
        )
      }
    </div>
  </td>
);

const TableRow = ({ item, itemIndex, columns, entityType }) => (
  <tr>
    <td className={classnames(
      'border-end',
      'border-1',
      'position-sticky',
      'start-0',
      'bg-white'
    )}>
      <div className='d-flex align-items-center'>
        <span className='px-3'>
          <TableIndex index={itemIndex + 1} />
        </span>

        <EntityLink
          className='px-3 text-darker-gray fw-bold width-5'
          entity={{ id: item.id, type: entityType }}
        >
          <Truncate>
            {item.name || item.title}
          </Truncate>
        </EntityLink>
      </div>
    </td>

    {
      columns.map((column, cellIndex) => (
        <TableData key={cellIndex}>
          {column.objectPath
            ? column.callback(get(item, column.objectPath))
            : column.callback(item)}
        </TableData>

      ))
    }
  </tr>
);

const TableBody = ({
  columns, items, isLoading,
  entityType, EmptyComponent
}) => {
  const hasItems = Boolean(items.length);

  if (isLoading) {
    return <Loading />;
  }

  if (!hasItems) {
    return (
      <div className='w-100 d-flex justify-content-center position-absolute'>
        {EmptyComponent}
      </div>
    );
  }

  return (
    <tbody>
      {
        items.map((item, index) => (
          <TableRow
            key={item.id}
            entityType={entityType}
            item={item}
            itemIndex={index}
            columns={columns}
          />
        ))
      }
    </tbody>
  );
};

function BaseTable({
  headers,
  columns,
  entityType,
  fetchNextPage,
  hasNextPage,
  isLoading,
  entityId,
  items,
  itemsTotal,
  relatedEntityType,
  EmptyComponent
}) {
  const itemsLength = items.length;

  return (
    <div>
      <InfiniteScroll
        height='400px'
        length={itemsLength}
        loadItems={fetchNextPage}
        hasMore={hasNextPage}
      >
        <DragScrollable className='flex-grow-1'>
          <Table className='table-border-separate '>
            <TableHead headers={headers} />
            <TableBody
              columns={columns}
              entityId={entityId}
              items={items}
              isLoading={isLoading}
              entityType={entityType}
              relatedEntityType={relatedEntityType}
              EmptyComponent={EmptyComponent}
            />
          </Table>
        </DragScrollable>
      </InfiniteScroll>

      <div className='py-2'>
        <PaginationCounter
          show={!isLoading}
          model={entityType}
          count={itemsLength}
          max={itemsTotal}
        />
      </div>
    </div>
  );
}

BaseTable.propTypes = propTypes;
BaseTable.defaultProps = defaultProps;

export default BaseTable;

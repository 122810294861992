import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { helpUrl } from '@/routes';
import Badge from '@/components/Badge';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import storage from '@/lib/storage';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';
import emptyVipLogo from 'images/empty-vip-logo.png';

const propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  storeKey: PropTypes.string
};

const defaultProps = {
  show: false,
  onClose: () => { },
  storeKey: ''
};

function VoipInformationModal({ show, onClose, storeKey }) {
  const { user } = useAuth();
  const tracker = useTracking();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const integrationQuery = new URLSearchParams(`${search}&integration=vip_solutions`);

  useEffect(() => {
    if (show) {
      tracker.trackVoipInfoModalOpened({ user });
    }
  }, [show]);

  const sections = [
    {
      title: (
        <>
          <img className='me-2' src={emptyVipLogo} alt='VIP Logo' />
          Utilize nosso parceiro oficial de telefonia
          <Badge variant='light-green' className='py-1 px-2 text-small ms-2'>
            RECOMENDADO
          </Badge>
        </>
      ),
      subtitle:
        'A VIP Solutions é uma empresa nacional, de telefonia fixa e em nuvem, que está ' +
        'há 20 anos no mercado de Telecom.',
      benefitsTitle: 'Benefícios de usar a VIP:',
      benefits: [
        'Efetue suas ligações a partir do Agendor.',
        'As gravações das chamadas são salvas automaticamente no histórico de atividades do ' +
        'Agendor.',
        'Escolha entre planos sob medida para o seu negócio ou pague por consumo de minutagem.',
        'Bina inteligente: contrate um DDD para cada região dos seus leads e clientes para ' +
        'aumentar as chances de ser atendido.',
        'Faça portabilidade do seu número ou contrate um número novo para efetuar as ligações.',
        'Efetue e receba ligações sem sair do computador.'
      ],
      button: (
        <Button
          variant='primary'
          onClick={() => {
            tracker.trackVoipInfoShowMoreClicked({
              user,
              action: 'Voip vip'
            });
            navigate(`${pathname}?${integrationQuery.toString()}`);
          }}
        >
          Saiba mais
          <Icon name='full-arrow-right' />
        </Button>
      )
    },
    {
      title: (
        <>
          <Icon name='call' className='me-2 text-primary' />
          Utilize seu próprio serviço de VoIP
        </>
      ),
      description: (
        <>
          Essa opção pode ser integrada ao Agendor, mas depende do seu fornecedor de VoIP. <br />
          Entre em contato com ele ou com nossa equipe para planejar uma integração.
        </>
      ),
      benefits: [
        'Efetue suas ligações a partir do Agendor.',
        'Utilize seu próprio serviço de VoIP de maneira independente.',
        'As gravações das chamadas podem ser salvas automaticamente no histórico' +
        'de atividades do Agendor.'
      ],
      button: (
        <Button
          variant='outline-dark-gray'
          onClick={() => {
            tracker.trackVoipInfoShowMoreClicked({
              user,
              action: 'Voip proprio'
            });
            window.open(helpUrl('use-voip'), '_blank');
          }}
        >
          Saiba mais
          <Icon name='full-arrow-right' />
        </Button>
      )
    },
    {
      title: (
        <>
          <Icon name='smartphone' className='me-2 text-primary' />
          Utilize seu celular através do Windows
        </>
      ),
      benefits: [
        'Efetue suas ligações a partir do Agendor.',
        'Utilize seu próprio número de celular para efetuar as ligações pelo computador.',
        'Sem nenhum custo além das tarifas da sua operadora tradicional.'
      ],
      button: (
        <Button
          variant='outline-dark-gray'
          onClick={() => {
            tracker.trackVoipInfoShowMoreClicked({
              user,
              action: 'Voip windows'
            });
            window.open(helpUrl('use-windows-voip'), '_blank');
          }}
        >
          Saiba mais
          <Icon name='full-arrow-right' />
        </Button>
      )
    }
  ];

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        onEnter={() => storage.set(storeKey, false)}
        size='xl'
        bodyClassName='p-8'
      >
        <div>
          <div className='fw-bold text-dark h2 mb-2'>
            Conheça as maneiras de fazer ligações a partir do Agendor
          </div>
          {sections.map((section, index) => (
            <div className='my-3 bg-dark-light rounded w-100 h-100' key={index}>
              <div className='p-5'>
                <div className='fw-bold text-dark h3 mb-2'>{section.title}</div>
                <div className='text-dark-gray my-3'>{section.subtitle}</div>
                {section.benefitsTitle && (
                  <div className='text-dark-gray fw-bold my-3'>{section.benefitsTitle}</div>
                )}
                <div className='my-3'>
                  {section.benefits.map((benefit, idx) => (
                    <div
                      className='d-flex align-items-center my-1'
                      key={idx}
                    >
                      <Icon name='check' size='sm' className='me-2 text-primary' />
                      <span className='text-dark-gray'>{benefit}</span>
                    </div>
                  ))}
                </div>
                {section.description && (
                  <div className='my-3 text-dark'>{section.description}</div>
                )}
                {section.button}
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
}

VoipInformationModal.propTypes = propTypes;
VoipInformationModal.defaultProps = defaultProps;

export default VoipInformationModal;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Stack from 'react-bootstrap/Stack';
import Card from '@/components/Card';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import { useACInboxes, useUsers } from '@/api';
import strings from '@/strings';
import find from 'lodash/find';
import Skeleton from '@/components/Skeleton';

const propTypes = {
  params: PropTypes.shape({
    from: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inbox_id: PropTypes.number,
    message: PropTypes.string
  }),
  onClick: PropTypes.func
};

function MessageActionPreview(props) {
  const { params, onClick } = props;
  const isEdit = location.pathname.includes('edit');
  const [loading, setLoading] = useState(false);

  const { data: users } = useUsers();
  const getLabel = useCallback((value) => {
    const defaultLabel = strings.labels.automation[value];
    if (defaultLabel) {
      return defaultLabel;
    }

    const user = find(users?.data, { id: value });
    if (user) {
      return user.name;
    }
  }, [users]);

  const { data: inboxes } = useACInboxes();
  const getInboxName = useCallback((value) => {
    const inbox = find(inboxes?.data, { id: value });
    if (inbox) {
      return inbox.name;
    }
  }, [inboxes]);

  const preview = useMemo(() => ({
    from: getLabel(params.from),
    to: getLabel(params.to),
    inbox: getInboxName(params.inbox_id),
    message: params.message
  }), [getLabel, params, inboxes]);

  useEffect(() => {
    if (isEdit) {
      setLoading(true);
      const allFilled = Object.values(preview).every((value) => value !== undefined);
      if (allFilled) {
        setLoading(false);
      }
    }
  }, [params, users, inboxes]);

  return (
    <div>
      <Card bg='dark-light' border='dark-light' className='p-4'>
        <Stack gap={2}>
          { loading
            ? <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
            : <>
              <h5 className='mb-0'>
                {preview.inbox}
              </h5>

              <dl className='d-flex gap-2 align-items-start text-small text-dark-gray mb-0'>
                <div>
                  <dt className='d-inline'>De: </dt>
                  <dd className='d-inline'>{preview.from}</dd>
                </div>
                <div>
                  <dt className='d-inline'>Para: </dt>
                  <dd className='d-inline'>{preview.to}</dd>
                </div>
              </dl>

              <hr className='m-0' />

              <div className='px-2 pt-2 overflow-hidden text-truncate' >
                <span className='white-space-nowrap'>{preview.message}</span>
              </div>
            </>
          }
        </Stack>
      </Card>

      <Button
        variant='outline-primary'
        href='message'
        onClick={onClick}
        className='mt-2'
      >
        <Icon name='edit-field' className='me-2' />
        <span>Editar mensagem</span>
      </Button>
    </div>
  );
}

MessageActionPreview.propTypes = propTypes;

export default MessageActionPreview;
